import React from 'react'
import { inject, observer } from 'mobx-react'
import Input from '../components/Input'
import Select from '../components/Select'
import { bindInput, bindSelect } from '../utils/bindField'
import { EquipmentStore } from '../stores/EquipmentsStore'
import { LeaseCost } from '../stores/types/lease.types'
import Form from '../stores/Form'

const LeaseCostRow = observer(({ leaseCost, onAddLeaseCost, isLast, onClose }: { leaseCost: Form<LeaseCost>; onAddLeaseCost: (e: any) => void; isLast: boolean; onClose: () => void; }) => {


  const options = [
    {
      value: 'day',
      label: 'dia'
    },
    {
      value: 'week',
      label: 'semana'
    },
    {
      value: 'month',
      label: 'mes'
    }
  ]

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='row align-items-center'>
          <div className='col'>
            <h3 className='card-header-title'>Agregar costo</h3>
          </div>
          {!isLast ? (
            <div className='col-auto'>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={onClose}>
                <span aria-hidden='true'>x</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className='card-body'>
        <Input
          label='Costo'
          type='number'
          {...bindInput(leaseCost, 'cost')}
        />
        <Select
          label='Tiempo de renta'
          options={options}
          {...bindSelect(leaseCost, 'unit')}
        />
      </div>
    </div>
  )
})

const EquipmentLeaseCost = inject('equipmentStore')(observer(({ equipmentStore }: { equipmentStore?: EquipmentStore; }) => {

  const onCloseLeaseCost = (index: number) => {
    equipmentStore.removeLeaseCost(index)
  }
  const onAddLeaseCost = (e: any) => {
    e.preventDefault()
    equipmentStore.addLeaseCost()
  }

  console.log('equipmentStore.leasesCost', equipmentStore.leasesCost);
  const leasesCost = equipmentStore.leasesCost.map((leaseCostForm, index) => {
    const leaseCost = leaseCostForm
    return <LeaseCostRow
      key={index}
      leaseCost={leaseCost}
      onAddLeaseCost={onAddLeaseCost}
      isLast={false}
      onClose={() => onCloseLeaseCost(index)}
    />
  })
  const lastLeaseCost = equipmentStore.leasesCost[equipmentStore.leasesCost.length - 1];
  const isValid = lastLeaseCost?.isValid;
  return (
    <>
      {leasesCost}
      <button
        className='btn btn-block btn-info'
        disabled={!isValid}
        onClick={onAddLeaseCost}
      >
        Agregar tipo de costo
      </button>
    </>
  )

}))

export default EquipmentLeaseCost

import { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import Form from '../components/Form'
import { bindInput, bindSelect, bindCheckbox } from '../utils/bindField'
import Input from '../components/Input'
import Select from '../components/Select'
import Checkbox from '../components/Checkbox'
import BranchesStore from '../stores/BranchesStore'
import ProjectsStore from '../stores/ProjectsStore'
import { LeaseFormFields, LeaseStore } from '../stores/Leases'
import { useHistory } from 'react-router-dom'
import ClientsStore from '../stores/ClientsStore'
import { debounce } from 'lodash'
import SelectSearch, { SelectSearchOption } from '../components/SelectSearch'
import { Client } from '../stores/types/client.types'
import { Project } from '../stores/types/project.types'
import { useQuery } from '../hooks/useQuery'
import { fetchLeaseById } from '../sources/lease'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { DayValue } from 'react-modern-calendar-datepicker'
import dayjs from 'dayjs'

require('dayjs/locale/es')
dayjs.locale('es')


export const LeaseForm = inject(
  'leaseStore',
  'clientsStore',
  'projectsStore',
  'branchesStore'
)(observer(({ branchesStore,
  clientsStore,
  projectsStore,
  leaseStore,
}: {
  branchesStore: BranchesStore,
  clientsStore: ClientsStore,
  projectsStore: ProjectsStore,
  leaseStore: LeaseStore,
}) => {

  const query = useQuery();
  const leaseId = query.get('id')
  const history = useHistory()
  const [client, setClient] = useState<Client>();


  const fetchClients = useRef(debounce((searchText = '') => {
    clientsStore.getClients({
      search: searchText
    })
  }, 300)
  ).current;

  useEffect(() => {

    if (leaseId) {
      fetchLeaseById({ leaseId }).then(({ data: lease }) => {
        console.log('lease ==>', lease)
        const formData: LeaseFormFields = {
          branchId: lease.branch.id,
          clientId: lease.client.id,
          noTax: lease.no_tax,
          projectId: lease.project.id,
          requestor: lease.requestor,
          humanId: lease.human_id,
          receivedDate: lease.received_date ? dayjs(lease.received_date).toDate() : undefined,
          deliveryDate: lease.delivery_date ? dayjs(lease.delivery_date).toDate() : undefined,
          id: lease.id,
        }
        setClient(lease.client)
        leaseStore.loadData(formData);
        fetchProjects();

      })
    }
  }, [leaseId]);

  const fetchProjects = debounce(() => {
    return projectsStore.getProjects({ clientId: leaseStore.form.fields.clientId, limit: 1000 })
  }, 500);
  const fetchBranches = () => {
    return branchesStore.getBranches()
  }
  const onSubmit = (event: any) => {
    event.preventDefault()
    leaseStore
      .save()
      .then(() => {
        history.push('/home/leases')
      })
  }
  const onCancel = () => {
    leaseStore.restore()
  }

  const { isNew } = leaseStore
  const title = isNew ? 'Crear nuevo contrato' : 'Actualizar contrato'
  const buttonLabel = isNew ? 'Crear contrato nuevo' : 'Editar contrato'

  const branchesOptions = branchesStore.allBranches.map(branch => ({
    value: branch.id,
    label: branch.name
  }))
  const projectsOptions: SelectSearchOption[] = []
  projectsStore.allProjects.forEach(project => {
    //console.log("leaseStore.form.clientId", leaseStore.form.fields.clientId)
    if (!leaseStore.form.fields.clientId) {
      return
    }
    if (project.clientId === leaseStore.form.fields.clientId) {
      projectsOptions.push({
        value: project.id,
        label: project.name,
        meta: project
      })
    }


  })

  useEffect(() => {

    fetchClients()
    fetchProjects()
    fetchBranches()
  }, []);

  useEffect(() => {
    if (leaseStore.form.fields.clientId)
      fetchProjects();
  }, [leaseStore.form.fields.clientId]);

  const clientsOptions = clientsStore.clients.map(client => ({
    value: client.id,
    label: client.name,
    meta: client
  }))

  let deliveryDates = null

  const onSelected = (option: SelectSearchOption) => {
    const { id, name, email } = option.meta as Client
    const store = leaseStore.form
    store.setField('clientId', id)
    setClient(option.meta);
    return name;
  }

  const onProjectSelected = (option: SelectSearchOption) => {
    const { id, name } = option.meta as Project
    const store = leaseStore.form
    store.setField('projectId', id)
    // setProject(option.meta);
    return name;
  }



  const onSearchClient = (seachText: string) => {
    fetchClients(seachText)
  }

  const renderClientItem = (option: SelectSearchOption) => {


    const { id, name, email, phone } = option.meta as Client

    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name} - {phone}
      </div>
    </div>


  }



  const [deliveryDate, setDeliveryDate] = useState<DayValue>();
  const [receivedDate, setReceivedDate] = useState<DayValue>();

  useEffect(() => {

    if (leaseStore.form.fields.deliveryDate) {
      const customDeliveryDate = dayjs(leaseStore.form.fields.deliveryDate)
      setDeliveryDate({
        day: customDeliveryDate.get('date'),
        month: customDeliveryDate.get('month') + 1,
        year: customDeliveryDate.get('year'),
      })
    }
  }, [leaseStore.form.fields.deliveryDate]);
  useEffect(() => {

    if (leaseStore.form.fields.receivedDate) {
      const customReceivedDate = dayjs(leaseStore.form.fields.receivedDate)
      setReceivedDate({
        day: customReceivedDate.get('date'),
        month: customReceivedDate.get('month') + 1,
        year: customReceivedDate.get('year'),
      })
    }
  }, [leaseStore.form.fields.receivedDate]);


  if (!isNew) {
    deliveryDates = (
      <div className='card'>
        <div className='card-header'>
          <h4 className='card-header-title'>
            Entrega y salida del pedido
          </h4>
        </div>
        <div className='card-body'>
          <DatePicker
            value={deliveryDate}
            onChange={(dayValue: DayValue) => {
              setDeliveryDate(dayValue);
              const selectedDeliveryDate = dayjs(`${dayValue?.month}/${dayValue?.day}/${dayValue?.year}`, 'M/D/YYYY').toDate()
              leaseStore.form.setField('deliveryDate', selectedDeliveryDate)
            }}
            formatInputText={() => {
              if (!deliveryDate) {
                return 'Salida del Pedido'
              }

              return dayjs(`${deliveryDate?.month}/${deliveryDate?.day}/${deliveryDate?.year}`, 'M/D/YYYY').format('DD MMM YY')
            }}
            inputPlaceholder="Salida del Pedido"
            inputClassName='form-control'
            shouldHighlightWeekends
          />
          {/* <InputMask
            label='Salida del pedido'
            mask='00/00/0000 00:00'
            {...bindInput(leaseStore.form, 'deliveryDate')}
          /> */}

          <br />
          <br />

          <DatePicker
            value={receivedDate}
            onChange={(dayValue: DayValue) => {
              setReceivedDate(dayValue);
              const selectedDeliveryDate = dayjs(`${dayValue?.month}/${dayValue?.day}/${dayValue?.year}`, 'M/D/YYYY').toDate()
              leaseStore.form.setField('receivedDate', selectedDeliveryDate)
            }}
            formatInputText={() => {
              if (!receivedDate) {
                return 'Entrega del pedido'
              }

              return dayjs(`${receivedDate?.month}/${receivedDate?.day}/${receivedDate?.year}`, 'M/D/YYYY').format('DD MMM YY')
            }}
            inputPlaceholder="Entrega del pedido"
            inputClassName='form-control'
            shouldHighlightWeekends
          />
          {/* <InputMask
            label='Entrega del pedido'
            mask='00/00/0000 00:00'
            {...bindInput(leaseStore.form, 'receivedDate')}
          /> */}
        </div>
      </div>
    )
  }

  return (
    <Form
      preTitle='Contrato'
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!leaseStore.form.isValid || leaseStore.saving}
      isSubmitLoading={leaseStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}

    >
      <>
        <Select
          label='Sucursal'
          disabled={!isNew}
          options={branchesOptions}
          {...bindSelect(leaseStore.form, 'branchId')}
        />

        <SelectSearch label='Cliente'
          onSelected={onSelected}
          options={clientsOptions}
          onSearch={onSearchClient}
          value={client?.name}
          renderItem={renderClientItem} />

        {/* <SelectSearch label='Proyecto'
        onSelected={onProjectSelected}
        options={projectsOptions}
        onSearch={onSearchClient}
        renderItem={renderClientItem} /> */}


        <Select
          label='Proyecto'
          options={projectsOptions}
          {...bindSelect(leaseStore.form, 'projectId')}
        />

        <Input
          label='Solicitante'
          {...bindInput(leaseStore.form, 'requestor')}
        />
        <Checkbox
          label='Contrato sin iva'
          {...bindCheckbox(leaseStore.form, 'noTax')}
        />
        {deliveryDates}
      </>
    </Form>
  )
}))


export default LeaseForm

// @flow
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import HeaderImage from '../components/HeaderImage'
import ClientCard from '../components/ClientCard'
import ProjectCard from '../components/ProjectCard'
import { Table, TableBody, TableHead } from '../components/Table'
import { LeaseStore } from '../stores/Leases'
import { LeaseEquipmentStore } from '../stores/LeaseEquipmentsStore'
import { ExtensionStore, ExtensionsStore } from '../stores/ExtensionsStore'
import UiStore from '../stores/UiStore'

import { ClientStore } from '../stores/ClientsStore'
import { ProjectStore } from '../stores/ProjectsStore'
import { fetchLeaseEquipmentById } from '../sources/lease-equipment'
import { LeaseEquipment } from '../stores/types/leaseEquipment.types'
import numeral from 'numeral'
import { LeaseEquipmentExtension } from '../stores/types/leaseEquipmentExtension.types'
function ExtensionRow({ leaseEquipmentId, leaseId, cost, days, onDeleteClick, onPrintNote }: {
  leaseEquipmentId: string;
  leaseId: string | undefined; cost: number;
  days: number;
  onDeleteClick: () => void;
  onPrintNote: () => void;
}) {
  return (
    <tr key={leaseEquipmentId}>
      <td className='supply-description'>
        {cost}
      </td>
      <td className='supply-description'>
        {days}
      </td>
      <td>
        <Link
          className='btn btn-sm btn-danger d-none d-md-inline-block'
          to={`/home/lease/${leaseId}/equipment/${leaseEquipmentId}/details`}
          onClick={onDeleteClick}
        >
          Borrar extension
        </Link>
      </td>
      <td>
        <button
          className='btn btn-sm btn-success d-none d-md-inline-block'
          onClick={onPrintNote}
        >
          Imprimir Nota de Remision
        </button>
      </td>
    </tr>
  )
}



const ExtensionDetails = inject('leaseStore', 'leaseEquipmentStore', 'extensionsStore', 'extensionStore', 'clientStore', 'projectStore')(observer(({
  leaseStore, leaseEquipmentStore, extensionsStore, extensionStore,
  projectStore,
  clientStore
}: {
  clientStore: ClientStore;
  projectStore: ProjectStore;
  leaseStore: LeaseStore; leaseEquipmentStore: LeaseEquipmentStore; extensionsStore: ExtensionsStore; extensionStore: ExtensionStore; uiStore: UiStore
}) => {

  const params = useParams<{
    leaseId: string;
    leaseEquipmentId: string;
  }>();

  const [leaseEquipment, setLeaseEquipment] = useState<LeaseEquipment>();


  const fetchExtensions = () => {
    extensionsStore.getExtensions({
      leaseId: params.leaseId,
      leaseEquipmentId: params.leaseEquipmentId,
    })
  }

  const fetchLeaseEquipment = () => {
    fetchLeaseEquipmentById(params.leaseId, params.leaseEquipmentId).then(({
      data
    }) => {
      console.log('fetchLeaseEquipmentById', data)
      setLeaseEquipment(data);
    })

  }
  const onRemove = ({ id }: { id: string }) => {
    extensionsStore.removeById(id)
  }

  const onPrintNote = ({ id }: { id: string }) => {
    console.log("onPrintNote", id)
    console.log("extensionsStore", extensionsStore)
    extensionsStore.printNote(id)
  }
  const renderExtension = (extension: LeaseEquipmentExtension, index: number) => {

    return (
      <ExtensionRow
        key={index}
        {...extension}
        onDeleteClick={() => onRemove(extension)}
        onPrintNote={() => onPrintNote(extension)}
        leaseId={leaseStore.id}
        leaseEquipmentId={leaseEquipmentStore.id} />
    )
  }

  useEffect(() => {
    fetchLeaseEquipment()
    fetchExtensions();
  }, []);

  const rows = extensionsStore.allExtensions.map(renderExtension)
  extensionStore.form.setField('equipmentId', leaseEquipment?.equipment.id)
  return (
    <div className='main-content'>
      <HeaderImage
        title='Extension de contrato'
        buttonLabel='Agregar extension'
        to={`/home/lease/${params.leaseId}/equipment/${params.leaseEquipmentId}/extension-form`}
      />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12'>
                <ClientCard clientStore={clientStore} client={leaseEquipment?.lease.client} />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <ProjectCard projectStore={projectStore} project={leaseEquipment?.lease.project} />
              </div>
            </div>
          </div>
          <div className='col-12 col-xl-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <h5 className='mb-0'>
                      Maquinaria
                    </h5>
                  </div>
                  <div className='col-auto'>
                    <small className='text-muted'>
                      {leaseEquipment?.equipment.name}
                    </small>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col'>
                    <h5 className='mb-0'>
                      Costo original
                    </h5>
                  </div>
                  <div className='col-auto'>
                    <small className='text-muted'>
                      {numeral(leaseEquipment?.equipment.cost).format('$0,0')}
                    </small>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col'>
                    <h5 className='mb-0'>
                      Dias original
                    </h5>
                  </div>
                  <div className='col-auto'>
                    <small className='text-muted'>
                      {leaseEquipment?.unit_qty}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row' style={{ marginBottom: '7rem' }}>
          <div className='col-12'>
            <Table>
              <TableHead>
                <th>
                  <a href='#' className='text-muted sort' data-sort='employee-name'>
                    Costo de extension
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='employee-name'>
                    Dias
                  </a>
                </th>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )

}))

export default ExtensionDetails

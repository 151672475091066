import { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import Select from '../components/Select'

import numeral from 'numeral'
import { LeaseEquipmentStore } from '../stores/LeaseEquipmentsStore'
import { EquipmentStore, EquipmentsStore } from '../stores/EquipmentsStore'
import { LeaseStore } from '../stores/Leases'
import { useHistory, useParams } from 'react-router-dom'
import SelectSearch, { SelectSearchOption } from '../components/SelectSearch'
import { debounce } from 'lodash'
import { useQuery } from '../hooks/useQuery'

export function mapTimeUnit(unit: string) {
  switch (unit) {
    case 'day':
      return 'Dia'
    case 'week':
      return 'Semana'
    case 'month':
      return 'Mes'
  }
}

export const LeaseEquipmentForm = inject('leaseEquipmentStore', 'equipmentsStore', 'leaseStore', 'equipmentStore')(observer(({
  leaseEquipmentStore,
  equipmentsStore,
  leaseStore,
  equipmentStore
}: {
  leaseEquipmentStore: LeaseEquipmentStore,
  equipmentsStore: EquipmentsStore,
  leaseStore: LeaseStore,
  equipmentStore: EquipmentStore
}) => {

  const [inventory, setInventory] = useState<any>();
  const params = useParams<{ leaseId: string; }>();
  const query = useQuery();

  console.log('query', query.get('scaffold'))

  console.log('params', params);
  const fetchEquipments = useRef(debounce((searchText) => {
    equipmentsStore.getEquipments({
      search: searchText,
      scaffold: '',
    })
  }, 500)
  ).current;


  const onSubmit = (event: any) => {
    event.preventDefault()
    leaseEquipmentStore
      .save()
      .then(() => {
        history.push(`/home/lease/${leaseStore.id}/details`)
      })
  }
  const onCancel = () => {
    leaseEquipmentStore.restore()
  }

  const selectEquipmentCost = ({ value: equipmentCostId }: { value: string }) => {
    const form = equipmentStore.getCostByLeaseEquipmentCostId(equipmentCostId)
    leaseEquipmentStore.form.setField('cost', form?.fields.cost)
    leaseEquipmentStore.form.setField('timeUnit', form?.fields.unit)
  }
  const renderEquipmentCost = (state: any) => {
    const { element } = state
    const id = element && element.getAttribute('data-value')
    const meta = element && element.getAttribute('data-meta')
    const { cost, time_unit: timeUnit } = JSON.parse(meta)

    if (!id) return null

    const $row = $(
      `<div key='${id}' className='row'>
        <div className='col-2 '>
          ${numeral(cost).format('$0,0.00')}
        </div>
        <div className='col-2 '>
          ${timeUnit}
        </div>
      </div>`
    )
    return $row
  }
  const renderEquipmentItem = (option: SelectSearchOption) => {


    const { id, name, description, cost } = option.meta as any

    if (!id) return null


    return <div className='row select-row' style={{

      borderTop: '1px solid lightgray',

    }}>
      <div className='col-4 '>
        {name}
      </div>
      <div className='col-2 '>
        {numeral(cost).format('$0,0.00')}
      </div>
      <div className='col-6'>
        {description}
      </div>
    </div>


  }

  useEffect(() => {
    fetchEquipments('')
    leaseStore.id = params.leaseId;
  }, []);
  const history = useHistory();
  const equipmentOptions = equipmentsStore.availableEquipments.map(item => ({
    value: item.id,
    label: `${item.name} - ${item.cost} - ${item.description}`,
    meta: item
  }))
  const equipmentLeaseOptions = equipmentStore.leasesCost
    .filter(leaseCost => leaseCost.isValid)
    .map(leaseCost => {
      const { cost, unit, id } = leaseCost.fields
      const label = `${cost} - ${mapTimeUnit(unit)}`
      return {
        value: id,
        label,
        meta: JSON.stringify(leaseCost.fields)
      }
    })
  const timeUnit = mapTimeUnit(leaseEquipmentStore.form.getField('timeUnit')) || 'Dias'
  let currQty = leaseEquipmentStore.form.getField("qty")
  console.log("currQty", currQty)

  const onSelected = (option: SelectSearchOption) => {
    const { id, name, description, cost } = option.meta as any
    console.log('onClick', { id, name, description, cost })
    const store = leaseEquipmentStore.form
    const fieldName = 'equipmentId'
    store.setField(fieldName, id)

    equipmentStore.fetchData(id)
      .then((equipment) => {
        console.log("equipment", equipment)
        setInventory(equipment.inventory)
        leaseEquipmentStore.form.setField('initialHours', equipment.hours.toString())
      })
    return name;
  }

  const onSeachEquimpment = (seachText: string) => {
    fetchEquipments(seachText)
  }

  const machineName = leaseEquipmentStore.form.getField('equipmentId') ? equipmentOptions.find((option) => {
    return option.value === leaseEquipmentStore.form.getField('equipmentId');
  })?.label : ''

  console.log('leaseEquipmentStore.form.isValid', leaseEquipmentStore.form.isValid);
  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <Form
          preTitle='Contrato'
          title='Agregar'
          primaryButtonLabel='Agregar'
          disableSubmit={!leaseEquipmentStore.form.isValid || leaseEquipmentStore.saving || currQty > inventory}
          isSubmitLoading={leaseEquipmentStore.saving}
          onSubmit={onSubmit}
          onCancel={onCancel}
        >
          <>
            {/* <Select
            label='Maquinaria/Andamio'
            options={equipmentOptions}
            renderTemplate={renderEquipmentItem}
            {...bindEquipment()}
          /> */}
            <SelectSearch label='Maquinaria/Andamio'
              onSelected={onSelected}
              value={machineName}
              options={equipmentOptions}
              onSearch={onSeachEquimpment}
              renderItem={renderEquipmentItem} />
            {/* @ts-ignore */}
            <Select
              label='Tipo de costo'
              options={equipmentLeaseOptions}
              renderTemplate={renderEquipmentCost}
              value=''
              // @ts-ignore
              onChange={selectEquipmentCost}
            />
            <Input
              label='Costo'
              {...bindInput(leaseEquipmentStore.form, 'cost')}
            />
            <Input
              label={`Numero de ${timeUnit}`}
              {...bindInput(leaseEquipmentStore.form, 'unitQty')}
            />
            <Input
              label={`Cantidad - Disponibles(${inventory})`}
              {...bindInput(leaseEquipmentStore.form, 'qty')}
            />
          </>
        </Form>
      </div>

    </div>
  )
}))

export default LeaseEquipmentForm

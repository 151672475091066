import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import HeaderImage from '../components/HeaderImage'
import Input from '../components/Input'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import ProjectsStore, { ProjectStore, ProjectStoreProjectType } from '../stores/ProjectsStore'

const ProjectRow = observer(({
  projectStore,
  project,
  onDeleteClick
}: {
  projectStore: ProjectStore;
  project: ProjectStoreProjectType;
  onDeleteClick: () => void;
}) => {

  const {
    id,
    clientId,
    name,
    address,
    clientName,
    city,
    zip,
    state,
    leases,
    client,
  } = project;

  return (
    <div className='col-12 col-md-6 col-xl-4'>
      <div className='card'>
        <div className='card-body pointer'>
          <div className='row align-items-center'>
            <div className='col'>
              <h4 className='card-title mb-2 name'>
                {name}
                <span className='card-text small text-muted'>
                  {" " + address + ", " + city + "," + state + " " + zip}
                </span>
              </h4>
              <h3 className='card-title mb-2 name'>
                {clientName}
              </h3>
              <h5 className='card-title mb-2 name'>
                Contratos:{leases.length}
              </h5>
            </div>
            <div className='col-auto'>
              <div className='dropdown'>
                <a href='#!' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' data-expanded='false'>
                  <i className='fe fe-more-vertical' />
                </a>
                <div className='dropdown-menu dropdown-menu-right'>
                  <Link
                    to={`/home/project-form?id=${id}`}
                    className='dropdown-item'>
                    Editar
                  </Link>
                  <span onClick={() => {
                    if (leases.length > 0) {
                      let errorMsg = ''
                      if (leases.length) {
                        errorMsg += ` ${leases.length} contrato(s)`
                      }


                      return alert('No se puede borrar el proyecto por fue usado en:' + errorMsg)
                    }
                    onDeleteClick();
                  }} className='dropdown-item'>
                    Borrar
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})



const Projects = inject('projectsStore', 'projectStore')(observer(({
  projectStore,
  projectsStore,
}: {
  projectStore: ProjectStore;
  projectsStore: ProjectsStore;
}) => {

  useEffect(() => {

    projectStore.restore()
    fetchProjects()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDelete = (projectId: string) => {
    projectsStore.deleteProjectById(projectId)
  }

  const onSearchChange = debounce(({ target }) => {
    console.log('onSearchChange', target);
    projectsStore.setSearchValue(target.value)
    fetchProjects(target.value);

  }, 500)
  const fetchProjects = (search?: string) => {
    projectsStore.getProjects({ search })
  }
  const renderProject = (project: ProjectStoreProjectType) => {
    return <ProjectRow key={project.id} project={project} onDeleteClick={() => onDelete(project.id)} projectStore={projectStore} />
  }

  const projects = projectsStore.filteredProjects.map(project => renderProject(project))

  return (
    <div className='main-content'>
      <HeaderImage to='/home/project-form' title='Proyectos' />
      <div data-toggle='lists'>
        <div className='container-fluid' data-toggle='lists' data-lists-className='listAlias'>
          <div className='tab-content'>
            <div className='tab-pane fade active show' id='tabPaneOne' role='tabpanel'>
              <div className='row'>
                <div className='col-12'>
                  <Input
                    label='Buscar proyecto'
                    placeholder='Buscar...'
                    defaultValue={projectsStore.searchValue}
                    onChange={onSearchChange}
                  />
                </div>
              </div>
              <div className='row listAlias'>
                {projects}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}))

export default Projects

import { extendObservable, action, computed, toJS, makeObservable, observable } from 'mobx'
import { resolver } from '../utils/validator'
import _ from 'lodash'

export default class Form<T> {

  public fields: T;
  public errors: any;
  private defaults: T;
  private validators: any;
  constructor(defaults: T, validators: any) {
    this.defaults = defaults
    this.validators = validators
    this.fields = extendObservable({}, defaults)
    this.errors = extendObservable({}, _.mapValues(defaults as object, () => ''));
    makeObservable(this);
  }

  @action.bound
  initialize(fields) {
    _.forOwn(fields, (value, key) => {

      this.setField(key, value)
    })

    this.validate()
  }

  @action.bound setField(fieldName: string, value: any = '') {
    this.fields[fieldName] = value
    return this
  }

  getField(fieldName = '') {
    return this.fields[fieldName]
  }

  @action.bound resetField(fieldName = '') {
    const defaultValue = this.defaults[fieldName]
    this
      .setField(fieldName, defaultValue)
      .resetFieldError(fieldName)
    return this
  }

  @action.bound resetAllFields() {
    _.forOwn(this.fields, (fieldValue, fieldName) => this.resetField(fieldName))
    return this
  }

  getFieldValidator(fieldName = '') {
    return this.validators[fieldName]
  }

  throwIfUnknownError(fieldName = '') {
    if (!this.hasFieldError(fieldName)) {
      throw new Error(`"${fieldName}" error not found`)
    }
    return this
  }

  getValidatorError(fieldName = '') {
    const value = this.getField(fieldName)
    const validator = this.getFieldValidator(fieldName)

    if (!validator) return ''
    return resolver({ value: toJS(value), validator })
  }

  isFieldValid(fieldName = '') {
    return _.isEmpty(this.getValidatorError(fieldName))
  }

  @computed get isValid() {
    const fieldsValid = _.mapValues(this.fields as object, (fieldValue, fieldName) => this.isFieldValid(fieldName))
    return _.every(fieldsValid)
  }

  hasFieldError(fieldName: string) {
    return Object.hasOwn(this.errors, fieldName)
  }

  getFieldError(fieldName = '') {
    this.throwIfUnknownError(fieldName)
    return this.errors[fieldName]
  }

  @action.bound
  setFieldError = (fieldName: string, value: any) => {
    this.throwIfUnknownError(fieldName)
    this.errors[fieldName] = value
    return this
  }

  @action.bound
  resetFieldError = (fieldName: string) => {
    this.setFieldError(fieldName, '')
    return this
  }

  @action.bound
  validateField = (fieldName: string) => {
    const validatorError = this.getValidatorError(fieldName)
    this.setFieldError(fieldName, validatorError)
    return _.isEmpty(validatorError)
  }

  @action.bound
  validate = () => {
    const fieldsValid = _.mapValues(this.fields as object, (fieldValue, fieldName) => this.validateField(fieldName))
    return _.every(fieldsValid)
  }
}

import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchEmployees,
  createEmployee,
  updateEmployee,
  removeEmployee
} from '../sources/employee'
import Form from './Form'
import { isRequired, isInt } from '../utils/validator'
import SessionStore from './SessionStore'
import UiStore from './UiStore'
import { Employee } from './types/employee.types'

export class EmployeeStore {
  @observable id = ''
  @observable saving = false
  private sessionStore: SessionStore;
  private uiStore: UiStore;

  public form: Form<{
    firstName: string;
    lastName: string;
    salary: string;
  }>

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore; uiStore: UiStore; }) {
    this.form = new Form({
      firstName: '',
      lastName: '',
      salary: ''
    }, {
      firstName: isRequired(),
      lastName: isRequired(),
      salary: [
        isRequired(),
        isInt()
      ]
    })
    this.sessionStore = sessionStore
    this.uiStore = uiStore
  }

  async update() {
    const { firstName, lastName, salary } = toJS(this.form.fields)
    const { id: userId } = this.sessionStore
    const salaryInt = parseInt(salary, 10)

    return await updateEmployee({
      firstName,
      lastName,
      salary: salaryInt,
      userId,
      employeeId: this.id
    })
      .catch(this.uiStore.handleError('Error al editar empleado'))
  }

  async create() {
    const { firstName, lastName, salary } = toJS(this.form.fields)
    const { id } = this.sessionStore
    const salaryInt = parseInt(salary, 10)

    return await createEmployee({
      firstName,
      lastName,
      salary: salaryInt,
      userId: id
    })
      .catch(this.uiStore.handleError('Error al crear empleado'))
  }

  @action.bound
  async save() {
    this.saving = true
    let promise = null
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return await promise
      .then(() => {
        this.restore()
        this.saving = false
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  loadData({ id, firstName, lastName, salary }: { id: string; firstName: string; lastName: string; salary: string; }) {
    this.id = id
    this.form.initialize({
      firstName,
      lastName,
      salary
    })
  }

  @action.bound
  restore() {
    this.id = ''
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export class EmployeesStore {
  @observable employees: Employee[] = []
  private sessionStore: SessionStore;
  private uiStore: UiStore;

  constructor({ uiStore, sessionStore }: { uiStore: UiStore; sessionStore: SessionStore; }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore
    makeObservable(this)
  }

  @action.bound
  getEmployees(params?: {
    searchText: string;
  }) {
    const { searchText = '' } = params || {}
    return fetchEmployees({
      searchText,
      limit: 50
    })
      .then(({ data }) => {
        console.log('fetchEmployees data', data);
        this.employees = data
      })
      .catch(this.uiStore.handleError('Error al obtener empleados'))
  }

  @action.bound
  deleteEmployeeById(id = '') {
    const { id: userId } = this.sessionStore
    const employeeIndex = this.employees.findIndex(employee => employee.id === id)

    if (employeeIndex < 0) return
    return removeEmployee({ employeeId: id })
      .then(() => {
        this.getEmployees()
      })
      .catch(this.uiStore.handleError('Error al borrar empleado'))
  }

  getEmployeeById(id: string) {
    return this.allEmployees.find(employee => employee.id === id)
  }

  @computed get allEmployees() {
    return this.employees.map(({
      id,
      first_name: firstName,
      last_name: lastName,
      salary
    }) => ({
      id,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      salary
    }))
  }
}

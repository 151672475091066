

const Card = ({ children }: { children: JSX.Element; }) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row align-items-center'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Card;
import React, { useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import HeaderImage from '../../components/HeaderImage'
import Input from '../../components/Input'
import ClientRow from './containers/ClientRow'

import Client from './components/Client'
import ClientDocuments from './components/ClientDocuments'
import ClientsStore, { ClientStore } from '../../stores/ClientsStore'
import Session from '../../stores/SessionStore'
import { debounce } from 'lodash'

export const Clients = inject('clientsStore', 'clientStore', 'sessionStore')(observer(({ clientsStore, clientStore, sessionStore }: {
  clientsStore: ClientsStore;
  clientStore: ClientStore;
  sessionStore: Session;
}) => {
  const match = useRouteMatch();

  const fetchClients = useRef(debounce((search?: string) => {
    clientsStore.getClients({
      search,
    })
  }, 500)).current;

  useEffect(() => {

    fetchClients()
    clientStore.restore()
  }, []);


  const onClientClick = (clientData: any) => {
    clientStore.loadData(clientData)
  }

  const onSearchChange = ({ currentTarget }: any) => {

    clientsStore.setSearchValue(currentTarget.value)
  }



  const removeClient = (id: string) => {

    clientsStore.removeClientById(id)
  }

  const renderClient = (client: any) => {
    return (
      <ClientRow
        key={client.id}
        client={client}
        match={match}
        onClientClick={onClientClick}
        onRemove={() => removeClient(client.id)}
      />
    )
  }

  useEffect(() => {
    fetchClients(clientsStore.searchValue)
  }, [clientsStore.searchValue, fetchClients]);



  const clients = clientsStore.allClients.map(renderClient)
  return (
    <div className='main-content'>
      <HeaderImage to='/home/clients/client-form' title='Clientes' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Input
              label='Buscar cliente'
              placeholder='Buscar...'
              value={clientsStore.searchValue}
              onChange={onSearchChange}
            />
          </div>
          <div className='col-12'>
            {clients}
          </div>

        </div>
      </div>
    </div>
  )

}))

const ClientRouter = () => {

  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={Clients} />
      <Route path={`${match.url}/client-form`} component={Client} />
      <Route path={`${match.url}/:clientId/documents`} component={ClientDocuments} />
    </Switch>
  )
}

export default ClientRouter

import { LeaseCost } from '../stores/types/lease.types';
import request, { ancoAPI } from '../utils/http-client'

export function createEquipment({
  branchId = '',
  name = '',
  description = '',
  cost = 0,
  hours = 0,
  inventory = 1,
  scaffold = false,
  leaseCost = [],
  photos
}: {
  branchId?: string;
  name?: string;
  description?: string;
  cost?: number;
  hours?: number;
  inventory?: number;
  scaffold?: boolean;
  leaseCost: LeaseCost[],
  photos: File[]
}) {

  const formData = new FormData();

  formData.append('name', name)
  formData.append('description', description)
  formData.append('hours', `${hours}`)
  formData.append('cost', cost.toString())
  formData.append('scaffold', `${scaffold}`)
  formData.append('inventory', `${inventory}`)
  formData.append('branch_id', branchId)
  formData.append('leaseCost', JSON.stringify(leaseCost))

  photos.forEach((photo, index) => {
    formData.append(`photos[]`, photo)
  })

  return ancoAPI
    .post('/api/equipments', formData)
}

export function fetchEquipments({
  search = '',
  limit = 50,
  offset = 0,
  scaffold = false,
}: {
  search?: string;
  limit?: number;
  offset?: number;
  scaffold?: boolean | string;
}) {
  return ancoAPI
    .get(`/api/equipments?search=${search}&limit=${limit}&offset=${offset}&scaffold=${scaffold}`)
}

export function fetchEquipment(id: string) {
  return ancoAPI
    .get(`/api/equipments/${id}`)
}

export function updateEquipment({
  equipmentId = '',
  branchId = '',
  name = '',
  description = '',
  cost = 0,
  hours = 0,
  inventory,
  scaffold,
  leaseCost,
  photos
}: {
  equipmentId: string;
  branchId?: string;
  description?: string;
  name?: string;
  cost?: number;
  hours?: number;
  inventory?: number;
  scaffold?: boolean;
  leaseCost: LeaseCost[];
  photos: File[]
}) {
  const formData = new FormData();


  formData.append('name', name);
  formData.append('description', description);
  formData.append('hours', `${hours}`);
  formData.append('cost', `${cost}`);
  formData.append('inventory', `${inventory}`);
  formData.append('scaffold', `${scaffold}`);
  formData.append('branch_id', branchId);
  formData.append('leaseCost', JSON.stringify(leaseCost));

  photos.forEach((photo, index) => {
    formData.append(`photos[]`, photo)
  })


  return ancoAPI
    .put(`/api/equipments/${equipmentId}`, formData);
}

export function removeEquipment(equipmentId = '') {
  return ancoAPI
    .delete(`/api/equipments/${equipmentId}`)
}


export const deleteEquipmentPhoto = (equipmentPhotoId: string) => {
  return ancoAPI
    .delete(`/api/equipment-photos/${equipmentPhotoId}`)
}
import request, { ancoAPI } from '../utils/http-client'

export function fetchExtensions({ leaseId, leaseEquipmentId }) {
  return ancoAPI
    .get(`/api/lease-equipment-extension/${leaseId}/lease-equipment/${leaseEquipmentId}/extension`)
}

export function createExtension({ leaseId, leaseEquipmentId, equipmentId, cost, days }) {
  return ancoAPI
    .post(`/api/lease-equipment-extension/${leaseId}/lease-equipment/${leaseEquipmentId}/extension`, {
      equipment_id: equipmentId,
      cost,
      days
    });
}

export function removeExtension({ leaseId, leaseEquipmentId, extensionId }) {
  return ancoAPI
    .delete(`/api/lease-equipment-extension/${leaseId}/lease-equipment/${leaseEquipmentId}/extension/${extensionId}`)
}

export function printNote({ leaseId, leaseEquipmentId, extensionId }) {
  return ancoAPI
    .get(`/api/lease-equipment-extension/${leaseId}/lease-equipment/${leaseEquipmentId}/extensions/${extensionId}/pdf`)
}

import React from 'react'
import { inject } from 'mobx-react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import MaintenanceDetailForm from './MaintenanceDetailForm'
import { Inventory } from './components/Inventory/Inventory'
import Supplier from '../Supplier'
import { CreateInventory } from './components/Inventory/CreateInventory'
import { ClientMachines } from './components/ClientMachines/ClientMachines'
import { CreateClientMachine } from './components/ClientMachines/CreateClientMachine'
import Suppliers from '../Suppliers'
import { Quotes } from './components/Quotes/Quotes'
import { CreateQuote } from './components/Quotes/CreateQuote'
import { QuoteItems } from './components/Quotes/QuoteItems'
import { UpdateInventory } from './components/Inventory/UpdateInventory'
import { UpdateClientMachine } from './components/ClientMachines/UpdateClientMachine'
import { UpdateQuote } from './components/Quotes/UpdateQuote'
import { MaintenanceStore } from '../../stores/Maintenances'
import { RepairQuotePDF } from './components/Quotes/QuoteItems/RepairQuotePDF'

const Maintenance = inject('maintenanceStore')(({
  maintenanceStore,
  id,
  name,
  description,
  cost,
  equipmentId,
  equipmentName,
  onDeleteClick
}: {
  id: string;
  name: string;
  description: string;
  cost: number;
  equipmentId: string;
  equipmentName: string;
  onDeleteClick: () => void;
  maintenanceStore: MaintenanceStore
}) => {

  const onMaintenanceClick = (maintenanceData: any) => {
    maintenanceStore.loadData(maintenanceData)
  }

  return (
    <tr>
      <td className='supply-name'>
        {name}
      </td>
      <td className='supply-description'>
        {description}
      </td>
      <td className='supply-description'>
        {cost}
      </td>
      <td className='supply-description'>
        {equipmentName}
      </td>
      <td>
        <Link
          className='btn btn-sm btn-info d-none d-md-inline-block'
          to={`/home/maintenance/${id}/details`}
          onClick={() => onMaintenanceClick({ id, name, description, cost, equipmentId })}
        >
          Ver detalles
        </Link>
      </td>
      <td >
        <Link
          className='btn btn-sm btn-info d-none d-md-inline-block'
          to={`/home/maintenance-form?id=${id}`}
          onClick={() => onMaintenanceClick({ id, name, description, cost, equipmentId })}
        >
          Editar
        </Link>
      </td>
      <td>
        <Link
          className='btn btn-sm btn-danger d-none d-md-inline-block'
          to={`/home/maintenances`}
          onClick={onDeleteClick}
        >
          Borrar
        </Link>
      </td>
    </tr>
  )
});

export const Maintenances = () => {

  const match = useRouteMatch();

  return (
    <div className='main-content'>
      <Switch >
        <Route path={`${match.url}/suppliers/supplier`} component={Supplier} />
        <Route path={`${match.url}/suppliers`} component={Suppliers} />
        <Route path={`${match.url}/inventory/update-inventory/:inventoryItemId`} component={UpdateInventory} />
        <Route path={`${match.url}/inventory/create-inventory`} component={CreateInventory} />
        <Route path={`${match.url}/inventory`} component={Inventory} />
        <Route path={`${match.url}/client-machines/create-client-machine`} component={CreateClientMachine} />
        <Route path={`${match.url}/client-machines/update-client-machine/:clientMachineId`} component={UpdateClientMachine} />
        <Route path={`${match.url}/client-machines`} component={ClientMachines} />
        <Route path={`${match.url}/maintenance-form`} component={Maintenance} />
        <Route
          path={`${match.url}/maintenance/:maintenanceId/detail-form`}
          component={MaintenanceDetailForm}
        />
        <Route path={`${match.url}/quotes/add-items/:quoteId`} component={QuoteItems} />
        <Route path={`${match.url}/quotes/create-quote`} component={CreateQuote} />
        <Route path={`${match.url}/quotes/update-quote/:quoteId`} component={UpdateQuote} />
        <Route path={`${match.url}/quotes/:quoteId/print`} component={RepairQuotePDF} />
        <Route path={`${match.url}/quotes`} component={Quotes} />
      </Switch>
    </div>
  )

};
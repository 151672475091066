import request, { ancoAPI } from '../utils/http-client'

export function fetchMaintenances(userId = '') {
  return ancoAPI
    .get(`/api/maintenance`)
}

export function createMaintenance({ name, description, cost, userId, equipmentId }) {
  return ancoAPI
    .post(`/api/maintenance`, {
      name,
      description,
      cost,
      equipment_id: equipmentId
    })
}

export function updateMaintenance({
  name = '',
  description = '',
  cost = 0,
  userId = '',
  equipmentId = '',
  maintenanceId = ''
}) {
  return ancoAPI
    .put(`/api/maintenance/${maintenanceId}`, {
      name,
      description,
      cost,
      equipment_id: equipmentId
    })
}

export function removeMaintenance({ userId = '', maintenanceId = '' }) {
  return ancoAPI
    .delete(`/api/maintenance/${maintenanceId}`)
}

import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import './RepairQuotePDF.css'
import { ancoAPI } from '../../../../../../utils/http-client';
import { useHistory, useParams } from 'react-router-dom';
import { Quote } from '../../Quotes.types';
import AncoLogo from './assets/response.jpeg'
import numeral from 'numeral';

export const RepairQuotePDF = () => {
  const quoteRef = useRef<HTMLDivElement>(null);
  const params = useParams<{
    quoteId: string
  }>()
  const [quote, setQuote] = useState<Quote>();
  const history = useHistory();

  const getQuoteDetails = async () => {
    const { data } = await ancoAPI.get<Quote>(`/api/quote/${params.quoteId}`)
    setQuote(data);
  }

  useEffect(() => {
    getQuoteDetails();
  }, []);

  const handlePrint = async () => {
    //@ts-ignore
    const canvas = await html2canvas(quoteRef.current, {
      allowTaint: false,
      useCORS: true,
    });
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',

    });

    const imgData = canvas.toDataURL('image/jpg');
    pdf.addImage(imgData, 'JPG', 0, 0, 215.9, 279.4);
    pdf.save(`quote-${params.quoteId}.pdf`);
  };


  let total = (quote?.quoteItems.reduce((sum, quoteItem) => {
    return sum + quoteItem.quantity * quoteItem.sale_price
  }, 0) ?? 0)

  total = quote?.quoteLabors.reduce((sum, quoteLabor) => {
    return sum + parseFloat(quoteLabor.cost);
  }, total) ?? total


  if (!quote?.accepted) {
    total += 500;
  }

  // Logic to show extra section
  let showExtras = false;

  if (!quote?.accepted) {
    showExtras = true;
  }

  return (
    <div>
      <div className='container' style={{
        display: 'flex',
        justifyContent: 'space-evenly'
      }}>
        <button className='btn btn-danger' onClick={() => {
          history.goBack();
        }}>
          Atras
        </button>
        <button className='btn btn-primary' onClick={handlePrint}>Imprimir Cotizacion</button>
      </div>

      <div ref={quoteRef} className="quote" style={{ color: '#4caf50', width: '210mm', height: '297mm', margin: '0 auto', padding: '10mm', boxSizing: 'border-box', fontSize: '14px' }}>
        <img src={AncoLogo} className="img-fluid" alt='Anco logo' />
        <h1><strong>Cotizacion:</strong> {`${quote?.name}`}</h1>
        <table className="quote-table">
          <thead>
            <tr>
              <th>Concepto</th>
              <th>Precio</th>
              <th>Cantidad</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {quote?.quoteItems?.map((quoteItem) => {
              return <tr key={quoteItem.id}>
                <td>{quoteItem.name}</td>
                <td>${quoteItem.cost}</td>
                <td>{quoteItem.quantity}</td>
                <td>{numeral(quoteItem.quantity * quoteItem.sale_price).format('$0,0.00')}</td>
              </tr>
            })}
          </tbody>
        </table>

        <h3>Mano de Obra</h3>
        <table className="quote-table">
          <thead>
            <tr>
              <th>Encargado</th>
              <th>Description</th>
              <th>Hours</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {quote?.quoteLabors?.map((labor) => {
              const employee = labor.employee
              let employeeName = `${employee.first_name}`

              if (employee.last_name) {
                employeeName += ` ${employee.last_name}`;
              }
              return (
                <tr key={labor.id}>
                  <td>{employeeName}</td>
                  <td>{labor.description}</td>
                  <td>{labor.hours}</td>
                  <td>{numeral(parseFloat(labor.cost) * labor.hours).format('$0,0.00')}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {showExtras && <><h3>
          <strong>
            Extras
          </strong>
        </h3>
          <table className="quote-table">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Costo</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>

              {!quote?.accepted && <tr>
                <td>Costo de diagnostico</td>
                <td>1</td>
                <td>$500</td>
                <td>$500</td>
              </tr>}
            </tbody>
          </table>
        </>}
        <h1 style={{
          color: 'black'
        }}>
          Total: {numeral(total).format('$0,0.00')}
        </h1>
      </div>

    </div>
  );
}



import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import HeaderImage from '../components/HeaderImage'
import { Table, TableBody, TableHead } from '../components/Table'
import { Link, useRouteMatch } from 'react-router-dom'
import SuppliersStore, { SupplierStore } from '../stores/SuppliersStore'
import { Supplier } from '../stores/types/supplier.types'

const SupplierRow = inject('supplierStore')(observer(({ supplierStore, supplierData, onDeleteClick }: {
  supplierData: any;
  onDeleteClick: () => void;
  supplierStore?: SupplierStore;
}) => {
  const { id, name, rfc, street, phone, email, number, neighborhood, city, state, zip } = supplierData
  const onEditClick = () => {
    supplierStore?.loadData(supplierData)
  }
  return (
    <tr>
      <td className='supervisor-name'>{name}</td>
      <td className='supervisor-rfc'>{rfc}</td>
      <td className='supervisor-phone'>{phone}</td>
      <td className='supervisor-email'>{email}</td>
      <td>
        <Link className='btn btn-sm btn-info d-none d-md-inline-block' to={`/home/supplier?id=${id}`} onClick={onEditClick}>
          Editar
        </Link>
      </td>
      <td>
        <Link className='btn btn-sm btn-danger d-none d-md-inline-block' to={`/home/suppliers`} onClick={onDeleteClick}>
          Borrar
        </Link>
      </td>
    </tr>
  )
}))

const Suppliers = inject('suppliersStore')(observer(({
  suppliersStore
}: {
  suppliersStore: SuppliersStore;
}) => {

  useEffect(() => {
    suppliersStore.getSuppliers()
  }, [])
  const onRemove = (supplierId: string) => {
    suppliersStore.deleteSupplierById(supplierId)
  }
  const renderSupplier = (supplier: Supplier) => {
    return <SupplierRow key={supplier.id} supplierData={supplier} onDeleteClick={() => onRemove(supplier.id)} />
  }
  const rows = suppliersStore.suppliers.map(renderSupplier)
  const match = useRouteMatch()
  return (
    <div className='main-content'>
      <HeaderImage to={`${match.url}/supplier`} title='Provedores' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Table>
              <TableHead>
                <th>
                  <span className='text-muted sort' data-sort='supervisor-name'>
                    Nombre
                  </span>
                </th>
                <th>
                  <span className='text-muted sort' data-sort='supervisor-rfc'>
                    RFC
                  </span>
                </th>
                <th>
                  <span className='text-muted sort' data-sort='supervisor-phone'>
                    PHONE
                  </span>
                </th>
                <th>
                  <span className='text-muted sort' data-sort='supervisor-email'>
                    EMAIL
                  </span>
                </th>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}))

export default Suppliers

import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import Form from '../components/Form'
import Input from '../components/Input'
import Select from '../components/Select'
import { File } from '../components/File'
import { bindInput, bindSelect } from '../utils/bindField'
import EquipmentLeaseCost from './EquipmentLeaseCost'
import { useHistory, useParams } from 'react-router-dom'
import { EquipmentStore } from '../stores/EquipmentsStore'
import BranchesStore from '../stores/BranchesStore'
import { useQuery } from '../hooks/useQuery'
import { EquipmentPhoto } from '../stores/types/equipment.types'

const Equipment = inject('equipmentStore', 'branchesStore')(observer(({ equipmentStore, branchesStore }: { equipmentStore: EquipmentStore; branchesStore: BranchesStore }) => {
  const history = useHistory();
  const query = useQuery()
  const params = useParams<{ scaffold: string }>()

  const equipmentId = query.get('id')
  const scaffold = !!params.scaffold

  const isNew = !!!equipmentId;
  let hasValues = !!scaffold
  equipmentStore.form.setField("scaffold", `${hasValues}`)

  useEffect(() => {

    fetchEquipmentData()
    fetchBranches()
  }, []);

  const fetchEquipmentData = async () => {
    if (equipmentId) {
      const equipment = await equipmentStore.fetchData(equipmentId)
      equipmentStore.loadData(equipment);
    }
  }
  const fetchBranches = () => {
    branchesStore.getBranches()
  }
  const onSubmit = (event: any) => {

    event.preventDefault()
    equipmentStore
      .save()
      .then(() => history.push('/home/equipments/' + (scaffold ? "scaffold" : "")))
  }
  const onCancel = () => {
    console.log('onCancel')
    equipmentStore.restore()
  }

  const onFileDrop = ([file]: any[], index: number) => {
    equipmentStore.photos[index] = Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
  }



  let type = !scaffold ? "equipo" : "andamio"
  const title = isNew ? `Crear ${type}` : `Actualizar ${type}`

  const buttonLabel = isNew ? `Crear ${type} nuevo` : `Editar ${type}`
  const branchOptions = branchesStore.allBranches.map(branch => ({
    value: branch.id,
    label: branch.name
  }))
  const isFormValid = equipmentStore.form.isValid && equipmentStore.isLeasesCostValid

  console.log('equipmentStore.form.isValid ', equipmentStore.form.isValid)
  console.log('equipmentStore.isLeasesCostValid ', equipmentStore.isLeasesCostValid)
  return (
    <Form
      //@ts-ignore
      preTitle={`${type.capitalize()} de renta`}
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!isFormValid || equipmentStore.saving}
      isSubmitLoading={equipmentStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label={`Nombre del ${type}`}
          {...bindInput(equipmentStore.form, 'name')}
        />
        <Input
          label={`Descripcion del ${type}`}
          {...bindInput(equipmentStore.form, 'description')}
        />
        {scaffold && <Input
          label={`Cantidad`}
          {...bindInput(equipmentStore.form, 'inventory')}
        />}
        <Select
          label='Sucursal'
          options={branchOptions}
          {...bindSelect(equipmentStore.form, 'branchId')}
        />
        {!scaffold && <Input
          label='Horometro'
          {...bindInput(equipmentStore.form, 'hours')}
        />}
        <EquipmentLeaseCost />
        <Input
          label={`Costo del ${type}`}
          {...bindInput(equipmentStore.form, 'cost')}
        />
        {equipmentStore.equipmentPhotos.map((photo, index) => {
          const tempPhoto: EquipmentPhoto & { id?: string; preview?: string; } = photo;
          return <File key={index}
            id={tempPhoto.id}
            label='Arrastra la imagen o click para seleccionar archivo'
            //@ts-ignore
            alt={`${type.capitalize()} de renta imagen`}
            src={tempPhoto.preview || equipmentStore.buildUrl(photo.url)}
            onDelete={() => {
              if (window.confirm('Estas seguro que deseas eliminar la foto')) {
                equipmentStore.removePhoto(photo.id)
              }

            }}
            onCancel={() => {
              equipmentStore.cancelPhoto(index)
            }}
            onDrop={(files: any[]) => {
              onFileDrop(files, index)
            }} />
        })}
        <button type="button" title='Agregar Foto' onClick={() => {
          equipmentStore.addPhoto();
        }} className='btn btn-primary' >
          + Agregar Foto
        </button>
      </>
    </Form>
  )

}))

export default Equipment

import request, { ancoAPI } from '../utils/http-client'

export function fetchSupplies(userId = '') {
  return ancoAPI
    .get(`/api/supply`)
}

export function createSupply({ name, unit, description, userId }) {
  return ancoAPI
    .post(`/api/supply`, {
      name,
      unit,
      description
    })
}

export function updateSupply({ name, unit, description, userId, supplyId }) {
  return ancoAPI
    .put(`/api/supply/${supplyId}`, {
      name,
      unit,
      description
    })
}

export function removeSupply({ userId, supplyId }) {
  return ancoAPI
    .delete(`/api/supply/${supplyId}`)
}

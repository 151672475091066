import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchDetails,
  createDetail,
  removeDetail
} from '../sources/maintenance-details'
import Form from './Form'
import { isRequired, isInt } from '../utils/validator'
import SessionStore from './SessionStore'
import { MaintenanceStore } from './Maintenances'
import UiStore from './UiStore'

export class MaintenanceDetailsStore {
  @observable details: any[] = []
  @observable saving = false
  private sessionStore: SessionStore;
  private maintenanceStore: MaintenanceStore;
  private uiStore: UiStore;
  public form: Form<{
    qty: string;
    employeeId: string;
    inventoryId: string;
  }>;


  constructor({ sessionStore, maintenanceStore, uiStore }: {
    sessionStore: SessionStore;
    maintenanceStore: MaintenanceStore;
    uiStore: UiStore;
  }) {
    this.sessionStore = sessionStore
    this.maintenanceStore = maintenanceStore
    this.uiStore = uiStore
    this.form = new Form({
      qty: '',
      employeeId: '',
      inventoryId: ''
    }, {
      qty: [
        isRequired(),
        isInt()
      ],
      employeeId: isRequired(),
      inventoryId: isRequired()
    });
    makeObservable(this);
  }

  @action.bound
  create() {
    const { id: userId } = this.sessionStore
    const { id: maintenanceId } = this.maintenanceStore
    const formData = toJS(this.form.fields)
    this.saving = true

    return createDetail({
      userId,
      maintenanceId,
      qty: parseInt(formData.qty, 10),
      employeeId: formData.employeeId,
      inventoryId: formData.inventoryId
    })
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.uiStore.setError('Error al crear detalle de mantenimiento')
        this.saving = false
        throw error
      })
  }

  @action.bound
  deleteDetailById(id = '') {
    const { id: userId } = this.sessionStore
    const { id: maintenanceId } = this.maintenanceStore
    const detailIndex = this.details.findIndex(detail => detail.id === id)

    if (detailIndex < 0) return
    return removeDetail({ userId, maintenanceId, detailId: id })
      .then(() => {
        this.details = [
          ...this.details.slice(0, detailIndex),
          ...this.details.slice(detailIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar detalle de mantenimiento'))
  }

  @action.bound
  restore() {
    this.saving = false
    this.form.resetAllFields()
  }

  @action.bound
  getDetails() {
    const { id: userId } = this.sessionStore
    const { id: maintenanceId } = this.maintenanceStore

    return fetchDetails({ userId, maintenanceId })
      .then(({ data }) => {
        const details = data
        this.details = details
      })
      .catch(this.uiStore.handleError('Error al obtener detalles de mantenimiento'))
  }

  @computed get allDetails() {
    return this.details.map(detail => ({
      id: detail.id,
      qty: detail.qty,
      employeeName: `${detail.employee_first_name} ${detail.employee_last_name}`,
      supplierName: detail.supplier_name,
      supplyName: detail.supply_name
    }))
  }
}

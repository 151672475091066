import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import LeaseDetails from './LeaseDetails'
import LeaseEquipmentForm from './LeaseEquipmentForm'
import ExtensionDetails from './ExtensionDetails'
import ExtensionForm from './ExtensionForm'
import ReturnForm from './ReturnForm'

export default function Lease() {
  const match = useRouteMatch();
  return (
    <React.Fragment>
      <Route path={`${match.url}/:leaseId/details`} component={LeaseDetails} />
      <Route path={`${match.url}/:leaseId/equipment-form`} component={LeaseEquipmentForm} />
      <Route path={`${match.url}/:leaseId/equipment/:leaseEquipmentId/details`} component={ExtensionDetails} />
      <Route path={`${match.url}/:leaseId/equipment/:leaseEquipmentId/extension-form`} component={ExtensionForm} />
      <Route path={`${match.url}/:leaseId/equipment/:leaseEquipmentId/return-form`} component={ReturnForm} />
    </React.Fragment>
  )
}

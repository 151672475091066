
import classnames from 'classnames'
import { useHistory } from 'react-router-dom';

const Form = ({
  children,
  preTitle,
  title,
  onCancel,
  onSubmit,
  isSubmitLoading,
  notPage,
  primaryButtonLabel,
  disableSubmit
}
  : {
    children: JSX.Element;
    preTitle?: string;
    title?: string;
    disableSubmit?: boolean;
    isSubmitLoading?: boolean;
    notPage?: boolean;
    primaryButtonLabel?: string;
    onCancel?: () => void;
    onSubmit: (e: any) => void;
  }) => {

  const history = useHistory();


  const submitButtonClassName = classnames('btn', 'btn-block', 'btn-primary', {
    'is-loading': isSubmitLoading
  })

  const form = (
    <div className='row justify-content-center'>
      <div className='col-12 col-lg-10 col-xl-8'>
        <div className='header mt-md-5'>
          <div className='header-body'>
            <div className='row align-items-center'>
              <div className='col'>
                <h6 className='header-pretitle'>
                  {preTitle}
                </h6>
                <h1 className='header-title'>
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <form className='mb-4' onSubmit={onSubmit}>
          {children}

          <hr className='mt-4 mb-5' />

          {primaryButtonLabel && primaryButtonLabel.length > 0 ? (
            <button className={submitButtonClassName} disabled={disableSubmit}>
              {primaryButtonLabel}
            </button>
          ) : null}
          <button onClick={(e) => {
            e.preventDefault();
            history.goBack()
            if (onCancel)
              onCancel();
          }} className='btn btn-block btn-link text-muted'>
            Cancelar
          </button>
        </form>
      </div>
    </div>
  )

  if (notPage === true) return form

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        {form}
      </div>
    </div>
  )

}

export default Form;
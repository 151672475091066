
const CardSearch = ({
  title,
  value,
  onChange,
  children
}: {
  title: string;
  value: string;
  onChange: (target: any) => void;
  children: JSX.Element;
}) => {
  return (
    <div className='card' data-toggle='lists'>
      <div className='card-header'>
        <div className='row align-items-center'>
          <div className='col'>

            <h4 className='card-header-title'>
              {title}
            </h4>
          </div>
        </div>
      </div>
      <div className='card-header'>
        <div className='input-group input-group-flush input-group-merge'>
          <input
            type='search'
            className='form-control form-control-prepended search'
            placeholder='Buscar'
            value={value}
            onChange={onChange}
          />
          <div className='input-group-prepend'>
            <div className='input-group-text'>
              <span className='fe fe-search' />
            </div>
          </div>
        </div>
      </div>
      <div className='card-body'>
        {children}
      </div>
    </div>
  )
}
export default CardSearch;

import { saveAs } from 'file-saver'
import moment from 'moment'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchLeases,
  createLease,
  updateLease,
  removeLease,
  downloadLeasePdf,
  downloadLeaseNotePdf,
} from '../sources/lease'
import Form from './Form'
import { isRequired, optional, isDate } from '../utils/validator'
import UiStore from './UiStore'
import { Lease } from './types/lease.types'
import dayjs from 'dayjs'


export type LeaseFormFields = {
  id?: string;
  humanId?: number | undefined;
  deliveryDate?: Date;
  receivedDate?: Date;
  clientId: string;
  projectId: string;
  branchId: string;
  requestor: string;
  noTax: boolean;

}
export class LeaseStore {
  @observable id: string | undefined;
  @observable humanId: number | undefined;
  @observable saving = false
  private uiStore: UiStore;
  public form: Form<LeaseFormFields>;

  constructor({ uiStore }: { uiStore: UiStore }) {
    this.uiStore = uiStore
    this.form = new Form({
      clientId: '',
      projectId: '',
      branchId: '',
      requestor: '',
      noTax: false,
      deliveryDate: undefined,
      receivedDate: undefined,
      humanId: 0,
      id: undefined,
    }, {
      deliveryDate: optional(isDate('Fecha invalida')),
      receivedDate: optional(isDate('Fecha invalida')),
      clientId: isRequired(),
      projectId: isRequired(),
      branchId: isRequired(),
      requestor: isRequired()
    })

    makeObservable(this);
  }

  create() {
    const formData = toJS(this.form.fields)

    return createLease(formData)
      .catch(this.uiStore.handleError('Error al crear contrato'))
  }

  update() {
    const {
      clientId,
      projectId,
      branchId,
      requestor,
      noTax,
      deliveryDate,
      receivedDate
    } = toJS(this.form.fields)

    let updateData: any = {
      clientId,
      projectId,
      branchId,
      requestor,
      noTax,
      leaseId: this.id,
    }

    if (deliveryDate)
      updateData.deliveryDate = dayjs(deliveryDate).toDate()
    if (receivedDate)
      updateData.receivedDate = dayjs(receivedDate).toDate()

    return updateLease(updateData)
      .catch(this.uiStore.handleError('Error al editar contrato'))
  }

  @action.bound
  save() {
    let promise = null
    this.saving = true
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return promise
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  loadData({ id, humanId, ...lease }: LeaseFormFields) {
    this.id = id
    this.humanId = humanId
    this.form.initialize(lease)
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id?.length === 0
  }
}

export type LeaseStoreObjType = {
  id: string;
  humanId: number;
  clientId: string;
  projectId: string;
  branchId: string;
  branchName: string;
  serialprefix: string;
  clientName: string;
  projectName: string;
  requestor: string;
  noTax: boolean
  canceled: boolean
  deliveryDate: string;
  receivedDate: string;
  createdDate: Date;
}

export default class LeasesStore {
  @observable leases: Lease[] = []
  @observable searchValue = ''
  @observable fullCount = 0
  @observable fetchingLeases = false
  private uiStore: UiStore;
  constructor({ uiStore }: { uiStore: UiStore }) {
    this.uiStore = uiStore
    makeObservable(this);
  }

  downloadById(id: string, humanId: number) {
    const error = 'Error al generar contrato, asegurese que los siguientes datos esten correctos:'
    downloadLeasePdf(id)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        saveAs(blob, 'contrato' + humanId + '.pdf')
      })
      .catch(this.uiStore.handleError(error, [
        'Informacion del cliente',
        'Informacion del proyecto',
        'Sucursal',
        'Equipos de renta en el contrato'
      ]))
  }

  downloadNoteById(id: string, humanId: number) {
    const error = 'Error al generar nota de remision, asegurese que los siguientes datos esten correctos:'
    downloadLeaseNotePdf(id)
      .then((response) => {
        saveAs(response.data, 'nota_remision' + humanId + '.pdf')
      })
      .catch(this.uiStore.handleError(error, [
        'Informacion del cliente',
        'Informacion del proyecto',
        'Sucursal',
        'Equipos de renta en el contrato'
      ]))
  }

  @action.bound
  setSearchValue(value: string) {
    this.searchValue = value
  }

  @action.bound
  deleteById(id = '') {
    const leaseIndex = this.leases.findIndex(lease => lease.id === id)

    if (leaseIndex < 0) return
    return removeLease(id)
      .then(() => {
        this.leases = [
          ...this.leases.slice(0, leaseIndex),
          ...this.leases.slice(leaseIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar contrato'))
  }

  @action.bound
  getLeases(data: { limit: number; offset: number; search?: string; branchId?: string }) {
    let { limit = 50, offset = 0, search = "", branchId } = data

    this.fetchingLeases = true
    return fetchLeases({
      limit,
      offset,
      search,
      branchId
    })
      .then(({ data }) => {

        const leases = data
        this.leases = leases
        let firstRow = leases.find(() => true)
        this.fullCount = firstRow ? firstRow.full_count : 0
      })
      .catch(this.uiStore.handleError('Error al obtener contratos'))
      .finally(() => {
        this.fetchingLeases = false
      })
  }



  @computed get allLeases() {
    return this.leases.map(lease => {

      return {
        id: lease.id,
        humanId: lease.human_id,
        clientId: lease.client.id,
        projectId: lease.project.id,
        branchId: lease.branch.id,
        branchName: lease.branch.name,
        serialprefix: lease.branch.serialprefix,
        clientName: lease.client.name,
        projectName: lease.project.name,
        requestor: lease.requestor,
        noTax: lease.no_tax,
        canceled: lease.canceled,
        createdDate: lease.created_date,
        deliveryDate: lease.delivery_date ? moment(lease.delivery_date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : '',
        receivedDate: lease.received_date ? moment(lease.received_date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''
      } as LeaseStoreObjType
    })
  }

  @computed get filteredLeases() {
    if (this.searchValue.length === 0) return this.allLeases

    return this.allLeases;
  }
}


import { ClientMachine } from '../pages/Maintenances/components/ClientMachines/ClientMachine.types';
import { ancoAPI } from '../utils/http-client'

const fetchClientMachineById = ({
  clientMachineId
}: {
  clientMachineId?: string;
}) => {
  return ancoAPI
    .get<ClientMachine>(`/api/client-machine/${clientMachineId}`)
}



export const clientMachineSource = {
  fetchClientMachineById
}
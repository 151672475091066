import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  createProject,
  updateProject,
  fetchProjects,
  deleteProject,
  fetchProject
} from '../sources/project'
import Form from './Form'
import { isRequired, isInt } from '../utils/validator'
import UiStore from './UiStore'
import { Project } from './types/project.types'
import { Client } from './types/client.types'
import { Lease } from './types/lease.types'

export class ProjectStore {
  @observable id = ''
  @observable saving = false
  public form: Form<{
    name: '',
    address: '',
    neighborhood: '',
    supervisor: '',
    city: '',
    zip: '',
    state: '',
    clientId: ''
  }>;
  private uiStore: UiStore;
  constructor({ uiStore }: {
    uiStore: UiStore;
  }) {
    this.uiStore = uiStore
    this.form = new Form({
      name: '',
      address: '',
      neighborhood: '',
      supervisor: '',
      city: '',
      zip: '',
      state: '',
      clientId: ''
    }, {
      name: isRequired(),
      address: isRequired(),
      neighborhood: isRequired(),
      supervisor: isRequired(),
      city: isRequired(),
      zip: [
        isRequired(),
        isInt()
      ],
      state: isRequired(),
      clientId: isRequired()
    });
    makeObservable(this);
  }

  create() {
    const formData = toJS(this.form.fields)

    return createProject(formData)
      .catch(this.uiStore.handleError('Error al crear proyecto'))
  }

  update() {
    const formData = toJS(this.form.fields)

    return updateProject({
      projectId: this.id,
      ...formData
    })
      .catch(this.uiStore.handleError('Error al editar proyecto'))
  }

  save() {
    let promise = null
    this.saving = true
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return promise
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  fetchData(id = '') {
    return fetchProject(this.id || id)
      .then(({ data: projectData }) => {
        return this.loadData({
          id: projectData.id,
          name: projectData.name,
          address: projectData.address,
          neighborhood: projectData.neighborhood,
          supervisor: projectData.supervisor,
          city: projectData.city,
          zip: projectData.zip,
          state: projectData.state,
          clientId: projectData.client.id,
        })
      })
      .catch(this.uiStore.handleError('Error al obtener proyecto'))
  }

  @action.bound
  setId(id: string) {
    this.id = id
  }

  @action.bound
  loadData({
    id,
    name,
    address,
    neighborhood,
    supervisor,
    clientId,
    city,
    zip,
    state
  }) {
    this.id = id
    this.form.initialize({
      name,
      address,
      neighborhood,
      supervisor,
      clientId,
      city,
      zip,
      state
    })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export class ProjectsStore {
  @observable projects: Project[] = []
  @observable searchValue = ''
  private uiStore: UiStore;

  constructor({ uiStore }: { uiStore: UiStore }) {
    this.uiStore = uiStore;
    makeObservable(this);
  }

  @action.bound
  setSearchValue(value: string) {
    this.searchValue = value
  }

  @action.bound
  getProjects(params: { limit?: number; search?: string, clientId?: string } | undefined) {
    return fetchProjects({ limit: params?.limit, search: params?.search, clientId: params?.clientId, })
      .then(({ data: projects }) => {

        this.projects = projects
      })
      .catch(this.uiStore.handleError('Error al obtener proyectos'))
  }

  @action.bound
  deleteProjectById(id = '') {
    const projectIndex = this.projects.findIndex(project => project.id === id)

    if (projectIndex < 0) return
    return deleteProject(id)
      .then(() => {
        this.projects = [
          ...this.projects.slice(0, projectIndex),
          ...this.projects.slice(projectIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar proyecto'))
  }

  @computed get allProjects(): ProjectStoreProjectType[] {
    return this.projects.map(project => ({
      id: project.id,
      name: project.name,
      address: project.address,
      neighborhood: project.neighborhood,
      supervisor: project.supervisor,
      city: project.city,
      zip: project.zip,
      state: project.state,
      clientId: project.client.id,
      clientName: project.client.name,
      client: project.client,
      leases: project.leases,
    }))
  }

  @computed get filteredProjects() {
    if (this.searchValue.length === 0) return this.allProjects

    return this.allProjects;
  }
}

export type ProjectStoreProjectType = {
  id: string;
  name: string;
  address: string;
  neighborhood: string;
  supervisor: string;
  city: string;
  zip: string;
  state: string;
  clientId: string;
  clientName: string;
  client: Client;
  leases: Lease[];
}

export default ProjectsStore

import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import IsUserAuthenticated from './IsUserAuthenticated'
import SessionStore from '../stores/SessionStore';

const SideBar = inject('sessionStore')(observer(({ sessionStore }: { sessionStore?: SessionStore }) => {

  const history = useHistory();
  const sideBarOptions = {
    adminMenuItems: [
      // {
      //   to: '/home',
      //   name: "Dashboard",
      //   icon: "fe fe-grid"
      // },
      {
        to: '/home/users',
        name: "Usuarios",
        icon: "fe fe-user"
      },
      {
        to: '/home/branches',
        name: "Sucursales",
        icon: "fe fe-home",
      }
    ],
    menuItems: [
      
      {
        to: '/home/leases',
        name: "Contratos",
        icon: "fe fe-layers"
      },
      {
        to: '/home/projects',
        name: "Proyectos",
        icon: "fe fe-briefcase"
      },
      {
        to: '/home/clients',
        name: "Clientes",
        icon: "fe fe-user"
      },
      {
        to: '/home/equipments',
        name: "Maquinaria",
        icon: "fe fe-settings"
      },

      {
        to: '/home/equipments/scaffold',
        name: "Andamios",
        icon: "fe fe-codepen"
      },

      // {
      //   to: '/home/supplies',
      //   name: "Material",
      //   icon: "fe fe-archive"
      // },
      {
        to: "/home/maintenances",
        name: "Mantenimiento & Ventas",
        icon: "fe fe-sliders",
        menu: [
          {
            to: '/home/maintenances/quotes',
            name: "Cotizaciones",
            icon: "fe fe-codepen",
          },
          {
            to: '/home/maintenances/client-machines',
            name: "Maquinaria Clientes",
            icon: "fe fe-codepen",
          },
          {
            to: '/home/employees',
            name: "Empleados",
            icon: "fe fe-users"
          },
          {
            to: '/home/maintenances/inventory',
            name: "Inventario",
            icon: "fe fe-codepen",
          },
          
         
          {
            to: '/home/maintenances/suppliers',
            name: "Provedores",
            icon: "fe fe-archive"
          },
        ]
      },
    ]
  }

  const logout = () => {

    sessionStore?.logout()
  }
  const onBranchesNavItemClick = () => {
    history.push('/home/branches')
  }

  const buildMenuItem = (array: any[], menuItem: any, index: number) => {
    let location = history.location.pathname

    let isActive = ""
    if (location.indexOf(menuItem.to) !== -1) {
      isActive = " active"
    }

    if (menuItem.menu) {
      return array.push(<li key={index} className="nav-item dropdown">
        <Link to="#maintenance" className={"nav-link" + isActive} data-toggle="collapse" role="button" aria-expanded="false" aria-controls="maintenance">
          <i className={menuItem.icon}></i>{menuItem.name}

        </Link>
        {menuItem.menu && <div className="collapse show" id="maintenance">
          <ul className="nav nav-sm flex-column">
            {menuItem.menu.map((subMenu: any, index: number) => {
              let isSubActive = ""
              if (location === menuItem.to) {
                isSubActive = " active"
              }
              return <li key={index} className="nav-item">
                <Link key={index} className={"nav-link " + isSubActive} to={subMenu.to}>
                  <i className={subMenu.icon}></i>{subMenu.name}
                </Link>
              </li>
            })}
          </ul>
        </div>}

      </li>)
    }
    array.push(
      <li key={index} className="nav-item">
        <Link className={"nav-link " + isActive} to={menuItem.to}>
          <i className={menuItem.icon}></i>{menuItem.name}
        </Link>
      </li>
    )
  }


  let menuItems: React.JSX.Element[] = []
  let adminMenuItems: React.JSX.Element[] = []

  sideBarOptions.menuItems.map((menuItem, index) => {
    buildMenuItem(menuItems, menuItem, index)
  })

  sideBarOptions.adminMenuItems.map((menuItem, index) => {
    buildMenuItem(adminMenuItems, menuItem, index)
  })

  return (
    <nav className='navbar navbar-vertical fixed-left navbar-expand-md navbar-light'>
      <div className='container-fluid'>
        <IsUserAuthenticated />
        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>

        <a className='navbar-brand' href='/'>
          <img src='/assets/img/logo.jpg' className='navbar-brand-img mx-auto' alt='...' />
        </a>

        <div className='navbar-user d-md-none'>

          <div className='dropdown'>
            <a href='#!' id='sidebarIcon' className='dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
              <div className='avatar avatar-sm avatar-online'>
                <img src='/assets/img/avatars/profiles/avatar-1.jpg' className='avatar-img rounded-circle' alt='...' />
              </div>
            </a>

            <div className='dropdown-menu dropdown-menu-right' aria-labelledby='sidebarIcon'>
              <a href='profile-posts.html' className='dropdown-item'>Profile</a>
              <a href='settings.html' className='dropdown-item'>Settings</a>
              <hr className='dropdown-divider' />
              <a href='sign-in.html' className='dropdown-item'>Logout</a>
            </div>

          </div>

        </div>

        <div className='collapse navbar-collapse' id='sidebarCollapse'>
         


          <ul className='navbar-nav'>
            {menuItems}
          </ul>
          {sessionStore?.isAdmin ? <div>
            <hr className="navbar-divider my-3" />
            <ul className='navbar-nav'>
              {adminMenuItems}
            </ul>

           
          </div> : null}
          <div className='navbar-user mt-auto d-none d-md-flex'>
            <div className='dropup'>
              <a href='#!' id='sidebarIconCopy' className='dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                <div className='avatar avatar-sm avatar-online'>
                  <img src='/assets/img/avatars/teams/team-logo-1.jpg' className='avatar-img rounded-circle' alt='...' />
                </div>
              </a>

              <div className='dropdown-menu' aria-labelledby='sidebarIconCopy'>
                <a href='profile-posts.html' className='dropdown-item'>Profile</a>
                <a href='settings.html' className='dropdown-item'>Settings</a>
                <hr className='dropdown-divider' />
                <Link
                  to='/login'
                  className='dropdown-item'
                  onClick={logout}
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}));

export default SideBar

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function HeaderImage({ to, buttonLabel, title, children, showButton }: { to: string; children?: any; showButton?: boolean; title?: string; buttonLabel?: string; }) {
  let navigationButton: JSX.Element | undefined = (
    <div className='col-12 col-md-auto mt-2 mt-md-0 mb-md-3'>
      <Link to={to} className='btn btn-primary d-block d-md-inline-block'>
        {buttonLabel}
      </Link>

    </div>
  )

  if (!showButton) {
    navigationButton = undefined
  }

  return (
    <div className='header'>
      <div className='container-fluid'>

        <div className='header-body mt--5 mt-md--6'>
          <div className='row align-items-end'>

            <div className='col mb-3 ml--3 ml-md--2'>
              <h1 className='header-title ' style={{ marginTop: "80px" }}>
                {title}
              </h1>

            </div>
            {navigationButton}
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

HeaderImage.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showButton: PropTypes.bool
}

HeaderImage.defaultProps = {
  to: '/',
  buttonLabel: 'Agregar',
  showButton: true
}

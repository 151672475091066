import { observable, action, computed, toJS, makeObservable } from 'mobx'

import Form from './Form'
import { isRequired, isInt } from '../utils/validator'
import SessionStore from './SessionStore'
import UiStore from './UiStore'

export default class InventoryStore {
  @observable inventory: any[] = []
  @observable saving = false
  private sessionStore: SessionStore;
  private uiStore: UiStore;
  public form: Form<{
    supplierId: string,
    cost: number,
    quantity: number,
    description: string
    name: string
    unit: string
  }>
  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore, uiStore: UiStore }) {
    this.sessionStore = sessionStore

    this.uiStore = uiStore
    this.form = new Form({
      supplierId: '',
      cost: 0,
      quantity: 1,
      description: '',
      name: '',
      profitPercentage: '',
      unit: '',
    }, {
      supplierId: isRequired(),
      cost: [
        isRequired(),
        isInt() //TODO: Validate float is not valid
      ],
      quantity: [
        isRequired(),
        isInt()
      ]
    })
    makeObservable(this);
  }


  @action.bound
  restore() {
    this.form.resetAllFields()
  }


  @computed get allInventory() {
    return this.inventory.map(supplyInventory => ({
      id: supplyInventory.id,
      supplyId: supplyInventory.supply_id,
      supplyName: supplyInventory.supply_name,
      cost: supplyInventory.cost,
      qty: supplyInventory.qty,
      supplierId: supplyInventory.supplier_id,
      supplierName: supplyInventory.supplier_name
    }))
  }

  @computed get total() {
    return this.inventory.reduce((total, supplyInventory) => {
      total += parseInt(supplyInventory.qty, 10)
      return total
    }, 0)
  }

  @computed get isEmpty() {
    return this.inventory.length === 0
  }
}

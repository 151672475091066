import React, { useState } from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { PreviewPDF } from "./PreviewPDF";

export const File = ({
  id,
  label,
  alt,
  src,
  onDrop,
  progress = 0,
  isPDF,
  title,
  onDelete,
  onCancel,
  onSave
}
  : {
    id?: string;
    label: string; alt: string;
    src: string;
    onDrop?: <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => void;
    onDelete?: () => void;
    onCancel?: () => void;
    onSave?: (files: File[]) => void;
    progress?: number;
    isPDF?: boolean;
    title?: string
  }) => {

  const [files, setFiles] = useState<File[]>();
  const [onFileDropped, setOnFileDropped] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>();

  let progressContainer = src || preview ? (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <img style={{ maxHeight: 500, maxWidth: 500, alignSelf: 'center' }} src={preview || src} alt={alt} />


    </div>
  ) : undefined;


  const handlePaste = (e: any) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      // const file = {
      //   getRawFile: () => fileObject,
      //   name: fileObject.name,
      //   size: fileObject.size,
      //   uid: guid(),
      //   status: 2,
      //   progress: 0,
      // };
      setFiles(e.clipboardData.files)
      setOnFileDropped(true);
      setPreview(URL.createObjectURL(e.clipboardData.files[0]))
      !onSave && onDrop && onDrop(e.clipboardData.files, [], {} as DropEvent)

    } else {
      alert(
        "No image data was found in your clipboard. Copy an image first or take a screenshot."
      );
    }
  };

  let FileOptions = (<div style={{
    display: 'flex',
    flex: 1,
    marginTop: 10,
    marginBottom: 10
  }}>
    {(onCancel && !onSave) || (onFileDropped) ? <button onClick={() => {
      setOnFileDropped(false);
      setFiles([])
      setPreview(undefined)
      onCancel?.()
    }} className="btn" style={{

      flex: 1
    }} type="button" >Cancelar</button> : null}
    {onSave && onFileDropped ? <button onClick={() => {

      files?.length && onSave(files)
      setFiles([])
      setPreview(undefined)
    }} disabled={files?.length === 0} className="btn btn-success" style={{
      flex: 1
    }} type="button" >Guardar</button> : null}
  </div>)

  if (id) {
    FileOptions = (<button onClick={(e) => {
      e.preventDefault();
      onDelete?.()
    }} className="btn btn-danger" style={{
      flex: 1
    }} type="button" >Borrar</button>)
  }

  return (
    <div style={{
      marginTop: 40
    }} onPaste={handlePaste}>
      <h2>{alt}</h2>
      <Dropzone
        accept={{ 'application/pdf': [".pdf"], 'image/*': [".jpeg", ".png"] }}
        multiple={false}
        onDrop={(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
          setOnFileDropped(true);
          setFiles(acceptedFiles)
          const filePreview = URL.createObjectURL(acceptedFiles[0])
          setPreview(filePreview)
          !onSave && onDrop?.(acceptedFiles, fileRejections, event)
        }}

      >
        {({ getRootProps, getInputProps }) => (<div >
          <div className="dz-default dz-message" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{label}</p>
            {!isPDF && <div>{progressContainer}</div>}
          </div>


        </div>)}

      </Dropzone>
      <div style={{
        width: "100%",
        display: 'flex'
      }}>
        {FileOptions}
      </div>

      {isPDF && (
        <div>
          <div className="mt-2">
            <PreviewPDF title={title} src={src} />
          </div>
        </div>
      )}
    </div>
  );
}

import superagent from 'superagent'
import axios from 'axios';

const agent = superagent.agent()

const ancoAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export default agent

export function setAuthorizationHeader(token = '') {
  agent.set('user-token', token)
  //@ts-ignore
  ancoAPI.defaults.headers = {
    'common': {
      'user-token': token,
    }
  }
  return agent
}

export function restoreClient() {
  setAuthorizationHeader('')
  ancoAPI.defaults.headers.common = {};
}

export {
  ancoAPI,
}

import React from 'react'

export function TableHead({ children }: { children: React.ReactNode }) {
  return (
    <thead>
      <tr>
        {children}
      </tr>
    </thead>
  )
}

export function TableBody({ children }: { children: React.ReactNode }) {
  return (
    <tbody className='list'>
      {children}
    </tbody>
  )
}

export function Table({ children }: { children: React.ReactNode }) {
  return (
    <div className='table-responsive'>
      <table style={{ minHeight: "200px" }} className='table table-sm table-nowrap card-table'>
        {children}
      </table>
    </div>
  )
}

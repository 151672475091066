import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  createBranch,
  fetchUsersByBranchId,
  fetchEmployeesByBranchId,
  assignUserToBranch,
  unassignUserFromBranch,
  updateBranchSerial,
  assignEmployeeToBranch,
  unassignEmployeeFromBranch
} from '../sources/branch'
import Form from './Form'
import { isRequired, isInt } from '../utils/validator'

import BranchesStore from './BranchesStore'
import { isString } from 'lodash'
import { UsersStore } from './Users'
import { EmployeesStore } from './EmployeesStore'
import UiStore from './UiStore'
import SessionStore from './SessionStore'
import { Branch } from './types/branch.types'

export default class BranchStore {
  @observable name = ''
  @observable serialprefix = ''
  @observable users = []
  @observable employees = []
  @observable saving = false

  id: string // The current BranchId
  form: Form<{
    name: '',
    address: '',
    neighborhood: '',
    city: '',
    zip: '',
    state: '',
    phone: ''
  }>
  searchForm: Form<{
    search: ''
  }>
  branchesStore: BranchesStore;
  private sessionStore: SessionStore;
  private usersStore: UsersStore;
  private employeesStore: EmployeesStore;
  private uiStore: UiStore;


  constructor({ branchesStore, sessionStore, usersStore, employeesStore, uiStore }: { branchesStore: BranchesStore, sessionStore: SessionStore, usersStore: UsersStore, employeesStore: EmployeesStore, uiStore: UiStore }) {
    this.form = new Form({
      name: '',
      address: '',
      neighborhood: '',
      city: '',
      zip: '',
      state: '',
      phone: ''
    }, {
      name: isRequired(),
      address: isRequired(),
      neighborhood: isRequired(),
      city: isRequired(),
      zip: [
        isRequired(),
        isInt()
      ],
      state: isRequired(),
      phone: isRequired()
    })
    this.searchForm = new Form({
      search: ''
    }, {
      search: isString(),
    })
    this.sessionStore = sessionStore
    this.usersStore = usersStore
    this.employeesStore = employeesStore
    this.uiStore = uiStore
    this.branchesStore = branchesStore
    this.id = ''
    makeObservable(this)
  }

  create() {
    const formData = toJS(this.form.fields)
    const { id } = this.sessionStore
    this.saving = true

    return createBranch({ userId: id, ...formData })
      .then(() => {
        this.form.resetAllFields()
        this.saving = false
      })
      .catch((error) => {
        this.saving = false
        this.uiStore.setError('Error al crear sucursal')
        throw error
      })
  }

  @action.bound
  restoreForm() {
    this.form.resetAllFields()
  }

  @action.bound
  loadData(data: Branch) {
    console.log("loadData", data)
    let { id, name, serialprefix } = data
    this.id = id
    this.name = name
    this.serialprefix = serialprefix
  }

  @action.bound
  setContractSerial(serialprefix: string) {
    this.serialprefix = serialprefix
  }

  @action.bound
  fetchUsersByBranch() {
    const { id } = this.sessionStore

    return fetchUsersByBranchId({ userId: id, branchId: this.id })
      .then(({ data }) => {
        const users = data
        this.users = users
      })
      .catch(this.uiStore.handleError('Error al obtener usuarios'))
  }

  @action.bound
  fetchEmployeesByBranch() {
    const { id } = this.sessionStore

    return fetchEmployeesByBranchId({ userId: id, branchId: this.id })
      .then(({ data }) => {
        const employees = data
        this.employees = employees
      })
      .catch(this.uiStore.handleError('Error al obtener empleados'))
  }

  @action.bound
  saveSerial() {
    const { id } = this.sessionStore
    return updateBranchSerial({
      userId: id,
      serialprefix: this.serialprefix,
      branchId: this.id
    }).then(({ data }) => {
      console.log("updatedSerial", data)
    })
  }

  @action.bound
  fetchUsersAndBranchesById(branchId: string) {
    if (branchId) {
      this.id = branchId;
      const { id } = this.sessionStore
      this.branchesStore.getBranches()
        .then(branches => {
          console.log("branches", branches)
          branches.map((branch) => {
            if (branch.id === branchId) {
              this.loadData(branch)
            }
          })
        }).then(() => {
          this.loadInitalAsyncData()
        })
    } else {
      this.loadInitalAsyncData()
    }

  }

  loadInitalAsyncData() {
    this.usersStore.getUsers()
    this.employeesStore.getEmployees()
    this.fetchUsersByBranch()
    this.fetchEmployeesByBranch()
  }

  @action.bound
  assignUserToBranch(userIdToAssign = '') {
    const { id } = this.sessionStore

    return assignUserToBranch({
      userId: id,
      branchId: this.id,
      userIdToAssign
    })
      .then(() => this.fetchUsersByBranch())
      .catch(this.uiStore.handleError('Error al asignar usuario a sucursal'))
  }

  @action.bound
  unassignUserFromBranch(userBranchId = '') {
    const { id } = this.sessionStore

    return unassignUserFromBranch({
      userId: id,
      branchId: this.id,
      userBranchId
    })
      .then(() => this.fetchUsersByBranch())
      .catch(this.uiStore.handleError('Error al borrar usuario de sucursal'))
  }

  @action.bound
  assignEmployeeToBranch(employeeIdToAssign = '') {
    const { id } = this.sessionStore

    return assignEmployeeToBranch({
      userId: id,
      branchId: this.id,
      employeeIdToAssign
    })
      .then(() => this.fetchEmployeesByBranch())
      .catch(this.uiStore.handleError('Error al asignar empleado a sucursal'))
  }

  @action.bound
  unassignEmployeeFromBranch(employeeBranchId = '') {
    const { id } = this.sessionStore

    return unassignEmployeeFromBranch({
      userId: id,
      branchId: this.id,
      employeeBranchId
    })
      .then(() => this.fetchEmployeesByBranch())
      .catch(this.uiStore.handleError('Error al borrar empleado de sucursal'))
  }

  @computed get usersInBranch() {

    return this.users.map(({
      id,
      user: {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        admin: isAdmin,
        email: email,
        created_date: createdOn
      } }) => ({
        id,
        userId,
        firstName,
        lastName,
        isAdmin,
        email,
        createdOn,
        name: `${firstName} ${lastName}`
      }))
  }

  @computed get unassignedUsers() {
    const userIdsInBranch = new Set(this.usersInBranch.map(user => user.userId))

    return this.usersStore.allUsers.filter(user => !userIdsInBranch.has(user.id))
  }

  @computed get unassignedUsersFiltered() {
    const search = this.searchForm.getField('search')

    if (search && search.length === 0) return this.unassignedUsers.slice(0, 3)
    return this.unassignedUsers
      .filter(user => user.name.toLowerCase().includes(search.toLowerCase()))
      .slice(0, 3)
  }

  @computed get employeesInBranch() {
    return this.employees.map(({
      id,
      employee: {
        id: employeeId,
        first_name: firstName,
        last_name: lastName,
        created_date: createdOn
      } }) => ({
        id,
        employeeId,
        firstName,
        lastName,
        createdOn,
        name: `${firstName} ${lastName}`
      }))
  }

  @computed get unassignedEmployees() {
    const employeeIdsInBranch = new Set(this.employeesInBranch.map(user => user.employeeId))

    return this.employeesStore.allEmployees.filter(employee => !employeeIdsInBranch.has(employee.id))
  }

  @computed get unassignedEmployeesFiltered() {
    const search = this.searchForm.getField('search')

    if (search && search.length === 0) return this.unassignedEmployees.slice(0, 3)
    return this.unassignedEmployees
      .filter(employee => employee.name.toLowerCase().includes(search.toLowerCase()))
      .slice(0, 3)
  }
}

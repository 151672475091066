import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchClients,
  createClient,
  updateClient,
  deleteClientById,
  fetchClient
} from '../sources/client'
import Form from './Form'
import { isRequired } from '../utils/validator'
import Session from './SessionStore'
import UiStore from './UiStore'
import { Client } from './types/client.types'

export class ClientStore {
  @observable public id = ''
  @observable saving = false
  private sessionStore: Session;
  private uiStore: UiStore;
  public form: Form<{
    name: '',
    email: '',
    phone: '',
    address: '',
    neighborhood: '',
    city: '',
    state: ''
  }>;
  constructor({ uiStore, sessionStore }: { uiStore: UiStore; sessionStore: Session; }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore
    this.form = new Form({
      name: '',
      email: '',
      phone: '',
      address: '',
      neighborhood: '',
      city: '',
      state: ''
    }, {
      name: isRequired(),
      email: isRequired(),
      phone: isRequired(),
      address: isRequired(),
      neighborhood: isRequired(),
      city: isRequired(),
      state: isRequired()
    })
    makeObservable(this);
  }

  update() {
    const { id: userId } = this.sessionStore
    const { id: clientId } = this
    const formData = toJS(this.form.fields)

    return updateClient({
      userId,
      clientId,
      ...formData
    })
      .catch(this.uiStore.handleError('Error al editar cliente'))
  }

  create() {
    const { id: userId } = this.sessionStore
    const formData = toJS(this.form.fields)

    return createClient({
      userId,
      ...formData
    })
      .catch(this.uiStore.handleError('Error al crear cliente'))
  }

  @action.bound
  save() {
    let promise = null
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    this.saving = true
    return promise
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @action.bound
  setId(id: string) {
    this.id = id
  }

  @action.bound
  loadData({ id, name, phone, email, address, neighborhood, city, state }: Client) {
    this.id = id
    this.form.initialize({
      name,
      phone,
      email,
      address,
      neighborhood,
      city,
      state
    })
  }

  fetchData() {
    const { id: userId } = this.sessionStore
    const { id: clientId } = this

    return fetchClient({ userId, clientId })
      .then(({ data: clientData }) => {
        this.loadData(clientData)
      })
      .catch(this.uiStore.handleError('Error al obtener cliente'))
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export default class ClientsStore {
  @observable clients: Client[] = []
  @observable searchValue = ''

  private sessionStore: Session;
  private uiStore: UiStore;

  constructor({ sessionStore, uiStore }: { sessionStore: Session; uiStore: UiStore; }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore
    makeObservable(this);
  }

  @action.bound
  getClients(params?: {
    search?: string;
    limit?: number;
    offset?: number;
  }) {
    return fetchClients({
      search: params?.search,
      limit: params?.limit,
      offset: params?.offset
    })
      .then(({ data }: { data: Client[] }) => {
        const clients = data
        this.clients = clients
      })
      .catch(this.uiStore.handleError('Error al obtener clientes'))
  }

  @action.bound
  removeClientById(id = '') {
    const { id: userId } = this.sessionStore
    const clientIndex = this.clients.findIndex(client => client.id === id)
    if (clientIndex < 0) return
    return deleteClientById({ userId, clientId: id })
      .then(() => {
        this.clients = [
          ...this.clients.slice(0, clientIndex),
          ...this.clients.slice(clientIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar cliente'))
  }

  @action.bound
  setSearchValue(value: string) {
    this.searchValue = value
  }

  sortByClientName(prevClient, nextClient) {
    const prevName = prevClient.name.toLowerCase()
    const nextName = nextClient.name.toLowerCase()

    return prevName.localeCompare(nextName)
  }

  @computed get allClients() {
    return this.clients.slice().sort(this.sortByClientName)

  }
}

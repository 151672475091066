import request, { ancoAPI } from '../utils/http-client'

export function fetchDocuments(clientId: string) {
  return ancoAPI
    .get(`/api/client/${clientId}/client-document/`)
}

export function uploadFile({ file, type, clientId, expireDate, progress }) {

  const formData = new FormData();

  formData.append('file', file)
  formData.append('type', type);
  if (expireDate)
    formData.append('expire_date', expireDate);
  return ancoAPI
    .post(`/api/client/${clientId}/client-document`, formData);
}

import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchSuppliers,
  createSupplier,
  updateSupplier,
  removeSupplier
} from '../sources/supplier'
import { isRequired } from '../utils/validator'
import Form from './Form'
import SessionStore from './SessionStore'
import UiStore from './UiStore'
import { Supplier } from './types/supplier.types'

export class SupplierStore {
  @observable id = ''
  @observable saving = false
  private sessionStore: SessionStore;
  private uiStore: UiStore;
  form: Form<{
    name: string,
    rfc: string,
    phone: string,
    email: string,
    street: string,
    number: string,
    neighborhood: string,
    city: string,
    state: string,
    zip: string,
  }>

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore, uiStore: UiStore }) {
    this.form = new Form({
      name: '',
      rfc: '',
      phone: '',
      email: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      zip: '',
    }, {
      name: isRequired()
    })
    this.sessionStore = sessionStore
    this.uiStore = uiStore;
    makeObservable(this);
  }

  create() {
    const { name, rfc, street, number, phone, email, neighborhood, city, state, zip } = toJS(this.form.fields)
    const { id } = this.sessionStore

    return createSupplier({
      name,
      rfc,
      street,
      number,
      neighborhood,
      city,
      state,
      phone,
      email,
      zip,
      userId: id
    })
      .catch(this.uiStore.handleError('Error al crear provedor'))
  }

  update() {
    const { name, rfc, street, phone, email, number, neighborhood, city, state, zip } = toJS(this.form.fields)
    const { id: userId } = this.sessionStore

    return updateSupplier({
      name,
      rfc,
      street,
      number,
      phone,
      email,
      neighborhood,
      city,
      state,
      zip,
      userId,
      supplierId: this.id
    })
      .catch(this.uiStore.handleError('Error al editar provedor'))
  }

  @action.bound
  save() {
    this.saving = true
    let promise = null
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return promise
      .then(() => {
        this.restore()
        this.saving = false
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  loadData({ id, name, rfc, street, phone, email, number, neighborhood, city, state, zip }: { id: string, name: string, rfc: string, street: string, phone: string, email: string, number: string, neighborhood: string, city: string, state: string, zip: string }) {
    this.id = id
    this.form.initialize({
      name,
      rfc,
      street,
      phone,
      email,
      number,
      neighborhood,
      city,
      state,
      zip,
    })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export default class SuppliersStore {
  @observable suppliers: Supplier[] = []
  private sessionStore: SessionStore;
  private uiStore: UiStore;

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore, uiStore: UiStore }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore
    makeObservable(this);
  }

  getSupplierByName(name = '') {
    return this.suppliers.find(supplier => supplier.name.toLowerCase() === name.toLowerCase())
  }

  @action.bound
  deleteSupplierById(id = '') {
    const { id: userId } = this.sessionStore
    const supplierIndex = this.suppliers.findIndex(supplier => supplier.id === id)

    if (supplierIndex < 0) return
    return removeSupplier({ userId, supplierId: id })
      .then(() => {
        this.suppliers = [
          ...this.suppliers.slice(0, supplierIndex),
          ...this.suppliers.slice(supplierIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar provedor'))
  }

  @action.bound
  getSuppliers() {
    const { id } = this.sessionStore

    return fetchSuppliers(id)
      .then(({ data }) => {
        const suppliers = data
        this.suppliers = suppliers
      })
      .catch(this.uiStore.handleError('Error al obtener provedores'))
  }
}

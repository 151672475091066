
import { inject, observer } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import { LeaseStore } from '../stores/Leases'
import { LeaseEquipmentStore } from '../stores/LeaseEquipmentsStore'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../hooks/useQuery'

const ReturnForm = inject('leaseStore', 'leaseEquipmentStore')(observer(({
  leaseStore,
  leaseEquipmentStore,
}: {
  leaseStore: LeaseStore;
  leaseEquipmentStore: LeaseEquipmentStore;
}) => {
  const query = useQuery();
  const history = useHistory();
  const isScaffold = query.get('scaffold') === 'true';
  const onSubmit = (event: any) => {
    event.preventDefault()

    leaseEquipmentStore
      .returnEquipment(isScaffold)
      .then(() => {
        history.push(`/home/lease/${leaseStore.id}/details`)
      })
  }
  const onCancel = () => {

    leaseEquipmentStore.returnForm.resetAllFields()
  }



  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <Form
          preTitle={`Entrega de ${isScaffold ? 'andamio' : 'maquinaria'}`}
          title={`Entrega ${isScaffold ? ' andamio' : 'maquinaria'}`}
          primaryButtonLabel='Entregar'
          disableSubmit={(!leaseEquipmentStore.returnForm.isValid && !isScaffold) || leaseEquipmentStore.saving}
          isSubmitLoading={leaseEquipmentStore.saving}
          onSubmit={onSubmit}
          onCancel={onCancel}
        >
          {!isScaffold ? <Input
            label='Horometro entrega'
            {...bindInput(leaseEquipmentStore.returnForm, 'finalHours')}
          /> : <></>}
        </Form>
      </div>
    </div>
  )

}))

export default ReturnForm

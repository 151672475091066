import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Users from './Users'
import NewUser from './NewUser'
import Employees from './Employees'
import Employee from './Employee'
import EditEmployee from './EditEmployee'
import Branches from './Branches'
import NewBranch from './NewBranch'
import Branch from './Branch'
import Equipment from './Equipment'
import Equipments from './Equipments'

import Supply from './Supply'
import SupplyInfo from './SupplyInfo'
import SupplyInfoForm from './SupplyInfoForm'
import { Maintenances } from './Maintenances'
import Clients from './Clients'
import ProjectForm from './ProjectForm'
import Projects from './Projects'
import Leases from './Leases'
import LeaseForm from './LeaseForm'
import Lease from './Lease'
import SideBar from '../components/SideBar'

function Home() {
  const match = useRouteMatch()
  return (
    <div>
      <SideBar />
      <Route path={`${match.url}/users`} component={Users} />
      <Route path={`${match.url}/new-user`} component={NewUser} />
      <Route path={`${match.url}/employees`} component={Employees} />
      <Route path={`${match.url}/employee`} component={Employee} />
      <Route path={`${match.url}/edit-employee`} component={EditEmployee} />
      <Route path={`${match.url}/branches`} component={Branches} />
      <Route path={`${match.url}/branch/:branchId`} component={Branch} />
      <Route path={`${match.url}/new-branch`} component={NewBranch} />

      <Route path={`${match.url}/supply-form`} component={Supply} />
      <Route path={`${match.url}/supply/:supplyId`} component={SupplyInfo} />
      <Route path={`${match.url}/supply/:supplyId/inventory-form`} component={SupplyInfoForm} />
      <Route path={`${match.url}/new-project`} component={NewUser} />

      <Route exact path={`${match.url}/equipments`} component={Equipments} />
      <Route path={`${match.url}/equipments/:scaffold`} component={Equipments} />

      <Route exact path={`${match.url}/equipment`} component={Equipment} />
      <Route path={`${match.url}/equipment/:scaffold`} component={Equipment} />

      <Route path={`${match.url}/maintenances`} component={Maintenances} />

      <Route path={`${match.url}/clients`} component={Clients} />
      <Route path={`${match.url}/projects`} component={Projects} />
      <Route path={`${match.url}/project-form`} component={ProjectForm} />
      <Route path={`${match.url}/leases`} component={Leases} />
      <Route path={`${match.url}/lease-form`} component={LeaseForm} />
      <Route path={`${match.url}/lease`} component={Lease} />
    </div>
  )
}

export default Home

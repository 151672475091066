import Session from './SessionStore'
import { UserStore, UsersStore } from './Users'
import BranchesStore from './BranchesStore'
import Branch from './Branch'
import { EmployeeStore, EmployeesStore } from './EmployeesStore'
import { EquipmentStore, EquipmentsStore } from './EquipmentsStore'
import SuppliersStore, { SupplierStore } from './SuppliersStore'
import { SupplyStore, SuppliesStore } from './SuppliesStore'
import Inventory from './InventoryStore'
import { MaintenanceStore, MaintenancesStore } from './Maintenances'
import { MaintenanceDetailsStore } from './MaintenanceDetailsStore'
import ClientsStore, { ClientStore } from './ClientsStore'
import { ProjectStore, ProjectsStore } from './ProjectsStore'
import LeasesStore, { LeaseStore } from './Leases'
import LeaseEquipmentsStore, { LeaseEquipmentStore } from './LeaseEquipmentsStore'
import { ExtensionStore, ExtensionsStore } from './ExtensionsStore'
import Documents from './Documents'
import UiStore from './UiStore'
import { InventoryItemStore } from './InventoryItemStore'

export default function createAppStore() {
  const uiStore = new UiStore()
  const sessionStore = new Session({ uiStore })
  const usersStore = new UsersStore({ uiStore })
  const employeesStore = new EmployeesStore({ uiStore, sessionStore })
  const supplyStore = new SupplyStore({ uiStore, sessionStore })
  const maintenanceStore = new MaintenanceStore({ uiStore, sessionStore })
  const leaseStore = new LeaseStore({ uiStore })
  const leaseEquipmentStore = new LeaseEquipmentStore({ uiStore, leaseStore })
  const clientStore = new ClientStore({ uiStore, sessionStore })
  const branchesStore = new BranchesStore({ uiStore, sessionStore })

  return {
    sessionStore,
    usersStore,
    userStore: new UserStore({ uiStore }),
    branchesStore: branchesStore,
    branchStore: new Branch({
      branchesStore,
      uiStore,
      sessionStore,
      usersStore,
      employeesStore
    }),
    employeesStore,
    employeeStore: new EmployeeStore({ uiStore, sessionStore }),
    equipmentsStore: new EquipmentsStore({ uiStore }),
    equipmentStore: new EquipmentStore({ uiStore }),
    suppliersStore: new SuppliersStore({ uiStore, sessionStore }),
    supplierStore: new SupplierStore({ uiStore, sessionStore }),
    suppliesStore: new SuppliesStore({ uiStore, sessionStore }),
    supplyStore,
    inventoryStore: new Inventory({ sessionStore, uiStore }),
    inventoryItemStore: new InventoryItemStore(),
    maintenancesStore: new MaintenancesStore({ uiStore, sessionStore }),
    maintenanceStore,
    maintenanceDetailsStore: new MaintenanceDetailsStore({ sessionStore, maintenanceStore, uiStore }),
    clientStore,
    clientsStore: new ClientsStore({ uiStore, sessionStore }),
    projectStore: new ProjectStore({ uiStore }),
    projectsStore: new ProjectsStore({ uiStore }),
    leaseStore,
    leasesStore: new LeasesStore({ uiStore }),
    leaseEquipmentStore,
    leaseEquipmentsStore: new LeaseEquipmentsStore({ uiStore, leaseStore }),
    extensionStore: new ExtensionStore({ leaseEquipmentStore, leaseStore, uiStore }),
    extensionsStore: new ExtensionsStore({ leaseEquipmentStore, leaseStore, uiStore }),
    documentsStore: new Documents({ uiStore, clientStore }),
    uiStore
  }
}

import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchSupplies,
  createSupply,
  updateSupply,
  removeSupply
} from '../sources/supply'
import { isRequired } from '../utils/validator'
import Form from './Form'
import SessionStore from './SessionStore'
import UiStore from './UiStore'

export class SupplyStore {
  @observable id = ''
  @observable saving = false
  private sessionStore: SessionStore;
  private uiStore: UiStore;
  public form: Form<{
    name: string;
    unit: string;
    description: string;
  }>;

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore; uiStore: UiStore; }) {
    this.form = new Form({
      name: '',
      unit: '',
      description: ''
    }, {
      name: isRequired(),
      unit: isRequired(),
      description: isRequired()
    })
    this.sessionStore = sessionStore
    this.uiStore = uiStore;
    makeObservable(this);
  }

  create() {
    const { name, description, unit } = toJS(this.form.fields)
    const { id } = this.sessionStore

    return createSupply({
      name,
      unit,
      description,
      userId: id
    })
      .catch(this.uiStore.handleError('Error al crear material'))
  }

  update() {
    const { name, unit, description } = toJS(this.form.fields)
    const { id: userId } = this.sessionStore

    return updateSupply({
      name,
      unit,
      description,
      userId,
      supplyId: this.id
    })
      .catch(this.uiStore.handleError('Error al editar material'))
  }

  @action.bound
  save() {
    this.saving = true
    let promise
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return promise
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  loadData({ id, name, unit, description }: { id: string; name: string; unit: string; description: string; }) {
    this.id = id
    this.form.initialize({ name, unit, description })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export class SuppliesStore {
  @observable supplies: any[] = []
  private sessionStore: SessionStore;
  private uiStore: UiStore;

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore; uiStore: UiStore; }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore
  }

  @action.bound
  deleteSupplyById(id = '') {
    const { id: userId } = this.sessionStore
    const supplyIndex = this.supplies.findIndex(supply => supply.id === id)

    if (supplyIndex < 0) return
    return removeSupply({ userId, supplyId: id })
      .then(() => {
        this.supplies = [
          ...this.supplies.slice(0, supplyIndex),
          ...this.supplies.slice(supplyIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar material'))
  }

  getSupplyById(id: string) {
    return this.supplies.find(supply => supply.id === id)
  }

  @action.bound
  getSupplies() {
    const { id } = this.sessionStore

    return fetchSupplies(id)
      .then(({ data }) => {
        const supplies = data
        this.supplies = supplies
      })
      .catch(this.uiStore.handleError('Error al obtener materiales'))
  }
}

import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import { ExtensionStore } from '../stores/ExtensionsStore'
import { EquipmentsStore } from '../stores/EquipmentsStore'
import { LeaseStore } from '../stores/Leases'
import { LeaseEquipmentStore } from '../stores/LeaseEquipmentsStore'
import { useHistory } from 'react-router-dom'

const ExtensionForm = inject('extensionStore', 'equipmentsStore', 'leaseStore', 'leaseEquipmentStore')(observer(({
  extensionStore, equipmentsStore, leaseStore, leaseEquipmentStore
}: {
  extensionStore: ExtensionStore;
  equipmentsStore: EquipmentsStore;
  leaseStore: LeaseStore;
  leaseEquipmentStore: LeaseEquipmentStore;
}) => {

  const history = useHistory();
  useEffect(() => {

    fetchEquipments();
  }, []);

  const fetchEquipments = () => {

    equipmentsStore.getEquipments()
  }
  const onSubmit = (event: any) => {
    event.preventDefault()
    extensionStore
      .save()
      .then(() => {
        history.push(`/home/lease/${leaseStore.id}/equipment/${leaseEquipmentStore.id}/details`)
      })
  }
  const onCancel = () => {
    extensionStore.restore()
  }



  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <Form
          preTitle='Contrato'
          title='Agregar maquinaria'
          primaryButtonLabel='Agregar'
          disableSubmit={!extensionStore.form.isValid || extensionStore.saving}
          isSubmitLoading={extensionStore.saving}
          onSubmit={onSubmit}
          onCancel={onCancel}
        >
          <>
            <Input
              label='Costo'
              {...bindInput(extensionStore.form, 'cost')}
            />
            <Input
              label='Dias'
              {...bindInput(extensionStore.form, 'days')}
            />
          </>
        </Form>
      </div>
    </div>
  )
}))

export default ExtensionForm

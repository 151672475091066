import { toString } from "lodash"


const SearchUtil = {
  findOne: (searchWord, words) => {
    let found = false
    if (!searchWord) {
      return true
    }

    words.map((word) => {
      if (word.contains(searchWord)) {
        found = true
        return true
      }
    })
    return found
  }
};

export default SearchUtil;

export function stringMatchInObject({ obj = {}, match = '', filterableProperties = [] }) {
  return filterableProperties.reduce((foundMatch, filterableProperty) => {
    if (foundMatch) return true
    let propertyValue: string | undefined = obj[filterableProperty]
    // console.log('propertyValue', propertyValue)

    if (!propertyValue) {
      return false;
    }

    if (typeof propertyValue !== 'string') {
      propertyValue = toString(propertyValue)
    }

    propertyValue = propertyValue?.toLowerCase()

    return propertyValue?.includes(match.toLowerCase())
  }, false)
}

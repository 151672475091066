import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { InventoryItem } from "../pages/Maintenances/components/Inventory/Inventory.types";
import inventoryItemSource from "../sources/inventoryItemSource";


export class InventoryItemStore {
  @observable id = ''
  @observable inventoryItems: InventoryItem[] = [];

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async fetchInventoryItems(searchText?: string, limit?: number) {
    const { data } = await inventoryItemSource.fetchInventoryItems({
      searchText,
      limit
    });
    this.inventoryItems = data;
  }

  @computed
  get items() {
    return this.inventoryItems.map((item) => {
      return {
        ...item,
      } as InventoryItem
    })
  };

}
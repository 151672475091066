import request, { ancoAPI } from '../utils/http-client'

export function fetchClients({
  search = '',
  limit = 50,
  offset = 0
}: {
  search?: string;
  limit?: number;
  offset?: number;
}) {
  return ancoAPI
    .get(`/api/client?search=${search}&limit=${limit}&offset=${offset}`)
}

export function createClient({ userId, ...data }) {
  return ancoAPI
    .post(`/api/client`, data)
}

export function deleteClientById({ userId, clientId }) {
  return ancoAPI
    .delete(`/api/client/${clientId}`)
}

export function updateClient({ userId, clientId, ...data }) {
  return ancoAPI
    .put(`/api/client/${clientId}`, data)
}

export function fetchClient({ userId, clientId }) {

  return ancoAPI
    .get(`/api/client/${clientId}`)
}

import { ancoAPI } from '../utils/http-client'


export function login({ email, password }: { email: string; password: string }) {
  return ancoAPI
    .post('/api/user/login', {
      email,
      password
    })
}

export function me() {
  return ancoAPI
    .get('/api/user/me')
}

export function fetchUsers() {
  return ancoAPI
    .get('/api/user')
}

export function createUser(user: any) {
  return ancoAPI
    .post('/api/user', {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      password: user.password,
      admin: user.admin
    });
}

export function deleteUserById(id = '') {
  return ancoAPI
    .delete(`/api/user/${id}`)
}

export function changeUserPassword({ userId, newPassword, oldPassword }: { userId: string; newPassword: string; oldPassword: string }) {
  return ancoAPI
    .put(`/api/user/${userId}/password`, {
      newPassword,
      oldPassword
    })
}

export function makeAdmin({ userId }: { userId: string }) {
  return ancoAPI
    .put(`/api/user/${userId}/admin`)
}

export function removeAdmin({ userId }: { userId: string }) {
  return ancoAPI
    .delete(`/api/user/${userId}/admin`)
}

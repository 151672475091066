import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../../../components/Modal';
import { ancoAPI } from '../../../../../utils/http-client';
import { Employee } from '../../../../../stores/types/employee.types';
import { Quote } from '../Quotes.types';
import SelectSearch, { SelectSearchOption } from '../../../../../components/SelectSearch';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { EmployeesStore } from '../../../../../stores/EmployeesStore';


export type QuoteLabor = {
  cost: number;
  hours: number;
  description: string;
  price_percentage: number;
  employee: Employee;
  quote: Quote;
}

export const AddQuoteLabor = inject('employeesStore')(observer(({ employeesStore, quote, onRefresh }: { employeesStore?: EmployeesStore; quote?: Quote; onRefresh: () => void; }) => {

  const [showAddLabor, setShowAddLabor] = useState<boolean>(false);
  const [employee, setEmployee] = useState<Employee & { name: string }>();
  const [cost, setCost] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (!showAddLabor) {
      setCost(0)
      setHours(0)
      setDescription('')
      setEmployee(undefined);
    }
  }, [showAddLabor]);

  const onSave = async () => {
    await ancoAPI.post('/api/quote-labor', {
      cost,
      description,
      hours,
      employeeId: employee?.id,
      quoteId: quote?.id,
    });

    setShowAddLabor(false);
    onRefresh();
  }

  const fetchEmployee = useRef(debounce((searchText = '') => {
    employeesStore?.getEmployees({
      searchText: searchText
    })
  }, 300)
  ).current;

  const clientsOptions = employeesStore?.allEmployees.map(employee => ({
    value: employee.id,
    label: employee.name,
    meta: employee
  })) ?? []

  const onSelected = (option: SelectSearchOption) => {
    const { name } = option.meta as Employee & { name: string };
    setEmployee(option.meta);
    return name;
  }

  const onSearchClient = (seachText: string) => {
    fetchEmployee(seachText)
  }

  const renderClientItem = (option: SelectSearchOption) => {


    const { id, name } = option.meta as Employee & { name: string }
    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name}
      </div>
    </div>


  }

  return <div>
    <button className='btn btn-success' onClick={() => {
      setShowAddLabor(true);
    }}>
      Agregar Mano de obra
    </button>
    <Modal show={showAddLabor}>
      <div style={{
        padding: 20,
      }}>
        <div style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        }}>

          <h2 className='modal-title' id='addLaborModalLabel'>Agregar mano de obra</h2>
        </div>

        <div className='modal-body'>
          <div >
            <SelectSearch label='Empleado'
              onSelected={onSelected}
              options={clientsOptions}
              onSearch={onSearchClient}
              value={employee?.name ?? ''}
              renderItem={renderClientItem} />

            <textarea className='form-control' placeholder='Describe las acciones a realizar' onChange={(e) => {
              setDescription(e.target.value);
            }} />
            <br />
            <input className='form-control' type="number" placeholder='Horas estimadas' onChange={(e) => {
              setHours(parseInt(e.target.value));
            }} />
            <br />
            <input className='form-control' type="number" placeholder='Costo' onChange={(e) => {
              setCost(parseInt(e.target.value));
            }} />
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-secondary' onClick={() => {
            setShowAddLabor(false);
          }} >Cerrar</button>
          <button type='button' className='btn btn-primary' onClick={onSave}>
            Agregar
          </button>
        </div>
      </div>
    </Modal>
  </div>

}));


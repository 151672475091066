function setField(field = '', value = '') {
  const { localStorage } = window
  localStorage.setItem(field, value)
}

function removeField(field = '') {
  const { localStorage } = window
  localStorage.removeItem(field)
}

function getField(field = '') {
  const { localStorage } = window
  return localStorage.getItem(field)
}

class ItemStorage {
  private field: string;

  constructor(field = '') {
    this.field = field
  }

  setValue(value = '') {
    setField(this.field, value)
    return this
  }

  remove() {
    removeField(this.field)
    return this
  }

  getValue() {
    return getField(this.field)
  }
}

export const authorizationStorage = new ItemStorage('token')

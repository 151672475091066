
import React, { EventHandler, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Route, useRouteMatch } from 'react-router-dom'
import HeaderImage from '../components/HeaderImage'
import { NavTab, NavItem } from '../components/NavTab'
import CardUser from '../components/CardUser'
import CardSearch from '../components/CardSearch'
import { bindInput } from '../utils/bindField'
import Input from '../components/Input'
import BranchStore from '../stores/Branch'
import BranchesStore from '../stores/BranchesStore'
import { set } from 'lodash'

const ListItem = ({ name, isAdmin = false, onAssignToBranch }: { name: string; isAdmin?: boolean; onAssignToBranch: () => void }) => {
  return (
    <li className='list-group-item px-0'>
      <div className='row align-items-center'>
        <div className='col-auto'>
          <a href='profile-posts.html' className='avatar'>
            <img src='/assets/img/avatars/profiles/avatar-5.jpg' alt='...' className='avatar-img rounded-circle' />
          </a>
        </div>
        <div className='col ml--2'>
          <h4 className='mb-1 name'>
            {name}
          </h4>

          {isAdmin ? <p className='small mb-0'>
            <span className='text-success'>?</span> Online
          </p> : null}
        </div>
        <div className='col-auto'>
          <button className='btn btn-sm btn-white' onClick={onAssignToBranch}>
            Asignar a sucursal
          </button>
        </div>
      </div>
    </li>
  )
}


const Users = inject('branchStore')(observer(({ branchStore }: { branchStore: BranchStore }) => {

  useEffect(() => {

    branchStore.searchForm.resetAllFields();
  }, [branchStore]);

  const unassignUserFromBranch = (userBranchId = '') => {


    branchStore.unassignUserFromBranch(userBranchId)
  }
  const assignUserToBranch = (userToAssign = '') => {


    branchStore.assignUserToBranch(userToAssign)
  }
  const renderUnassignedUser = ({ id, name, isAdmin }: { id: string; name: string; isAdmin: boolean; }) => {
    return <ListItem
      name={name}
      isAdmin={isAdmin}
      onAssignToBranch={() => assignUserToBranch(id)}
    />
  }
  const renderAssignedUser = ({ id: userBranchId, name, email, isAdmin }: { id: string; name: string; email: string; isAdmin: boolean; }) => {
    return (
      <CardUser
        name={name}
        isAdmin={isAdmin}
        email={email}
      >
        <div className='col-auto'>
          <button
            className='btn btn-sm btn-danger d-none d-md-inline-block'
            onClick={() => unassignUserFromBranch(userBranchId)}
          >
            Quitar de sucursal
          </button>
        </div>
      </CardUser>
    )
  }

  const users = branchStore.usersInBranch.map(renderAssignedUser)
  const unassignedUsers = branchStore.unassignedUsersFiltered.map(renderUnassignedUser)

  return (
    <div className='container-fluid'>
      <CardSearch
        title='Buscar usuarios'
        {...bindInput(branchStore.searchForm, 'search')}
      >
        <ul className='list-group list-group-flush list my--3'>
          {unassignedUsers}
        </ul>
      </CardSearch>
      <div className='row'>
        <div className='col-12'>
          {users}
        </div>
      </div>
    </div>
  )

}));



const Employees = inject('branchStore')(observer(({ branchStore }: { branchStore: BranchStore }) => {


  useEffect(() => {

    branchStore.searchForm.resetAllFields()
  }, []);

  const unassignEmployeeFromBranch = (employeeBranchId = '') => {
    branchStore.unassignEmployeeFromBranch(employeeBranchId)
  }
  const assignEmployeeToBranch = (employeeToAssign = '') => {
    branchStore.assignEmployeeToBranch(employeeToAssign)
  }
  const renderUnassignedEmployee = ({ id, name }: { id: string; name: string; }) => {
    return <ListItem
      name={name}
      onAssignToBranch={() => assignEmployeeToBranch(id)}
    />
  }
  const renderAssignedEmployee = ({ id: employeeBranchId, name }: { id: string; name: string; }) => {
    return (
      <CardUser name={name}>
        <div className='col-auto'>
          <button
            className='btn btn-sm btn-danger d-none d-md-inline-block'
            onClick={() => unassignEmployeeFromBranch(employeeBranchId)}
          >
            Quitar de sucursal
          </button>
        </div>
      </CardUser>
    )
  }
  const assignedEmployees = branchStore.employeesInBranch.map(renderAssignedEmployee)
  const unassignedEmployees = branchStore.unassignedEmployeesFiltered.map(renderUnassignedEmployee)

  return (
    <div className='container-fluid'>
      <CardSearch
        title='Buscar empleados'
        {...bindInput(branchStore.searchForm, 'search')}
      >
        <ul className='list-group list-group-flush list my--3'>
          {unassignedEmployees}
        </ul>
      </CardSearch>
      <div className='row'>
        <div className='col-12'>
          {assignedEmployees}
        </div>
      </div>
    </div>
  )

}));




const Branch = inject('branchStore', 'branchesStore')(observer(({ branchesStore, branchStore }: { branchesStore: BranchesStore; branchStore: BranchStore; }) => {
  const [activeTab, setActiveTab] = useState<string>('users');
  const match = useRouteMatch<{
    branchId: string;
  }>();
  useEffect(() => {

    fetchUsersAndBranches()
  }, []);

  const fetchBranches = () => {
    branchesStore.getBranches()
  }

  const fetchUsersAndBranches = () => {
    let { params: { branchId } } = match
    fetchBranches()
    branchStore.fetchUsersAndBranchesById(branchId)
  }

  const onContractSerial = ({ currentTarget }: { currentTarget: any }) => {

    branchStore.setContractSerial(currentTarget.value)
  }

  const onNavChange = (activeTab: string) => {
    setActiveTab(activeTab)
  }

  const onSerialSave = (event: any) => {
    branchStore.saveSerial()
  }


  return (
    <div className='main-content'>
      <HeaderImage showButton={false} title={branchStore.name}>
        <div className="container">
          <div className="row" >
            <div className="col-4">
              <Input
                label='Serie para contratos'
                placeholder='DSC...'
                value={branchStore.serialprefix}
                onChange={onContractSerial} />

              <button onClick={onSerialSave} className="btn btn-primary" style={{
                height: 50
              }}> Guardar</button>
            </div>
          </div>
        </div>
        <NavTab value={activeTab}>
          <NavItem
            onClick={() => onNavChange('users')}
            value='users'
            label='Usuarios'
            to={`/home/branch/${match.params.branchId}/users`}
          />
          <NavItem
            onClick={() => onNavChange('employees')}
            value='employees'
            label='Empleados'
            to={`/home/branch/${match.params.branchId}/employees`}
          />
        </NavTab>
      </HeaderImage>
      <Route path={`/home/branch/${match.params.branchId}/users`} component={Users} />
      <Route path={`/home/branch/${match.params.branchId}/employees`} component={Employees} />
    </div>
  )
}));

export default Branch

import _ from 'lodash'
import moment from 'moment'

export function resolver({ value, validator }: { value: any, validator: any }) {
  let validators = []

  if (!_.isArray(validator)) {
    validators = [validator]
  } else {
    validators = validator.map((valFunction) => valFunction)
  }
  // execute validators in series
  return validators.reduce((error, valFunction) =>
    (error.length)
      ? error
      : valFunction(value)
    , '')
}

export function resolverAsync({ value, validator }: { value: any, validator: any }) {
  let validators: any[] = []

  if (!_.isArray(validator)) {
    validators = [validator]
  } else {
    validators = validator.map((valFunction) => valFunction)
  }
  // execute validators in series
  function processValidators(valFunction: any): any {
    if (!valFunction) return Promise.resolve('')
    return Promise.resolve(valFunction(value))
      .then((error) =>
        (error.length)
          ? error
          : processValidators(validators.shift()))
  }

  return processValidators(validators.shift())
}

export function asyncValidator(asyncFunction: (field: any) => Promise<string>, message = 'Invalid data') {
  return (fieldValue: any) =>
    asyncFunction(fieldValue)
      .then((isValid) => isValid ? '' : message)
}

export function isOptional() {
  return () => ''
}

export function isRequired(message = 'Este campo es obligatorio') {
  return (fieldValue: string) =>
    _.isEmpty(fieldValue)
      ? message
      : ''
}

export function isEmail(message = 'Correo electronico incorrecto') {
  return (fieldValue: string) =>
    (fieldValue.match(/^[a-zA-Z0-9.\-#_~!&'()*+,;=:']+@([a-zA-Z0-9\-#_~!&'()*+,;=:'])+[.]+[.a-zA-Z0-9\-#_~!&'()*+,;=:']{2,63}$/gi) === null)
      ? message
      : ''
}

export function optionalEmail(message = 'Invalid email address') {
  return (fieldValue: string) =>
    (fieldValue.match(/.+@.+/) === null)
      ? message
      : ''
}

export function isBoolean(message = 'This field can be only true or false') {
  return (fieldValue: string) =>
    (!_.isBoolean(fieldValue))
      ? message
      : ''
}

export function isNotEmpty(message = 'This field can\'t be blank') {
  return (fieldValue: string) =>
    _.isEmpty(fieldValue)
      ? message
      : ''
}


export function isInt(message = 'Numero invalido') {
  return (fieldValue: string) => {
    if (/^([0-9]+)$/ig.test(fieldValue)) return ''
    return message
  }
}


export function isNumber(message = 'Numero invalido') {
  return (fieldValue: string) => {
    if (/^([0-9,.]+)$/ig.test(fieldValue)) return ''
    return message
  }
}

export function valueNumChar(message = 'Exceeds the maximum character length (256)') {
  return (fieldValue: string) => {
    if (!/^.{0,256}$/g.test(fieldValue)) {
      return message
    } else {
      return ''
    }
  }
}

export function isTrue(message = 'This must be selected') {
  return (fieldValue: boolean) =>
    (fieldValue !== true)
      ? message
      : ''
}

export function isZipCode(message = 'Invalid postal code') {
  // Matches ZIP (eg: 01952) or ZIP+4 (eg: 01952-1309
  //@ts-ignore
  return matchesPattern(/^[0-9]{5}(-[0-9]{4})?$/, message)
}

export function isPhoneNumber(message = 'Invalid phone number') {
  //@ts-ignore
  return matchesPattern(/^\(?[0-9]{3}\) [0-9]{3}-[0-9]{4}$/, message)
}

// Note: this expects a year matching 19__ or 20__
export function isDate(message = 'Invalid date') {
  const format = 'DD/MM/YYYY mm:hh'
  return (fieldValue: string) => {
    const date = moment(fieldValue, format)
    if (date.isValid()) return ''
    return message
  }
}

export function isReasonableLength(message = 'Exceeds the maximum character length (256)') {
  return (fieldValue: string) =>
    (fieldValue && fieldValue.length > 256)
      ? message
      : ''
}

export function matchesPattern(pattern: string, message = 'Invalid') {
  return (fieldValue: string) =>
    (fieldValue.match(pattern) === null)
      ? message
      : ''
}

export function isArrayWithMinLength(min = 1, message = `Please enter at least ${min} items`) {
  return (fieldValue: string) =>
    (_.isArray(fieldValue) && fieldValue.length < min)
      ? message
      : ''
}

//
// Prevent validators from returning an error when the value is blank
// Usage:
//   validators { 'zipCode': optional(isZipCode()) }
//
export function optional(validator: (fieldValue: string) => void) {
  return (fieldValue: string) =>
    _.isEmpty(fieldValue)
      ? ''
      : validator(fieldValue)
}

export function isDateInFuture(message = 'Please enter a date of birth that is in the past') {
  return (fieldValue: string) =>
    new Date(fieldValue) > new Date()
      ? message
      : ''
}

import React, { Component, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import HeaderImage from '../components/HeaderImage'
import { useHistory } from 'react-router-dom'
import BranchStore from '../stores/Branch';
import BranchesStore from '../stores/BranchesStore';
import { Branch } from '../stores/types/branch.types';


const BranchRow = inject('branchStore')(observer(({ branchStore, name, id, createdOn }: { id: string; name: string; createdOn: string; branchStore?: BranchStore }) => {
  const history = useHistory();
  const onBranchClick = (data: any) => {
    branchStore?.loadData(data)
    history.push(`/home/branch/${data.id}/users`)
  }

  return (
    <div className='col-12 col-md-6 col-xl-4'>
      <div className='card pointer' onClick={() => onBranchClick({ id, name })}>

        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col'>
              <h4 className='card-title mb-2 name'  >
                {name}
              </h4>

              <p className='card-text small text-muted'>
                Fecha de creacion: {createdOn}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}));


const Branches = inject('branchesStore')(observer(({ branchesStore }: { branchesStore: BranchesStore }) => {

  useEffect(() => {

    fetchBranches()
  }, []);

  const fetchBranches = () => {
    branchesStore.getBranches()
  }
  const renderBranch = (branch: typeof branchesStore.allBranches[0]) => {
    return (
      <BranchRow
        id={branch.id}
        name={branch.name}
        createdOn={branch.createdOn}
      />
    )
  }

  const branches = branchesStore.allBranches.map(renderBranch)
  return (
    <div className='main-content'>
      <HeaderImage to='/home/new-branch' title='Sucursales' />
      <div data-toggle='lists'>
        <div className='container-fluid' data-toggle='lists' data-lists-className='listAlias'>
          <div className='tab-content'>
            <div className='tab-pane fade active show' id='tabPaneOne' role='tabpanel'>
              <div className='row listAlias'>
                {branches}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}));

export default Branches

import { ancoAPI } from '../utils/http-client'

export function fetchEmployees({
  searchText = '',
  limit = 10
}: {
  searchText?: string;
  limit?: number;
}) {
  return ancoAPI
    .get(`/api/employee?search=${searchText}&limit=${limit}`)
}

export function createEmployee({ firstName, lastName, salary, userId }) {
  return ancoAPI
    .post(`/api/employee`, {
      first_name: firstName,
      last_name: lastName,
      salary
    })
}

export function updateEmployee({ firstName, lastName, salary, userId, employeeId }) {
  return ancoAPI
    .put(`/api/employee/${employeeId}`, {
      first_name: firstName,
      last_name: lastName,
      salary
    })
}

export function removeEmployee({ employeeId }) {
  return ancoAPI
    .delete(`/api/employee/${employeeId}`)
}

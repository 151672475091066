import React from 'react'

export default function CardUser({ name, email, isAdmin, children }: { name: string; email?: string; isAdmin?: boolean; children?: React.JSX.Element; }) {
  // CHECK: Should we display email, creation date?
  return (
    <div className='card mb-3'>
      <div className='card-body'>
        <div className='row align-items-center'>
          <div className='col-auto'>
            {/*<a href='profile-posts.html' className='avatar avatar-lg'>
                          <img src='/assets/img/avatars/profiles/avatar-1.jpg' alt='...' className='avatar-img rounded-circle' />
                        </a>*/}

          </div>
          <div className='col ml--2'>
            <h4 className='card-title mb-1'>
              {name}
            </h4>

            {email && email.length > 0 ? (
              <p className='card-text small text-muted mb-1'>
                {email}
              </p>
            ) : null}

            {isAdmin ? <p className='card-text small'>
              <span className='text-success'>●</span> Admin
            </p> : null}

          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

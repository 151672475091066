import { useEffect } from 'react'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { inject, observer } from 'mobx-react'
import Inventory from '../../../../stores/InventoryStore'
import SuppliersStore from '../../../../stores/SuppliersStore'
import { useHistory } from 'react-router-dom'
import { bindSelect, bindInput } from '../../../../utils/bindField'
import inventoryItemSource from '../../../../sources/inventoryItemSource'


export const CreateInventory = inject('inventoryStore', 'supplyStore', 'suppliersStore')(observer(({ inventoryStore, suppliersStore }: { inventoryStore: Inventory; suppliersStore: SuppliersStore }) => {
  const history = useHistory();
  const fetchSuppliers = () => {

    return suppliersStore.getSuppliers()
  }

  useEffect(() => {

    fetchSuppliers()
  }, []);
  const onSubmit = async (event: any) => {

    event.preventDefault()
    console.log('submitting');

    try {
      const { data } = await inventoryItemSource.createInventoryItem({
        supplierId: inventoryStore.form.getField('supplierId'),
        cost: inventoryStore.form.getField('cost'),
        name: inventoryStore.form.getField('name'),
        unit: inventoryStore.form.getField('unit'),
        description: inventoryStore.form.getField('description'),
        profitPercentage: inventoryStore.form.getField('profitPercentage'),
      })
      console.log('data', data);

      history.goBack();
    } catch (e) {
      alert('Error al crear el inventario')
    }


  }
  const onCancel = () => {
    inventoryStore.restore()
  }

  const suppliersOptions = suppliersStore.suppliers.map(supplier => ({
    value: supplier.id,
    label: supplier.name
  }))

  return (
    <Form
      preTitle={`Nuevo inventario`}
      title='Agregar inventario'
      primaryButtonLabel={'Agregar inventario'}
      disableSubmit={!inventoryStore.form.isValid || inventoryStore.saving}
      isSubmitLoading={inventoryStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Select
          label='Provedor'
          options={suppliersOptions}
          {...bindSelect(inventoryStore.form, 'supplierId')}
        />

        <Input
          label='Nombre'
          {...bindInput(inventoryStore.form, 'name')}
        />

        <Input
          label='Descripcion'
          {...bindInput(inventoryStore.form, 'description')}
        />
        <Input
          label='Costo'
          {...bindInput(inventoryStore.form, 'cost')}
        />

        <Input
          label={'Unidad'}
          {...bindInput(inventoryStore.form, 'unit')}
        />
        <Input
          label={'Utilidad %'}
          {...bindInput(inventoryStore.form, 'profitPercentage')}
        />

      </>
    </Form>
  )
}))

import { useEffect, useState } from 'react'
import { Table } from '../../../../components/Table'
import HeaderImage from '../../../../components/HeaderImage'
import { match, useHistory } from 'react-router-dom'
import { ancoAPI } from '../../../../utils/http-client'
import dayjs from 'dayjs'
import numeral from 'numeral'
import inventoryItemSource from '../../../../sources/inventoryItemSource'
import { InventoryItem } from './Inventory.types'



export const Inventory = ({ match }: { match: match }) => {

  const history = useHistory();
  const [inventory, setInventory] = useState<InventoryItem[]>([]);

  const getInventory = async () => {
    const { data } = await inventoryItemSource.fetchInventoryItems({});
    console.log('data', data);
    setInventory(data);

  }
  useEffect(() => {

    getInventory();
  }, []);

  const onDelete = async (inventory: InventoryItem) => {
    await ancoAPI.delete(`/api/inventory-item/${inventory.id}`);
    getInventory();
  }

  const onEdit = (inventory: InventoryItem) => {
    history.push(`${match.url}/update-inventory/${inventory.id}`)
  }

  return (
    <div>
      <HeaderImage title='Inventario' to={`${match.url}/create-inventory`} />
      <Table>
        <thead>
          <tr>

            <th>Nombre</th>
            <th>Descripción</th>
            <th>Cost</th>
            <th>Unidad</th>

            <th>Utilidad %</th>
            <th>Cantidad</th>
            <th>Fecha de creacion</th>
          </tr>
        </thead>
        <tbody>
          {inventory.map((inventory, index) => {
            return <tr key={inventory.id}>
              <td>{inventory.name}</td>
              <td>{inventory.description}</td>
              <td>{numeral(inventory.cost).format('$0.0')}</td>
              <td>{inventory.unit}</td>
              <td>{inventory.profitPercentage}</td>
              <td>{inventory.quantity}</td>
              <td>{dayjs(inventory.created_date).format('DD/MM/YYYY')}</td>
              <td>
                <button className='btn btn-sm btn-primary' onClick={() => onEdit(inventory)}>Editar</button>
                <button className='btn btn-sm btn-danger' onClick={() => onDelete(inventory)}>Borrar</button>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Table } from '../../../../components/Table'
import HeaderImage from '../../../../components/HeaderImage'
import { match, useHistory } from 'react-router-dom'
import { ancoAPI } from '../../../../utils/http-client'
import dayjs from 'dayjs'
import { Quote } from './Quotes.types'

export const Quotes = ({ match }: { match: match }) => {
  const history = useHistory()
  const [quotes, setQuotes] = useState<Quote[]>([]);

  const getInventory = async () => {
    const { data } = await ancoAPI.get('/api/quote');
    console.log('data', data);
    setQuotes(data);

  }

  useEffect(() => {

    getInventory();
  }, []);

  const onDelete = async (quote: Quote) => {
    await ancoAPI.delete(`/api/quote/${quote.id}`);
    getInventory();
  }
  return (
    <div>
      <HeaderImage title='Cotizacion de cliente' to={`${match.url}/create-quote`} />
      <Table>
        <thead>
          <tr>

            <th>Name</th>
            <th>Descripción</th>
            <th>Cliente</th>
            <th>Maquinaria</th>
            <th>Fecha de creacion</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map((quote, index) => {
            return <tr key={quote.id}>
              <td>{quote.name}</td>
              <td>{quote.description}</td>
              <td>{quote.client?.name}</td>
              {quote.clientMachine ? <td>{quote.clientMachine?.name}</td> : <td>{quote.equipment?.name}</td>}

              <td>{dayjs(quote.created_date).format('DD/MM/YYYY')}</td>
              <td>
                <button className='btn btn-sm btn-primary' onClick={() => {
                  history.push(`${match.url}/add-items/${quote.id}`)
                }}>Abrir</button>
                <button className='btn btn-sm btn-success' onClick={() => {
                  history.push(`${match.url}/update-quote/${quote.id}`)
                }}>Editar</button>
                <h1 style={{
                  display: 'inline-block',
                  marginLeft: '10px',
                  marginRight: '10px',
                  lineHeight: 1,
                }}>|</h1>

                <button style={{
                }} className='btn btn-sm btn-danger' onClick={() => onDelete(quote)}>Borrar</button>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </div>
  )
}

import React, { Component, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { setAuthorizationHeader } from '../utils/http-client'
import { authorizationStorage } from '../utils/localstorage'
import SessionStore from '../stores/SessionStore'



const IsUserAuthenticated = inject('sessionStore')(observer(({
  sessionStore
}: {
  sessionStore?: SessionStore;
}) => {
  useEffect(() => {

    // get token from localstorage
    const token = authorizationStorage.getValue()
    if (token) {
      setAuthorizationHeader(token)
    }
    //if (token && !sessionStore.isLoggedIn) {
    sessionStore?.me()
    //}
  }, []);


  if (!sessionStore?.isLoading && !sessionStore?.isLoggedIn) {
    return <Redirect to='/login' />
  }

  return <div />


}));

export default IsUserAuthenticated

import { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import SelectSearch, { SelectSearchOption } from '../components/SelectSearch'
import { debounce } from 'lodash'
import { ProjectStore } from '../stores/ProjectsStore'
import ClientsStore from '../stores/ClientsStore'
import { useHistory } from 'react-router-dom'
import { Client } from '../stores/types/client.types'
import { useQuery } from '../hooks/useQuery'
import { fetchProject } from '../sources/project'

const ProjectForm = inject('projectStore', 'clientsStore')(observer(({
  projectStore,
  clientsStore,
}: {
  projectStore: ProjectStore;
  clientsStore: ClientsStore;
}) => {

  const query = useQuery();

  const projectId = query.get('id')

  const history = useHistory();
  const [client, setClient] = useState<Client>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {



    if (projectId) {
      fetchProject(projectId).then(({
        data: project
      }) => {

        const { id,
          name,
          address,
          neighborhood,
          supervisor,
          client,
          city,
          zip,
          state
        } = project;
        projectStore.loadData({
          id,
          name,
          address,
          neighborhood,
          supervisor,
          clientId: client.id,
          city,
          zip,
          state
        })
        setClient(client);
      })

    }
  }, [projectId]);

  const fetchClients = useRef(debounce((searchText = '') => {
    setLoading(true)
    clientsStore.getClients({
      search: searchText
    }).then(() => {
      setLoading(false)
    })
  }, 300)
  ).current;
  const onSubmit = (event: any) => {
    event.preventDefault()
    projectStore
      .save()
      .then(() => history.push('/home/projects'))
  }
  const onCancel = () => {
    projectStore.restore()
  }

  const onSelected = (option: SelectSearchOption) => {
    const { id, name, email } = option.meta as Client
    console.log('onClick', { id, name, email })
    const store = projectStore.form
    store.setField('clientId', id)
    setClient(option.meta);
    return name;
  }

  const onSearchClient = (seachText: string) => {
    fetchClients(seachText)
  }

  const renderClientItem = (option: SelectSearchOption) => {


    const { id, name, email, phone } = option.meta as Client

    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name} - {phone}
      </div>
    </div>


  }

  const { isNew } = projectStore
  const title = isNew ? 'Nuevo proyecto' : 'Actualizar proyecto'
  const buttonLabel = isNew ? 'Crear proyecto nuevo' : 'Editar proyecto'
  let options = clientsStore.clients.map(client => ({
    value: client.id,
    label: client.name,
    meta: client
  }))

  useEffect(() => {

    fetchClients()
  }, []);

  return (
    <Form

      preTitle='Proyectos'
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!projectStore.form.isValid || projectStore.saving}
      isSubmitLoading={projectStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <div>
        <SelectSearch label='Cliente'
          onSelected={onSelected}
          options={options}
          onSearch={onSearchClient}
          loading={loading}
          value={client?.name}
          renderItem={renderClientItem} />
        {/* <Select
          label='Cliente'
          options={options}
          disabled={!isNew}
          {...bindSelect(projectStore.form, 'clientId')}
        /> */}
        <Input
          label='Nombre del proyecto'
          {...bindInput(projectStore.form, 'name')}
        />
        <Input
          label='Direccion'
          {...bindInput(projectStore.form, 'address')}
        />
        <Input
          label='Colonia'
          {...bindInput(projectStore.form, 'neighborhood')}
        />
        <Input
          label='Ciudad'
          {...bindInput(projectStore.form, 'city')}
        />
        <Input
          label='Estado'
          {...bindInput(projectStore.form, 'state')}
        />
        <Input
          label='Codigo postal'
          {...bindInput(projectStore.form, 'zip')}
        />
        <Input
          label='Encargado de Obra'
          {...bindInput(projectStore.form, 'supervisor')}
        />
      </div>
    </Form>
  )
}
))

export default ProjectForm

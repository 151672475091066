// @flow
import { observable, action, computed, makeObservable } from 'mobx'
import moment from 'moment'
import { fetchBranches } from '../sources/branch'
import UiStore from './UiStore'
import SessionStore from './SessionStore'
import { Branch } from './types/branch.types'

export default class BranchesStore {
  @observable branches: Array<Branch> = []
  private sessionStore: SessionStore;
  private uiStore: UiStore;

  constructor({ uiStore, sessionStore }: { uiStore: UiStore; sessionStore: SessionStore; }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore;
    makeObservable(this);
  }

  @action.bound
  getBranches() {
    const { id } = this.sessionStore

    return fetchBranches(id)
      .then(({ data }) => {
        const branches = data
        this.branches = branches
        return branches
      })
      .catch(this.uiStore.handleError('Error al obtener sucursales'))
  }

  @action.bound
  getBranchById(branchId: string): Branch {
    let branch = this.branches.find((branch) => {
      return branch.id === branchId
    })

    branch || (branch = {} as Branch)
    return {
      name: branch.name,
      serialprefix: branch.serialprefix,
      address: branch.address
    } as Branch
  }

  @computed get allBranches() {
    return this.branches.map(({ id, name, created_date }) => ({
      id,
      name,
      createdOn: moment(created_date).format('DD/MM/YYYY')
    }))
  }

  getBranchByName(branchName = '') {
    return this.allBranches.find(branch => branch.name.toLowerCase() === branchName.toLowerCase())
  }
}

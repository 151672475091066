export function bindInput(store, fieldName) {
  return {
    error: store.getFieldError(fieldName),
    value: store.getField(fieldName),
    onChange({ target }) {
      store.setField(fieldName, target.value)
    },
    onBlur() {
      store.validateField(fieldName)
    }
  }
}

export function bindSelect(store, fieldName) {
  return {
    error: store.getFieldError(fieldName),
    value: store.getField(fieldName),
    onChange({ value }) {
      store.setField(fieldName, value)
    },
    onBlur() {
      store.validateField(fieldName)
    }
  }
}

export function bindCheckbox(store, fieldName) {
  return {
    error: store.getFieldError(fieldName),
    checked: store.getField(fieldName),
    onChange({ target }) {
      store.setField(fieldName, target.checked)
    },
    onBlur() {
      store.validateField(fieldName)
    }
  }
}

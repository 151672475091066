import { observable, computed, action, toJS, makeObservable } from 'mobx'
import {
  createEquipment,
  updateEquipment,
  fetchEquipment,
  fetchEquipments,
  removeEquipment,
  deleteEquipmentPhoto,
} from '../sources/equipment'
import Form from './Form'
import {
  isRequired,
  isNumber,
  isOptional
} from '../utils/validator'
import { stringMatchInObject } from '../utils/SearchUtil'
import UiStore from './UiStore'
import { LeaseCost } from './types/lease.types'
import { Equipment, EquipmentPhoto } from './types/equipment.types'

export class EquipmentStore {
  @observable file = {}
  @observable file2 = {}
  @observable id = ''
  @observable saving = false
  @observable public leasesCost: Form<LeaseCost>[] = []
  @observable public photos: EquipmentPhoto[] = []
  private uiStore: UiStore;

  public form: Form<{
    id?: string;
    name: string;
    description: string;
    branchId?: string;
    inventory?: number;
    scaffold?: boolean;
    hours?: number;
    cost?: number;
  }>;

  constructor({ uiStore }: { uiStore: UiStore }) {
    this.form = new Form({
      id: undefined,
      name: '',
      description: '',
      branchId: '',
      inventory: 1,
      scaffold: false,
      hours: 0,
      cost: 0
    }, {

      name: isRequired(),
      description: isRequired(),
      branchId: isRequired(),
      hours: [
        isNumber()
      ],
      cost: [
        isRequired(),
        isNumber()
      ]
    })
    this.uiStore = uiStore
    // this.addLeaseCost()
    makeObservable(this);

  }

  @action.bound
  removePhoto(equipmentPhotoId: string) {
    const equipmentId = this.id
    return deleteEquipmentPhoto(equipmentPhotoId)
      .then(({ data }) => {
        this.restore();

        this.fetchData(equipmentId);
      })
  }

  @action.bound
  cancelPhoto(id: number) {
    this.photos.splice(1, id);
  }

  @action.bound
  addLeaseCost() {
    this.leasesCost.push(
      new Form<LeaseCost>({
        id: '',
        cost: undefined,
        unit: ''
      }, {
        id: isOptional(),
        cost: [isNumber(), isRequired()],
        unit: isRequired(),
      })
    )

    // this.leasesCost = [
    //   //@ts-ignore
    //   ...this.leasesCost,
    //   //@ts-ignore
    //   new Form({
    //     id: '',
    //     cost: undefined,
    //     unit: ''
    //   }, {
    //     id: isOptional(),
    //     cost: [isNumber(), isRequired()],
    //     unit: isRequired(),
    //   })
    // ]
  }

  @action.bound
  addPhoto() {
    this.photos = [
      //@ts-ignore
      ...this.photos,
      //@ts-ignore
      {}
    ]
  }

  @action.bound
  removeLeaseCost(index: number) {
    this.leasesCost = [
      ...this.leasesCost.slice(0, index),
      ...this.leasesCost.slice(index + 1)
    ]
  }

  @action.bound
  restore() {
    this.file = {}
    this.file2 = {}
    this.id = ''
    this.saving = false
    this.leasesCost = [];
    this.photos = [];
    this.addLeaseCost();
    this.form.resetAllFields();
  }


  buildUrl(url: string) {
    if (!url) {
      return ''
    }
    return url.replace('anco-s3', 'localhost')
  }

  update() {
    const formData = toJS(this.form.fields)
    const leases = toJS(this.leasesCost.map((leaseCost) => leaseCost.fields))

    return updateEquipment({
      equipmentId: this.id,
      branchId: formData.branchId,
      name: formData.name,
      inventory: formData.inventory,
      scaffold: formData.scaffold,
      hours: formData.hours,
      description: formData.description,
      cost: formData.cost,
      leaseCost: leases,
      //Remove existing photos
      photos: this.photos.filter((photo) => !photo.id).map((photo) => photo as unknown as File)
    })
      .catch(this.uiStore.handleError('Error al editar equipo de renta'))
  }

  create() {
    const formData = toJS(this.form.fields)
    const leases = toJS(this.leasesCost.map((leaseCost) => leaseCost.fields))

    return createEquipment({
      branchId: formData.branchId,
      name: formData.name,
      hours: formData.hours,
      inventory: formData.inventory,
      scaffold: formData.scaffold,
      description: formData.description,
      cost: formData.cost,
      photos: this.photos.map((photo) => photo as unknown as File),
      leaseCost: leases
    })
      .catch(this.uiStore.handleError('Error al crear equipo de renta'))
  }

  @action.bound
  save() {
    let promise = null
    this.saving = true
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return promise
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  fetchData(id: string) {
    const equipmentId = this.id ? this.id : id
    this.leasesCost.splice(0, this.leasesCost.length); // Clears the observable array
    return fetchEquipment(equipmentId)
      .then(({ data: equipment }: { data: Equipment }) => {
        this.leasesCost.splice(0, this.leasesCost.length); // Clears the observable array
        console.log('this.leasesCost', this.leasesCost);
        console.log('fetchEquipment ===>', equipment.equipmentLeaseCosts);
        equipment.equipmentLeaseCosts?.forEach((leaseCost, index) => {
          // this.addLeaseCost();
          this.leasesCost.push(
            new Form<LeaseCost>({
              id: leaseCost.id,
              cost: leaseCost.cost,
              unit: leaseCost.time_unit
            }, {
              id: isOptional(),
              cost: [isNumber(), isRequired()],
              unit: isRequired(),
            })
          )
          // const form = this.leasesCost[index]
          // // form.id = leaseCost.id
          // console.log('leaseCost ===>', leaseCost);
          // form.initialize({
          //   id: leaseCost.id,
          //   cost: leaseCost.cost,
          //   unit: leaseCost.time_unit
          // })
        })

        equipment.photos.forEach((photo, index) => {
          this.addPhoto();
          this.photos[index] = photo;
        })
        return equipment;
      })
      .finally(() => {
        console.log('finally this.leasesCost ===>', this.leasesCost);
      })
      .catch(this.uiStore.handleError('Error al obtener informacion de equipo'))

  }

  getCostByLeaseEquipmentCostId(equipmentCostId: string) {

    return this.leasesCost.find(leaseCost => leaseCost.fields.id === equipmentCostId)
  }

  @action.bound
  loadData({ id, name, description, hours, scaffold, inventory, cost, branch, fileUrl, fileUrl2 }: Equipment & { fileUrl?: string; fileUrl2?: string }) {
    this.id = id
    this.file2 = {
      preview: fileUrl2
    }
    this.file = {
      preview: fileUrl
    }
    this.form.initialize({
      id,
      name,
      description,
      cost,
      scaffold,
      inventory,
      hours,
      branchId: branch.id
    })
  }

  @computed get equipmentPhotos() {
    return this.photos.slice();
  }

  @computed get isNew() {
    return this.id.length === 0
  }

  @computed get isLeasesCostValid() {
    return this.leasesCost.reduce((prev, curr) => {
      if (prev === false) return false
      return curr.isValid
    }, true)
  }
}

export class EquipmentsStore {
  @observable equipments: Equipment[] = []
  @observable searchValue = ''

  private uiStore: UiStore;

  constructor({ uiStore }: { uiStore: UiStore }) {
    this.uiStore = uiStore
    makeObservable(this)
  }

  @action.bound
  getEquipments(params?: {
    limit?: number,
    search: string,
    offset?: number,
    scaffold?: boolean | string,
  }) {
    return fetchEquipments({
      limit: params?.limit,
      search: params?.search,
      offset: params?.offset,
      scaffold: params?.scaffold
    })
      .then(({ data }) => {
        const equipments = data
        this.equipments = equipments
      })
      .catch(this.uiStore.handleError('Error al obtener equipos de renta'))
  }

  @action.bound
  deleteEquipmentById(id = '') {
    const equipmentIndex = this.equipments.findIndex(equipment => equipment.id === id)

    if (equipmentIndex < 0) return
    return removeEquipment(id)
      .then(() => {
        this.equipments = [
          ...this.equipments.slice(0, equipmentIndex),
          ...this.equipments.slice(equipmentIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar equipo de renta'))
  }

  @action.bound
  setSearchValue(value: string) {
    this.searchValue = value
  }

  @computed get allEquipments() {
    return this.equipments.map(equipment => ({
      ...equipment,
      id: equipment.id,
      name: equipment.name,
      description: equipment.description,
      cost: equipment.cost,
      hours: equipment.hours,
      inventory: equipment.inventory,
      scaffold: equipment.scaffold,
      branchId: equipment.branch.id,
      branchName: equipment.branch.name,
      humanId: equipment.lease?.human_id,
      leaseId: equipment.lease?.id,
      createdOn: equipment.created_date,
      updatedOn: equipment.updated_date,
      available: equipment.available,
      fileUrl: equipment.file_url,
      fileUrl2: equipment.file_url2
    }))
  }

  @computed get filteredEquipments() {
    if (this.searchValue.length === 0) return this.allEquipments
    const filterableProperties = [
      'name',
      'description',
      'hours',
      'cost',
      'branchName'
    ]

    return this.allEquipments.filter(equipment => stringMatchInObject({
      obj: equipment,
      match: this.searchValue,
      //@ts-ignore
      filterableProperties
    }))
  }

  @computed get availableEquipments() {
    return this.allEquipments.filter(equipment => (equipment.available || equipment.inventory > 0))
  }
}

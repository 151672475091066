import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchUsers,
  createUser,
  deleteUserById,
  changeUserPassword,
  makeAdmin,
  removeAdmin
} from '../sources/user'
import Form from './Form'
import {
  isRequired,
  isEmail
} from '../utils/validator'
import UiStore from './UiStore'

export class UsersStore {
  @observable users: any[] = []
  private uiStore: UiStore;
  public form: Form<{
    newPassword: string;
    oldPassword: string;
  }>;

  constructor({ uiStore }: { uiStore: UiStore }) {
    this.uiStore = uiStore
    this.form = new Form({
      newPassword: '',
      oldPassword: ''
    }, {
      newPassword: isRequired(),
      oldPassword: isRequired()
    });

    makeObservable(this);
  }

  @action.bound
  makeAdmin({ id: userId }: { id: string }) {
    return makeAdmin({ userId })
      .then(() => this.getUsers())
      .catch(this.uiStore.handleError('Error al actualizar usuario a admin'))
  }

  @action.bound
  removeAdmin({ id: userId }: { id: string }) {
    return removeAdmin({ userId })
      .then(() => this.getUsers())
      .catch(this.uiStore.handleError('Error al actualizar usuario a admin'))
  }

  @action.bound
  changeUserPassword({ id: userId }: { id: string }) {
    const { newPassword, oldPassword } = toJS(this.form.fields)
    if (newPassword === oldPassword) return

    return changeUserPassword({ userId, newPassword, oldPassword })
      .catch(this.uiStore.handleError('Error al actualizar contrasena'))
  }

  @action.bound
  getUsers() {
    return fetchUsers()
      .then(({ data }) => {
        const users = data

        console.log('users', users);
        this.users = users
      })
      .catch(this.uiStore.handleError('Error al obtener usuarios del servidor'))
  }

  @computed get allUsers() {
    return this.users.map(({ first_name, last_name, ...rest }) => ({
      name: `${first_name} ${last_name}`,
      ...rest
    }))
  }

  @action.bound
  removeUserById(id = '') {
    const userIndex = this.users.findIndex(user => user.id === id)
    if (userIndex < 0) return
    return deleteUserById(id)
      .then(() => {
        this.users = [
          ...this.users.slice(0, userIndex),
          ...this.users.slice(userIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar usuario'))
  }
}

export class UserStore {
  @observable saving = false
  private uiStore: UiStore;
  public form: Form<{
    firstName: string;
    lastName: string;
    email: string;
    firstPassword: string;
    secondPassword: string;
  }>;

  constructor({ uiStore }: { uiStore: UiStore }) {
    this.form = new Form({
      firstName: '',
      lastName: '',
      email: '',
      firstPassword: '',
      secondPassword: ''
    }, {
      firstName: isRequired(),
      lastName: isRequired(),
      email: [
        isRequired(),
        isEmail()
      ],
      firstPassword: isRequired(),
      secondPassword: isRequired()
    })
    this.uiStore = uiStore
  }

  @computed get isPasswordOkay() {
    const { fields } = this.form
    return fields.firstPassword === fields.secondPassword
  }

  @computed get formValid() {
    return this.isPasswordOkay && this.form.isValid
  }

  @action.bound
  create() {
    this.saving = true
    const {
      firstName,
      lastName,
      email,
      firstPassword
    } = toJS(this.form.fields)

    return createUser({
      firstName,
      lastName,
      email,
      password: firstPassword
    })
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        this.uiStore.setError('Error al crear usuario')
        throw error
      })
  }

  @action.bound
  restore() {
    this.saving = false
    this.form.resetAllFields()
  }
}

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import { EmployeeStore, EmployeesStore } from '../stores/EmployeesStore'
import { useHistory } from 'react-router-dom'


const Employee = inject('employeeStore', 'employeesStore')(observer(({
  employeeStore,
  employeesStore,
}: {
  employeeStore: EmployeeStore;
  employeesStore: EmployeesStore;
}) => {
  const history = useHistory();
  const onSubmit = (event: any) => {
    event.preventDefault()
    // TODO: Validate form is okay
    employeeStore
      .save()
      .then(() => {
        // CHECK: redirect to employees so you can see new user you created
        history.push('/home/employees')
      })
  }
  const onCancel = () => {
    employeeStore.restore()
  }

  const { isNew } = employeeStore
  const buttonLabel = isNew ? 'Crear empleado' : 'Editar empleado'
  const title = isNew ? 'Crear nuevo empleado' : 'Actualizar empleado'

  return (
    <Form
      preTitle='Empleados'
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!employeeStore.form.isValid || employeeStore.saving}
      isSubmitLoading={employeeStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label='Nombre(s)'
          {...bindInput(employeeStore.form, 'firstName')}
        />
        <Input
          label='Apellidos'
          {...bindInput(employeeStore.form, 'lastName')}
        />
        <Input
          label='Salario'
          {...bindInput(employeeStore.form, 'salary')}
        />
      </>
    </Form>
  )
}));

export default Employee

import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Link } from 'react-router-dom'
import HeaderImage from '../components/HeaderImage'
import { NavTab, NavItem } from '../components/NavTab'
import { Table, TableBody, TableHead } from '../components/Table'

function SupplyInventory({ id, supplyName, supplierName, cost, qty, match, onDeleteClick }) {
  return (
    <tr>
      <td className='supply-inventory-name'>
        {supplyName}
      </td>
      <td className='supply-inventory-cost'>
        {cost}
      </td>
      <td className='supply-inventory-qty'>
        {qty}
      </td>
      <td className='supply-inventory-supplier'>
        {supplierName}
      </td>
      <td>
        <Link
          className='btn btn-sm btn-danger d-none d-md-inline-block'
          to={match.url}
          onClick={onDeleteClick}
        >
          Borrar
        </Link>
      </td>
    </tr>
  )
}

const Inventory = inject('supplyStore', 'inventoryStore')(observer(({ supplyStore, inventoryStore, match }) => {
  const { form } = supplyStore
  const { allInventory, total } = inventoryStore

  useEffect(() => {
    inventoryStore.getInventory()
  }, [inventoryStore])

  const removeInventory = (inventoryId) => {
    inventoryStore.deleteInventoryById(inventoryId)
  }

  const renderSupplyInventory = (supplyInventory) => {
    return (
      <SupplyInventory
        {...supplyInventory}
        match={match}
        onDeleteClick={() => removeInventory(supplyInventory.id)}
      />
    )
  }

  const rows = allInventory.map(supplyInventory => renderSupplyInventory(supplyInventory))

  return (
    <div className='main-content'>
      <HeaderImage
        title={form.getField('name')}
        to={`/home/supply/${supplyStore.id}/inventory-form`}
      >
        {/* <NavTab value='inventory'>
          <NavItem
            value='inventory'
            label='Inventario'
            onClick={() => {}}
            to={`/home/supply/${supplyStore.id}/inventory`}
          />
        </NavTab> */}
      </HeaderImage>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Table>
              <TableHead>
                <th>
                  <a href='#' className='text-muted sort' data-sort='inventory-name'>
                    Material
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='inventory-name'>
                    Costo
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='inventory-name'>
                    Cantidad
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='inventory-name'>
                    Provedor
                  </a>
                </th>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
          </div>
          <div className='col-12 col-xl-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <h5 className='mb-0'>
                      Total
                    </h5>
                  </div>
                  <div className='col-auto'>
                    <small className='text-muted'>
                      {total}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}))

const SupplyInfo = ({ match }) => {
  return <Route path={`/home/supply/${match.params.supplyId}/inventory`} component={Inventory} />
}

export default SupplyInfo

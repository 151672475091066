//@ts-nocheck
import React, { Component } from 'react'
import classnames from 'classnames'

export default class InputMask extends Component<{ label: string, mask: string; }> {
  componentDidMount() {
    const { onBlur, onChange, mask } = this.props

    this.$input = $(this.input)
    this.$input.mask(mask, {
      onChange(value, event) {
        onChange(event)
      }
    })
    this.$input.blur(onBlur)
  }
  componentWillUnmount() {
    this.$input.unmask()
  }

  render() {
    const { label, value, error } = this.props
    const hasError = !!(error && error.length > 0)
    const inputClassName = classnames('form-control', {
      'is-invalid': hasError
    })
    return (
      <div className='form-group'>
        <label className='mb-1'>
          {label}
        </label>
        <input
          type='text'
          className={inputClassName}
          ref={el => { this.input = el }}
          value={value}
        />
        {hasError ? (
          <div className='invalid-feedback'>
            {error}
          </div>
        ) : null}
      </div>
    )
  }
}
import React, { useCallback } from 'react'
import { inject, useObserver } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import { SupplierStore } from '../stores/SuppliersStore'
import { useHistory } from 'react-router-dom'

const Supplier = inject('supplierStore')(({ supplierStore }: {
  supplierStore: SupplierStore;
}) => {

  const history = useHistory();

  const onSubmit = useCallback((event: any) => {
    event.preventDefault()
    // TODO: Validate form is okay
    supplierStore
      .save()
      .then(() => {
        // CHECK: redirect to employees so you can see new user you created
        history.goBack();
      })
  }, [supplierStore, history])

  const onCancel = useCallback(() => {
    supplierStore.restore()
  }, [supplierStore])

  const { isNew } = supplierStore
  const buttonLabel = isNew ? 'Crear provedor' : 'Editar provedor'
  const title = isNew ? 'Crear nuevo provedor' : 'Actualizar provedor'

  return useObserver(() => (
    <Form
      preTitle='Provedores'
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!supplierStore.form.isValid || supplierStore.saving}
      isSubmitLoading={supplierStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label='Nombre(s)'
          {...bindInput(supplierStore.form, 'name')}
        />
        <Input
          label='RFC'
          {...bindInput(supplierStore.form, 'rfc')}
        />
        <Input
          label='Telefono'
          {...bindInput(supplierStore.form, 'phone')}
        />
        <Input
          label='Email'
          {...bindInput(supplierStore.form, 'email')}
        />
        <Input
          label='Calle'
          {...bindInput(supplierStore.form, 'street')}
        />
        <Input
          label='Numero'
          {...bindInput(supplierStore.form, 'number')}
        />
        <Input
          label='Colonia'
          {...bindInput(supplierStore.form, 'neighborhood')}
        />
        <Input
          label='Ciudad'
          {...bindInput(supplierStore.form, 'city')}
        />
        <Input
          label='Estado'
          {...bindInput(supplierStore.form, 'state')}
        />
        <Input
          label='Codigo Postal'
          {...bindInput(supplierStore.form, 'zip')}
        />
      </>
    </Form>
  ))
})

export default Supplier

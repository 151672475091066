//@ts-nocheck
import React, { Component } from 'react'
import _ from 'lodash'


export type SelectOptions = {
  value: string;
  label: string;
  meta?: any
}
export default class Select extends Component<{
  options: SelectOptions[];
  value: string | undefined;
  renderTemplate?: (state: any) => JSX.Element | JQuery<HTMLElement> | null;
  onChange?: ({ event, value }: { event: any, value: string | number }) => void;
  onBlur?: () => void;
  disabled?: boolean;
}> {

  static defaultProps = {
    label: ''
  }

  componentDidMount() {
    const { renderTemplate } = this.props
    let options = {}

    this.$el = $(this.el)
    if (_.isFunction(renderTemplate)) {
      options = Object.assign({}, {
        templateResult: this.renderTemplate
      })
    }

    this.$el.select2(options)
    $(this.el).data('select2').$container.addClass('form-control select-component')
    // listen to select event to set branchId field
    this.$el.on('change.select2', (e) => {
      const value = this.$el.find(':selected').data('value')
      this.props.onChange({
        event: e,
        value
      })
    })

    this.$el.on('select2:close', (e) => {
      this.props.onBlur && this.props.onBlur(e)
    })
    this.$el.on('select2:open', (e) => {
      this.$el.find('.select2-search__field').on('input', (e) => {
        console.log('finding', e.target);
      })
    })
  }
  componentWillUnmount() {
    this.$el.select2('destroy')
  }
  render() {
    const { label, value = '', disabled, options, error } = this.props
    const hasError = !!(error && error.length > 0)
    if (hasError && this.$el) {
      $(this.el).data('select2').$container.addClass('is-invalid')
      $(this.el).data('select2').$selection.addClass('select-component-error')
    }
    if (!hasError && this.$el) {
      $(this.el).data('select2').$container.removeClass('is-invalid')
      $(this.el).data('select2').$selection.removeClass('select-component-error')
    }
    const opts = options
      .map((option) => {
        if (option && option.meta) {
          return (
            <option key={option.value} data-value={option.value} data-meta={JSON.stringify(option.meta)} selected={option.value === value}>
              {option.label}
            </option>
          )
        }
        return (
          <option key={option.value} data-value={option.value} selected={option.value === value}>
            {option.label}
          </option>
        )
      })

    return (
      <div className='form-group'>
        <label>
          {label}
        </label>
        <select
          disabled={disabled}
          ref={el => { this.el = el }}
        >
          {value.length === 0 ? <option /> : null}
          {opts}
        </select>
        {hasError ? (
          <div className='invalid-feedback'>
            {error}
          </div>
        ) : null}
      </div>
    )
  }
}

Select.defaultProps = {
  label: ''
}

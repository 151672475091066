import React, { useEffect, useRef, useState } from 'react'
import Form from '../../../../components/Form'
import Input from '../../../../components/Input'
import { inject, observer } from 'mobx-react'

import { useHistory } from 'react-router-dom'
import { ancoAPI } from '../../../../utils/http-client'
import ClientsStore from '../../../../stores/ClientsStore'
import SelectSearch, { SelectSearchOption } from '../../../../components/SelectSearch'

import { Client } from '../../../../stores/types/client.types'
import { debounce } from 'lodash'
import { Equipment } from '../../../../stores/types/equipment.types'
import { EquipmentsStore } from '../../../../stores/EquipmentsStore'
import { ClientMachine } from '../ClientMachines/ClientMachine.types'

export const CreateQuote = inject('equipmentsStore', 'clientsStore', 'suppliersStore')(observer(({ equipmentsStore, clientsStore }: { equipmentsStore: EquipmentsStore; clientsStore: ClientsStore }) => {
  const history = useHistory();

  const [description, setDescription] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [client, setClient] = useState<Client>();
  const [initialDiagnostic, setInitialDiagnostic] = useState<string>('');

  const fetchClients = useRef(debounce((searchText = '') => {
    clientsStore.getClients({
      search: searchText
    })
  }, 300)
  ).current;

  useEffect(() => {

    fetchClients()
  }, []);
  const onSubmit = async (event: any) => {

    event.preventDefault()
    console.log('submitting');

    try {
      const { data } = await ancoAPI.post('/api/quote', {
        clientId: client?.id,
        name,
        description,
        initialDiagnostic,
        clientMachineId: clientMachine?.id,
        equipmentId: equipment?.id,
      })
      console.log('data', data);

      history.goBack();
    } catch (e) {
      alert('Error al crear cotizacion')
    }


  }




  const clientsOptions = clientsStore.clients.map(client => ({
    value: client.id,
    label: client.name,
    meta: client
  }))

  const onSelected = (option: SelectSearchOption) => {
    const { id, name } = option.meta as Client

    setClient(option.meta);
    return name;
  }

  const onSearchClient = (seachText: string) => {
    fetchClients(seachText)
  }

  const renderClientItem = (option: SelectSearchOption) => {


    const { id, name, email, phone } = option.meta as Client

    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name} - {phone}
      </div>
    </div>


  }

  // ClientMachine

  const [clientMachines, setClientMachines] = useState<ClientMachine[]>([]);
  const [clientMachine, setClientMachine] = useState<ClientMachine>();

  const fetchClientMachines = useRef(debounce(async (searchText = '') => {
    const { data } = await ancoAPI.get('/api/client-machine');
    console.log('fetchClientMachines')
    const filtered = data.filter((clientMachine: ClientMachine) => clientMachine.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    setClientMachines(filtered)
  }, 300)
  ).current;
  const clientMachinesOptions = clientMachines.map(client => ({
    value: client.id,
    label: client.name,
    meta: client
  }))

  const onSelectedClientMachine = (option: SelectSearchOption) => {
    const { id, name } = option.meta as ClientMachine

    setClientMachine(option.meta);
    return name;
  }

  const onSearchClientMachines = (seachText: string) => {
    fetchClientMachines(seachText)
  }

  const renderClientMachineItem = (option: SelectSearchOption) => {


    const { id, name, description } = option.meta as ClientMachine

    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name} - {description}
      </div>
    </div>
  }

  // Anco Equipment

  const [equipment, setEquipment] = useState<Equipment>();

  const fetchEquipments = useRef(debounce(async (searchText = '') => {
    equipmentsStore.getEquipments({ search: searchText });
  }, 300)
  ).current;
  const equipmentOptions = equipmentsStore.availableEquipments.map(equipment => ({
    value: equipment.id,
    label: equipment.name,
    meta: equipment
  }))

  const onSelectedEquipment = (option: SelectSearchOption) => {
    const { id, name } = option.meta as Equipment

    setEquipment(option.meta);
    return name;
  }

  const onSearchEquipment = (seachText: string) => {
    fetchEquipments(seachText)
  }

  const renderEquipmentItem = (option: SelectSearchOption) => {


    const { id, name, description } = option.meta as Equipment

    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name} - {description}
      </div>
    </div>
  }

  const isValid = () => {
    return name && description && (clientQuote ? clientMachine : equipment) && (clientQuote ? client : true);
  }

  const [clientQuote, setClientQuote] = useState<boolean>(true);

  useEffect(() => {
    if (clientQuote) {
      setEquipment(undefined);
    } else {
      setClientMachine(undefined);
    }
  }, [clientQuote]);
  return (
    <Form
      preTitle={`Nueva cotizacion`}
      title='Agregar cotizacion'
      primaryButtonLabel={'Agregar cotizacion'}
      disableSubmit={!isValid()}
      isSubmitLoading={false}
      onSubmit={onSubmit}
      onCancel={() => {
        history.goBack();
      }}

    >
      <>
        <SelectSearch label='Cliente'
          onSelected={onSelected}
          options={clientsOptions}
          onSearch={onSearchClient}
          value={client?.name}
          renderItem={renderClientItem} />

        <div>
          <div className='row'>
            <button className={`btn ${clientQuote ? 'btn-primary' : 'btn-secondary'}`} onClick={(event) => {
              event.preventDefault();
              setClientQuote(true)

            }} >
              Maquinaria Cliente
            </button>
            <button className={`btn ${!clientQuote ? 'btn-primary' : 'btn-secondary'}`} onClick={(event) => {
              event.preventDefault();
              setClientQuote(false)
            }} >
              Maquinaria Anco
            </button>
          </div>
        </div>
        {clientQuote && <SelectSearch label='Maquinaria Cliente'
          onSelected={onSelectedClientMachine}
          options={clientMachinesOptions}
          onSearch={onSearchClientMachines}
          value={clientMachine?.name}
          renderItem={renderClientMachineItem} />}

        {!clientQuote && <SelectSearch label='Maquinaria Anco'
          onSelected={onSelectedEquipment}
          options={equipmentOptions}
          onSearch={onSearchEquipment}
          value={equipment?.name}
          renderItem={renderEquipmentItem} />}

        <Input
          label='Nombre'
          onChange={(event) => {
            console.log('event', event);
            setName(event.target.value)
          }}
        />

        <Input
          label='Descripcion'
          onChange={(event) => {
            setDescription(event.target.value)
          }}
        />
        <Input
          label='Diagnostico Inicial'
          onChange={(event) => {
            setInitialDiagnostic(event.target.value)
          }}
        />
      </>
    </Form>
  )
}))

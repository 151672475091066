// @flow
import { Lease } from '../stores/types/lease.types'
import request, { ancoAPI } from '../utils/http-client'

export function createLease({ projectId, clientId, branchId, requestor, noTax }: {
  projectId: string;
  clientId: string;
  branchId: string;
  requestor: string;
  noTax: boolean;
}) {
  return ancoAPI
    .post('/api/leases', {
      project_id: projectId,
      client_id: clientId,
      branch_id: branchId,
      requestor,
      no_tax: noTax
    })
}

export function fetchLeases({
  limit,
  offset,
  search = '',
  branchId = ''
}: {
  limit: number;
  offset: number;
  search?: string;
  branchId?: string
}) {

  return ancoAPI
    .get(`/api/leases?limit=${limit}&offset=${offset}&search=${search}&branch=${branchId}`)
}
export function fetchLeaseById({
  leaseId
}: {
  leaseId: string
}) {

  return ancoAPI
    .get<Lease>(`/api/leases/${leaseId}`)
}

export function updateLease({
  projectId,
  clientId,
  branchId,
  leaseId,
  requestor,
  noTax,
  deliveryDate,
  receivedDate
}: {
  projectId: string;
  clientId: string;
  branchId: string;
  leaseId: string;
  requestor: string;
  noTax: boolean;
  deliveryDate: Date;
  receivedDate: Date;
}) {

  const params = {
    project_id: projectId,
    client_id: clientId,
    branch_id: branchId,
    requestor,
    no_tax: noTax,
    delivery_date: deliveryDate,
    received_date: receivedDate
  }
  console.log('updateData params', params);
  return ancoAPI
    .put(`/api/leases/${leaseId}`, params)
}

export function removeLease(leaseId: string) {
  return ancoAPI
    .delete(`/api/leases/${leaseId}`)
}
export function cancelLease(leaseId: string, canceled: boolean) {
  return ancoAPI
    .put(`/api/leases/${leaseId}/cancel`, { canceled })
}

export function downloadLeaseNotePdf(leaseId: string) {
  return ancoAPI
    .get(`/api/leases/${leaseId}/note/pdf`, { responseType: 'blob' })
}

export function downloadLeasePdf(leaseId: string) {
  return ancoAPI
    .get(`/api/leases/${leaseId}/pdf`, { responseType: 'blob' })
}

import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useRouteMatch } from 'react-router-dom'
import HeaderImage from '../components/HeaderImage'
import CardUser from '../components/CardUser'
import Input from '../components/Input'
import { bindInput } from '../utils/bindField'
import { UsersStore } from '../stores/Users'
import SessionStore from '../stores/SessionStore'

export function User({ id, name, isAdmin, onRemove, isMe, changePassword, changeAdmin, isLoggedInUserAdmin = false }:
  { id: string; name: string; isAdmin?: boolean; onRemove?: () => void; isMe: boolean; changePassword?: () => void; changeAdmin?: () => void; isLoggedInUserAdmin?: boolean; }) {
  let adminContent: React.JSX.Element | undefined = undefined
  const match = useRouteMatch();
  if (isLoggedInUserAdmin && !isMe) {
    adminContent = (<div className="row">
      <div className='col-auto'>
        <div className="form-check">
          <input className="form-check-input" onClick={changeAdmin} type="checkbox" checked={isAdmin} id="defaultCheck1" />
          <label className="form-check-label" htmlFor="defaultCheck1">
            Admin.
          </label>
        </div>
      </div>
      <div className='col-auto'>
        <button type="button" onClick={changePassword} className="btn btn-sm btn-primary" data-toggle="modal" data-target="#exampleModal">
          Cambiar Contraseña
        </button>
      </div>
      <div className='col-auto'>
        {<Link
          onClick={onRemove}
          to={match.url}
          className='btn btn-sm btn-danger d-inline-block'
        >
          Borrar
        </Link>}
      </div>
    </div>)
  }

  return (
    <CardUser
      name={name}
      isAdmin={isAdmin}
    >
      {adminContent}
    </CardUser>
  )
}

const Users = inject('usersStore', 'sessionStore')(observer(({ usersStore, sessionStore }: { usersStore: UsersStore; sessionStore: SessionStore }) => {
  const [user, setUser] = useState<any>();
  useEffect(() => {

    fetchUsers();
  }, []);
  const fetchUsers = () => {

    usersStore.getUsers()
  }
  const removeUser = (id: string) => {
    // CHECK: validate we don't remove all users
    usersStore.removeUserById(id)
    fetchUsers();
  }

  const changeIsAdmin = (user: any) => {
    if (user.admin) {
      usersStore.removeAdmin(user)
    } else {
      usersStore.makeAdmin(user)
    }
  }

  const renderUser = (user: any) => {

    return (
      <User
        id={user.id}
        name={user.name}
        isLoggedInUserAdmin={sessionStore.isAdmin}
        isAdmin={user.admin}
        changeAdmin={() => changeIsAdmin(user)}
        changePassword={() => changePassword(user)}
        isMe={sessionStore.id === user.id}
        onRemove={() => removeUser(user.id)}
      />
    )
  }

  const changePassword = (user: any) => {
    setUser(user)
  }


  const users = usersStore.allUsers.map(renderUser)
  return (
    <div className='main-content'>
      <HeaderImage to='/home/new-user' title='Usuarios' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            {users}
          </div>
        </div>
      </div>


      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{"Cambiar Contraseña de " + user.name}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Input
                type='password'
                label='Antigua Contraseña'
                placeholder='Antigua Contraseña'
                {...bindInput(usersStore.form, 'oldPassword')}
              />
              <Input
                type='password'
                label='Nueva Contraseña'
                placeholder='Nueva Contraseña'
                {...bindInput(usersStore.form, 'newPassword')}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button
                disabled={!usersStore.form.isValid}
                type="button"
                onClick={() => { }}
                data-dismiss="modal"
                className="btn btn-primary"
              >
                Cambiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}));

export default Users

'use client';
import React, { useState } from "react";
// import { pdfjs } from 'react-pdf';
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export const PreviewPDF = ({ src, title }: { src: string; title: string | undefined; }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }: { numPages: number; }) {
    setNumPages(numPages);
  }
  const actionPage = (type: string) => {
    switch (type) {
      case "PREV":
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1);
        }
        return;
      case "NEXT":
        if (numPages != pageNumber) {
          setPageNumber(pageNumber + 1);
        }
        return;
      default:
        return;
    }
  };

  return (
    <div>
      <div className="text-center">
        <h3>{title}</h3>
        {src && <a target="_blank" href={src}>{title}</a>}
        <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div>
        <nav aria-label="Page navigation example" className="text-center">
          <ul className="pagination">
            <li className="page-item">
              <div
                onClick={() => {
                  actionPage("PREV");
                }}
                className="page-link"
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </div>
            </li>
            <li className="page-item">
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </li>
            <li className="page-item">
              <div
                onClick={() => {
                  actionPage("NEXT");
                }}
                className="page-link"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

import { ancoAPI } from "../utils/http-client";

const fetchQuotes = (searchText = '') => {
  return ancoAPI.get('/api/quote?searchText=' + searchText);
}
const fetchQuoteById = (quoteId) => {
  return ancoAPI.get(`/api/quote/${quoteId}`);
}
const createQuote = ({ name, supplierId, cost, unit, description, profitPercentage }: { name: string; supplierId: string; cost: number; unit: string; description: string; profitPercentage: number; }) => {
  return ancoAPI.post('/api/quote', {
    supplierId,
    cost,
    unit,
    name,
    description,
    profitPercentage,
  })
}
const updateQuote = ({ name, supplierId, cost, unit, quantity, description, profitPercentage, quoteId }: { name: string; supplierId: string; cost: number; unit: string; quantity: number; description: string; profitPercentage: number; quoteId: string; }) => {
  return ancoAPI.put(`/api/quote/${quoteId}`, {
    supplierId,
    cost,
    unit,
    name,
    quantity,
    description,
    profitPercentage,
  })
}
const removeQuote = ({ quoteId }: { quoteId: string; }) => {
  return ancoAPI.delete(`/api/quote/${quoteId}`)
}

const addQuoteItem = ({ quoteId, inventoryItemId, quantity }: { quoteId: string; quantity: number; inventoryItemId: string; }) => {
  return ancoAPI.post(`/api/quote/${quoteId}/quote-item`, {
    quoteId,
    inventoryItemId,
    quantity
  })
}
const updateQuoteItem = ({ quoteId, quoteItemId, quantity, name, description }: { quoteId: string; quantity: number; quoteItemId: string; name: string; description: string; }) => {
  return ancoAPI.put(`/api/quote/${quoteId}/quote-item`, {
    quoteId,
    quoteItemId,
    quantity,
    name,
    description,
  })
}

const deleteQuoteItem = ({ quoteId, quoteItemId }: { quoteId: string; quoteItemId: string; }) => {
  return ancoAPI.delete(`/api/quote/${quoteId}/quote-item/${quoteItemId}`)
}

export const quoteSource = {
  fetchQuotes,
  fetchQuoteById,
  createQuote,
  updateQuote,
  removeQuote,
  addQuoteItem,
  updateQuoteItem,
  deleteQuoteItem
};
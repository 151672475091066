import { useEffect } from 'react'
import Card from './Card'
import { ClientStore } from '../stores/ClientsStore'
import { Client } from '../stores/types/client.types';

const ClientCard = ({ clientStore, client }: {
  clientStore: ClientStore;
  client?: Client;
}) => {

  useEffect(() => {
    if (clientStore.id)
      clientStore.fetchData()
  }, [clientStore, clientStore.id]);

  if (!client) return null

  const { name, email, phone } = client



  return (
    <Card >
      <>
        <div className='col-auto'>
          <h4 className='card-title mb-1'>
            {name}
          </h4>
        </div>
        <div className='col-auto'>
          <p className='card-text small text-muted mb-1'>
            {email}
          </p>
        </div>
        <div className='col-auto'>
          <p className='card-text small text-muted mb-1'>
            {phone}
          </p>
        </div>
      </>
    </Card>
  )
}

export default ClientCard

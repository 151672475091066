import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput, bindSelect } from '../../utils/bindField'
import Form from '../../components/Form'
import Select from '../../components/Select'
import Input from '../../components/Input'
import { MaintenanceDetailsStore } from '../../stores/MaintenanceDetailsStore'
import { MaintenanceStore } from '../../stores/Maintenances'
import InventoryStore from '../../stores/InventoryStore'
import { EmployeesStore } from '../../stores/EmployeesStore'
import { SupplyStore, SuppliesStore } from '../../stores/SuppliesStore'
import { useHistory } from 'react-router-dom'



const MaintenanceDetailForm = inject(
  'maintenanceDetailsStore',
  'maintenanceStore',
  'inventoryStore',
  'employeesStore',
  'suppliesStore',
  'supplyStore'
)(observer(({
  maintenanceDetailsStore,
  maintenanceStore,
  inventoryStore,
  employeesStore,
  suppliesStore,
  supplyStore,
}: {
  maintenanceDetailsStore: MaintenanceDetailsStore;
  maintenanceStore: MaintenanceStore;
  inventoryStore: InventoryStore;
  employeesStore: EmployeesStore;
  suppliesStore: SuppliesStore;
  supplyStore: SupplyStore
}) => {
  const history = useHistory();
  useEffect(() => {
    fetchEmployees()
    fetchSupplies()
  });

  const fetchSupplies = () => {
    return suppliesStore.getSupplies()
  }
  const fetchEmployees = () => {
    return employeesStore.getEmployees()
  }


  const selectInventory = ({ event, value: supplyId }: { event: any; value: string }) => {
    const selectedSupply = suppliesStore.getSupplyById(supplyId)
    supplyStore.loadData(selectedSupply)

  }
  const onSubmit = (event: any) => {
    event.preventDefault()
    maintenanceDetailsStore
      .create()
      .then(() => {
        history.push(`/home/maintenance/${maintenanceStore.id}/details`)
        supplyStore.restore()
      })
  }
  const onCancel = () => {
    supplyStore.restore()
    maintenanceDetailsStore.restore()
  }
  const renderIventoryItem = (state: any) => {
    const { element } = state
    const id = element && element.getAttribute('data-id')
    const qty = element && element.getAttribute('data-qty')
    const supplierName = element && element.getAttribute('data-supplier-name')
    const supplyName = element && element.getAttribute('data-supply-name')

    if (!id) return null

    const $row = $(
      `<div className='row'>
        <div className='col-12 col-xl-4'>
          ${qty}
        </div>
        <div className='col-12 col-xl-4'>
          ${supplierName}
        </div>
        <div className='col-12 col-xl-4'>
          ${supplyName}
        </div>
        </div>`
    )
    return $row
  }

  const employeeOptions = employeesStore.allEmployees.map(employee => ({
    value: employee.id,
    label: employee.name
  }))
  const suppliesOptions = suppliesStore.supplies.map(supply => ({
    value: supply.id,
    label: supply.name
  }))
  const inventoryOptions = inventoryStore.allInventory.map(item => ({
    value: item.id,
    label: `${item.qty} - ${item.supplierName} - ${item.supplyName}`,
    meta: item
  }))

  return (
    <Form
      preTitle={`${maintenanceStore.form.getField('name')} detalles`}
      title='Agregar detalle'
      primaryButtonLabel='Agregar detalle'
      disableSubmit={!maintenanceDetailsStore.form.isValid || maintenanceDetailsStore.saving}
      isSubmitLoading={maintenanceDetailsStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Select
          label='Empleado'
          options={employeeOptions}
          {...bindSelect(maintenanceDetailsStore.form, 'employeeId')}
        />
        <Select
          label='Material'
          options={suppliesOptions}
          value=''
          //@ts-ignore
          onChange={selectInventory}
        />
        <Select
          label='Inventario'
          renderTemplate={renderIventoryItem}
          options={inventoryOptions}
          {...bindSelect(maintenanceDetailsStore.form, 'inventoryId')}
        />
        <Input
          label='Cantidad'
          {...bindInput(maintenanceDetailsStore.form, 'qty')}
        />
      </>
    </Form>
  )
}));

export default MaintenanceDetailForm

import { useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import UiStore from '../stores/UiStore'


export const ErrorModal = inject('uiStore')(observer(({ uiStore }: { uiStore?: UiStore }) => {

  const onClose = () => {
    uiStore?.dismissModal()
  }

  const error = uiStore?.error
  const errorDetails = uiStore?.errorDetails
  const showModal = !!(error && error.length > 0)
  const hasDetails = errorDetails?.length ?? 0 > 0
  const details = errorDetails?.map((detail, idx: number) => (
    <li key={idx}>
      {detail}
    </li>
  ))
  return (
    <Modal show={showModal}>
      <div className='modal-card card'>
        <div className='card-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h4 className='card-header-title' id='exampleModalCenterTitle'>
                Error
              </h4>
            </div>
            <div className='col-auto'>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={onClose}>
                <span aria-hidden='true'>x</span>
              </button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          {error}
          {!hasDetails ? null : (
            <ul>
              {details}
            </ul>
          )}
        </div>
      </div>
    </Modal>
  )

}))



const Modal = ({ show, children }: { show: boolean; children: JSX.Element; }) => {
  const el = useRef(null);
  useEffect(() => {
    if (show) {
      console.log('opening')
      //@ts-ignore
      $(el.current).modal('show')
    } else {
      console.log('closing')
      //@ts-ignore
      $(el.current).modal('hide')
    }
  }, [show]);


  return (
    <div className='modal fade' id='modalMembers' tabIndex={-1} role='dialog' style={{ display: 'none' }} aria-hidden='false'
      ref={el}>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          {children}
        </div>
      </div>
    </div>
  )

}

export default Modal
import React from 'react'
import classnames from 'classnames'

export default function Input({
  onChange,
  value,
  onBlur,
  error = '',
  label = '',
  loading = false,
  disabled = false,
  defaultValue = undefined,
  placeholder = '',
  type = 'text',
}: {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  onBlur?: () => void
  error?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  defaultValue?: string | undefined;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
}) {
  const hasError = !!(error && error.length > 0)
  const inputClassNanme = classnames('form-control', {
    'is-invalid': hasError
  })
  return (
    <div className='form-group'>
      <label>
        {label}
      </label>
      <input
        type={type}
        className={inputClassNanme}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      {loading && (<div className="valid-tooltip">
        Looks good!
      </div>)}
      {hasError ? (
        <div className='invalid-feedback'>
          {error}
        </div>
      ) : null}
    </div>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

export function NavItem({
  label, to, value, activeValue, onChange, onClick }: {
    label: string;
    to: string;
    value: string;
    activeValue?: string;
    onChange?: (value: string) => void;
    onClick: () => void;
  }) {
  const isActive = value === activeValue
  const linkClasses = classnames('nav-link', {
    active: isActive
  })

  return (
    <li className='nav-item' onClick={() => {
      onClick()
      onChange?.(value)
    }}>
      <Link to={to} className={linkClasses}>{label}</Link>
    </li>
  )
}

export function NavTab({ onChange, value, children }: { onChange?: (value: string) => void; value: string; children: JSX.Element[] }) {
  const newChildren = React.Children.map(children, child => React.cloneElement(child, {
    activeValue: value,
    onChange
  }))
  return (
    <ul className='nav nav-tabs nav-overflow header-tabs'>
      {newChildren}
    </ul>
  )
}
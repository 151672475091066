// @flow
import { ancoAPI } from '../utils/http-client'

export function fetchBranches(userId = '') {
  return ancoAPI
    .get(`/api/branch`)
}

export function createBranch({ userId = '', ...formData }: {
  userId: string;
  name: string;
  address: string;
  neighborhood: string;
  city: string;
  zip: string;
  state: string;
  phone: string;
}) {
  return ancoAPI
    .post(`/api/branch`, formData)
}

export function fetchUsersByBranchId({ userId = '', branchId = '' }: { userId: string, branchId: string }) {
  return ancoAPI
    .get(`/api/branch/${branchId}/user`)
}

export function fetchEmployeesByBranchId({ userId = '', branchId = '' }: { userId: string, branchId: string }) {
  return ancoAPI
    .get(`/api/branch/${branchId}/employee`)
}


export function updateBranchSerial({ userId = '', serialprefix = '', branchId = '' }: { userId: string, serialprefix: string, branchId: string }): Promise<any> {
  return ancoAPI
    .put(`/api/branch/${branchId}`, {
      serialprefix
    })
}


export function assignUserToBranch({ userId = '', branchId = '', userIdToAssign = '' }: { userId: string, branchId: string, userIdToAssign: string }) {
  return ancoAPI
    .post(`/api/branch/${branchId}/user`, {
      user_id: userIdToAssign
    });
}

export function unassignUserFromBranch({
  userId = '',
  branchId = '',
  userBranchId = ''
}: { userId: string, branchId: string, userBranchId: string }) {
  return ancoAPI
    .delete(`/api/branch/${branchId}/user/${userBranchId}`)
}

export function assignEmployeeToBranch({ userId = '', branchId = '', employeeIdToAssign = '' }: { userId: string, branchId: string, employeeIdToAssign: string }) {
  return ancoAPI
    .post(`/api/branch/${branchId}/employee`, {
      employee_id: employeeIdToAssign
    })
}

export function unassignEmployeeFromBranch({
  userId = '',
  branchId = '',
  employeeBranchId = ''
}: { userId: string, branchId: string, employeeBranchId: string }) {
  return ancoAPI
    .delete(`/api/branch/${branchId}/employee/${employeeBranchId}`)
}

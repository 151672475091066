import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import HeaderImage from "../components/HeaderImage";
import { Table, TableBody, TableHead } from "../components/Table";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Input from "../components/Input";
import BranchesStore from "../stores/BranchesStore";
import BranchStore from "../stores/Branch";
import LeasesStore, { LeaseStore, LeaseStoreObjType } from "../stores/Leases";
import Select, { SelectOptions } from "../components/Select";
import { ClientStore } from "../stores/ClientsStore";
import { ProjectStore } from "../stores/ProjectsStore";
import dayjs from "dayjs";
import { cancelLease } from "../sources/lease";

const LeaseRow = inject(
  "leaseStore",
  "clientStore",
  "projectStore"
)(
  observer(
    ({
      lease,
      index,
      humanId,
      onDeleteClick,
      onDownload,
      onCancelLease,
    }: {
      leaseStore: LeaseStore;
      clientStore: ClientStore;
      projectStore: ProjectStore;
      lease: LeaseStoreObjType;
      index: number;
      humanId: string;
      onDeleteClick: () => void;
      onDownload: () => void;
      onCancelLease: () => void;
    }) => {
      const { id, clientName, projectName, branchName, requestor } = lease;
      return (
        <tr
          style={{
            backgroundColor: lease.canceled ? "lightgray" : "transparent",
          }}
        >
          <td>{index + 1}</td>
          <td>
            <div className="dropdown">
              <button
                className="btn btn-sm btn-info d-inline-block dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                data-expanded="false"
              >
                VER
              </button>
              <div className="dropdown-menu ">
                {!lease.canceled && (
                  <>
                    <Link
                      className="dropdown-item"
                      to={`/home/lease/${id}/details`}
                    >
                      Detalles
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={`/home/lease-form?id=${id}`}
                    >
                      Editar
                    </Link>

                    <button className="dropdown-item" onClick={onDownload}>
                      Generar Contrato
                    </button>
                  </>
                )}

                <button className="dropdown-item" onClick={onCancelLease}>
                  {lease.canceled ? "Reactivar" : "Cancelar"} Contrato
                </button>

                <div className="dropdown-divider"></div>

                <Link
                  className="dropdown-item text-danger"
                  to="/home/leases"
                  onClick={onDeleteClick}
                >
                  Borrar
                </Link>
              </div>
            </div>
          </td>
          <td>{humanId}</td>
          <td className="lease-name">{branchName}</td>
          <td className="lease-poryect">{projectName}</td>
          <td className="lease-client">{clientName}</td>
          <td className="lease-requestor">{requestor}</td>
        </tr>
      );
    }
  )
);

let searchThrottle: NodeJS.Timeout;

const Leases = inject(
  "leasesStore",
  "leaseStore",
  "branchesStore",
  "projectStore",
  "clientStore"
)(
  observer(
    ({
      branchesStore,
      branchStore,
      leaseStore,
      leasesStore,
      projectStore,
      clientStore,
    }: {
      branchesStore: BranchesStore;
      branchStore: BranchStore;
      leaseStore: LeaseStore;
      leasesStore: LeasesStore;
      projectStore: ProjectStore;
      clientStore: ClientStore;
    }) => {
      const [limit, setLimit] = useState<number>(50);
      const [selectedBranch, setSelectedBranch] = useState<string | undefined>(
        localStorage.getItem("defaultBranch") ?? undefined
      );

      useEffect(() => {
        leaseStore.restore();
        branchesStore.getBranches();
      }, []);

      useEffect(() => {
        if (branchesStore.allBranches.length > 0 && !selectedBranch)
          setSelectedBranch(branchesStore.allBranches[0]?.id);
      }, [branchesStore.allBranches, selectedBranch]);

      useEffect(() => {
        if (selectedBranch) {
          fetchLeases();
        }
      }, [selectedBranch, limit]);

      const fetchLeases = () => {
        leasesStore.getLeases({
          limit: limit,
          offset: 0,
          branchId: selectedBranch,
        });
      };
      const onRemove = (leaseId: string) => {
        leasesStore.deleteById(leaseId);
      };
      const onCancelLease = (leaseId: string, cancel: boolean) => {
        cancelLease(leaseId, cancel).then(({ data }) => {
          console.log("contrato cancelado", data);
          alert("Contrato " + (cancel ? "cancelado" : "reactivado"));
          fetchLeases();
        });
      };
      const onDownload = (lease: LeaseStoreObjType) => {
        console.log("onDownload", lease);
        leasesStore.downloadById(lease.id, lease.humanId);
      };
      const onSearchChange = ({ currentTarget }: { currentTarget: any }) => {
        const value = currentTarget.value;
        leasesStore.setSearchValue(value);
        if (searchThrottle) clearTimeout(searchThrottle);

        searchThrottle = setTimeout(filterLeases.bind(this, value), 600);
      };

      const filterLeases = (value?: string) => {
        leasesStore.getLeases({
          limit: 50,
          offset: 0,
          search: value || leasesStore.searchValue,
          branchId: selectedBranch,
        });
      };

      let leaseDate: Date;
      const renderLease = (lease: LeaseStoreObjType, index: number) => {
        let renderDateBlock = false;
        if (!leaseDate || !dayjs(leaseDate).isSame(lease.createdDate, "day")) {
          leaseDate = dayjs(lease.createdDate).toDate();
          renderDateBlock = true;
        }
        const rows = [];
        if (renderDateBlock) {
          rows.push(
            <tr
              key={dayjs(leaseDate).toDate().toTimeString()}
              className="col-12"
            >
              <td>
                <h4>{dayjs(leaseDate).format("DD MMM YY")}</h4>
              </td>
            </tr>
          );
        }
        rows.push(<LeaseRow
          key={lease.id}
          lease={lease}
          clientStore={clientStore}
          projectStore={projectStore}
          index={index}
          humanId={`${lease.serialprefix}-${lease.humanId}`}
          onDeleteClick={() => onRemove(lease.id)}
          onCancelLease={() => onCancelLease(lease.id, !lease.canceled)}
          onDownload={() => onDownload(lease)}
          leaseStore={leaseStore}
        />);

        return rows;
      };

      const rows = leasesStore.filteredLeases.map(renderLease);

      const options: SelectOptions[] = branchesStore.allBranches.map(
        (branch) => {
          return {
            label: branch.name,
            value: branch.id,
          };
        }
      );

      const onBranchSelection = (e: any) => {
        localStorage.setItem("defaultBranch", e.value);
        setSelectedBranch(e.value);
      };

      return (
        <div className="main-content">
          <HeaderImage to="/home/lease-form" title="Contratos" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <Select
                  label="Sucursal"
                  options={options}
                  value={selectedBranch}
                  onChange={onBranchSelection}
                />
              </div>
              <div className="col-12">
                <Input
                  label="Buscar contrato"
                  placeholder="Buscar..."
                  loading={leasesStore.fetchingLeases}
                  value={leasesStore.searchValue}
                  onChange={onSearchChange}
                />
              </div>
              <div className="col-12">
                <Table>
                  <TableHead>
                    <th>
                      <a href="#" className="text-muted">
                        #
                      </a>
                    </th>
                    <th>
                      <a href="#" className="text-muted">
                        Opciones
                      </a>
                    </th>
                    <th style={{ width: "5%" }}>
                      <a href="#" className="text-muted">
                        # Contrato
                      </a>
                    </th>
                    <th>
                      <a href="#" className="text-muted">
                        Sucursal
                      </a>
                    </th>
                    <th>
                      <a href="#" className="text-muted">
                        Proyecto
                      </a>
                    </th>
                    <th>
                      <a href="#" className="text-muted">
                        Cliente
                      </a>
                    </th>
                    <th>
                      <a href="#" className="text-muted">
                        Solicitante
                      </a>
                    </th>
                  </TableHead>
                  <TableBody>{rows}</TableBody>
                </Table>
                <div className="row justify-content-center">
                  {
                    <button
                      className={classNames({
                        btn: 1,
                        "btn-primary": 1,
                        disabled: leasesStore.fetchingLeases,
                      })}
                      onClick={() => {
                        setLimit((prevVal) => prevVal + 50);
                        filterLeases();
                      }}
                    >
                      Cargar Mas
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);

export default Leases;

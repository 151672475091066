import React, { useEffect, useState } from 'react'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { inject, observer } from 'mobx-react'
import InventoryStore from '../../../../stores/InventoryStore'
import SuppliersStore from '../../../../stores/SuppliersStore'
import { useHistory, useParams } from 'react-router-dom'
import { bindSelect, bindInput } from '../../../../utils/bindField'
import inventoryItemSource from '../../../../sources/inventoryItemSource'
import { File } from '../../../../components/File'
import { DayValue } from 'react-modern-calendar-datepicker'
import { ancoAPI } from '../../../../utils/http-client'
import { InventoryItemPhoto } from './Inventory.types'
import { buildUrl } from '../../../../utils/media'

export const UpdateInventory = inject('inventoryStore', 'suppliersStore')(observer(({
  inventoryStore,
  suppliersStore
}: {
  inventoryStore: InventoryStore;
  suppliersStore: SuppliersStore
}) => {
  const history = useHistory();
  const params = useParams<{
    inventoryItemId: string;
  }>();

  const [inventoryImage, setInventoryImage] = useState<any>();

  const fetchSuppliers = () => {

    return suppliersStore.getSuppliers()
  }

  const loadData = async () => {

    const { data } = await inventoryItemSource.fetchInventoryItemById(params.inventoryItemId)
    inventoryStore.form.resetAllFields();
    inventoryStore.form.setField('name', data.name)
    inventoryStore.form.setField('description', data.description)
    inventoryStore.form.setField('cost', data.cost)
    inventoryStore.form.setField('unit', data.unit)
    inventoryStore.form.setField('quantity', data.quantity)
    inventoryStore.form.setField('profitPercentage', data.profitPercentage)
    inventoryStore.form.setField('supplierId', data.supplier.id)
    setInventoryImage(data.photos);

  }

  useEffect(() => {

    loadData();
    fetchSuppliers()
  }, []);


  const onSubmit = async (event: any) => {

    event.preventDefault()
    console.log('submitting');

    try {
      const { data } = await inventoryItemSource.updateInventoryItem({
        inventoryItemId: params.inventoryItemId,
        supplierId: inventoryStore.form.getField('supplierId'),
        cost: inventoryStore.form.getField('cost'),
        name: inventoryStore.form.getField('name'),
        quantity: inventoryStore.form.getField('quantity'),
        unit: inventoryStore.form.getField('unit'),
        description: inventoryStore.form.getField('description'),
        profitPercentage: inventoryStore.form.getField('profitPercentage'),
      })
      console.log('data', data);

      history.goBack();
    } catch (e) {
      alert('Error al crear el inventario')
    }


  }
  const onCancel = () => {
    inventoryStore.restore()
  }

  const suppliersOptions = suppliersStore.suppliers.map(supplier => ({
    value: supplier.id,
    label: supplier.name
  }))


  return (
    <Form
      preTitle={`Nuevo inventario`}
      title='Agregar inventario'
      primaryButtonLabel={'Actualizar'}
      disableSubmit={!inventoryStore.form.isValid || inventoryStore.saving}
      isSubmitLoading={inventoryStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Select
          label='Provedor'
          options={suppliersOptions}
          {...bindSelect(inventoryStore.form, 'supplierId')}
        />

        <Input
          label='Nombre'
          {...bindInput(inventoryStore.form, 'name')}
        />

        <Input
          label='Descripcion'
          {...bindInput(inventoryStore.form, 'description')}
        />
        <Input
          label='Costo'
          {...bindInput(inventoryStore.form, 'cost')}
        />
        <Input
          label='Inventario'
          {...bindInput(inventoryStore.form, 'quantity')}
        />

        <Input
          label={'Unidad'}
          {...bindInput(inventoryStore.form, 'unit')}
        />
        <Input
          label={'Utilidad %'}
          {...bindInput(inventoryStore.form, 'profitPercentage')}
        />


        {inventoryImage && inventoryImage.map((image: InventoryItemPhoto) => {
          return <File label={'Foto inventario'} alt={''} src={buildUrl(image.url)}
            id={image.id}
            onDelete={() => {
              return ancoAPI
                .delete(`/api/inventory-item-photo/${params.inventoryItemId}/${image.id}`);
            }} />
        })}


        <File label={'Foto inventario'} alt={''} src={''}
          onSave={(files) => {
            const formData = new FormData();

            formData.append('file', files[0])

            ancoAPI
              .post(`/api/inventory-item-photo/${params.inventoryItemId}`, formData)
              .then(() => {
                loadData();
              });
          }} />
      </>
    </Form>
  )
}))

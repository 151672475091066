import { observable, action, makeObservable } from 'mobx'

export default class UiStore {
  @observable error = ''
  @observable errorDetails: string[] = []

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setError(message = '') {
    this.error = message
  }

  @action.bound
  handleError(message = '', details: string[] = []) {
    this.errorDetails = details
    return (error) => {
      this.setError(message)
      throw error
    }
  }

  @action.bound
  dismissModal() {
    this.error = ''
    this.errorDetails = []
  }
}

import React from 'react'

export default function EditEmployee() {
  const id = ''
  return (
    <div>
      {id}
    </div>
  )
}

import React, { ReactNode, useEffect, useReducer, useState } from "react";
import './SelectSearch.css'
export interface SelectSearchOption {
  value: string; label: string; meta: any;
}

export interface SelectSearchProps {
  options: SelectSearchOption[];
  label: string;
  value?: string;
  renderItem: (option: SelectSearchOption) => ReactNode;
  onSelected: (option: SelectSearchOption) => string;
  onSearch?: (text: string) => void;
  loading?: boolean;
}

const SelectSearch: React.FC<SelectSearchProps> = ({
  options,
  label,
  onSelected,
  renderItem,
  onSearch,
  loading,
  value: initialValue,
}) => {

  // const [showOptions, setShowOptions] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>(initialValue);

  useEffect(() => {
    if (initialValue)
      setValue(initialValue)
  }, [initialValue]);

  const [showOptions, dispatch] = useReducer((state: { show: boolean; }, action: string) => {
    state.show = action === 'show';
    return state;
  }, {
    show: false
  });

  return <div className="form-group" style={{
    position: 'relative',
    width: "100%",
  }}>
    <span style={{
      marginBottom: '20px',
    }}>{label}</span>
    <input type="text" value={value} onChange={(e) => {
      onSearch && onSearch(e.target.value)
      setValue(e.target.value)
    }} onFocus={() => {
      dispatch('show')
    }} onBlur={() => {
      dispatch('hide')
    }} className="form-control" placeholder="Buscar" style={{
      width: '100%',
    }} />
    {showOptions.show && !loading && <div style={{
      position: 'absolute',
      top: "65px",
      maxHeight: '300px',
      width: '100%',
      zIndex: 999,
      border: '1px solid lightgray',
      borderRadius: 8,
      overflow: 'scroll',
    }}>
      {options.map((option) => <div key={option.meta.id} onClick={() => {
        // setShowOptions(false)
        dispatch('hide')
        const valueVal = onSelected(option);
        setValue(valueVal);
      }}>{renderItem(option)}</div>)}
    </div>}

    {loading && <div className="loader" role="status" />}

  </div>
}

export default SelectSearch;
import { Project } from '../stores/types/project.types'
import request, { ancoAPI } from '../utils/http-client'

export function createProject({
  clientId,
  name,
  address,
  neighborhood,
  supervisor,
  zip,
  city,
  state
}: {
  clientId?: string,
  name: string,
  address: string,
  neighborhood: string,
  supervisor: string,
  zip: string,
  city: string,
  state: string
}) {
  return ancoAPI
    .post('/api/projects', {
      name,
      address,
      neighborhood,
      supervisor,
      zip,
      city,
      state,
      client_id: clientId
    })
}

export function fetchProjects({ limit = 30, search = '', clientId = '' }) {
  return ancoAPI
    .get<Project[]>(`/api/projects?limit=${limit}&search=${search}&clientId=${clientId}`)
}

export function updateProject({
  projectId,
  clientId,
  name,
  address,
  neighborhood,
  supervisor,
  zip,
  city,
  state
}) {
  return ancoAPI
    .put(`/api/projects/${projectId}`, {
      name,
      address,
      neighborhood,
      supervisor,
      zip,
      city,
      state,
      client_id: clientId
    })
}

export function deleteProject(projectId: string) {
  return ancoAPI
    .delete(`/api/projects/${projectId}`)
}

export function fetchProject(projectId: string) {
  return ancoAPI
    .get<Project>(`/api/projects/${projectId}`)
}

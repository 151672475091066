// @flow
import saveAs from 'file-saver'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchExtensions,
  createExtension,
  removeExtension,
  printNote
} from '../sources/extension'
import Form from './Form'
import { isRequired, isInt } from '../utils/validator'
import { LeaseStore } from './Leases'
import { LeaseEquipmentStore } from './LeaseEquipmentsStore'
import UiStore from './UiStore'
import { LeaseEquipmentExtension } from './types/leaseEquipmentExtension.types'

export class ExtensionStore {
  @observable id = ''
  @observable saving = false
  private uiStore: UiStore;
  private leaseEquipmentStore: LeaseEquipmentStore;
  private leaseStore: LeaseStore;
  public form: Form<{
    equipmentId: '',
    cost: '',
    days: ''
  }>

  constructor({ leaseStore, leaseEquipmentStore, uiStore }: { leaseStore: LeaseStore; leaseEquipmentStore: LeaseEquipmentStore; uiStore: UiStore; }) {
    makeObservable(this);
    this.form = new Form({
      equipmentId: '',
      cost: '',
      days: ''
    }, {
      equipmentId: isRequired(),
      cost: [
        isRequired(),
        isInt()
      ],
      days: [
        isRequired(),
        isInt()
      ]
    })
    this.uiStore = uiStore
    this.leaseEquipmentStore = leaseEquipmentStore
    this.leaseStore = leaseStore
  }

  create() {
    const { equipmentId, cost, days } = toJS(this.form.fields)
    const { id: leaseId } = this.leaseStore
    const { id: leaseEquipmentId } = this.leaseEquipmentStore

    return createExtension({
      leaseId,
      equipmentId,
      leaseEquipmentId,
      cost: parseInt(cost, 10),
      days: parseInt(days, 10)
    })
      .catch(this.uiStore.handleError('Error al crear extension de contrato para equipo'))
  }

  @action.bound
  save() {
    this.saving = true
    return this.create()
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export class ExtensionsStore {
  @observable extensions: LeaseEquipmentExtension[] = []
  private leaseEquipmentStore: LeaseEquipmentStore;
  private leaseStore: LeaseStore;
  private uiStore: UiStore;

  constructor({ leaseStore, leaseEquipmentStore, uiStore }: { leaseStore: LeaseStore; leaseEquipmentStore: LeaseEquipmentStore; uiStore: UiStore; }) {
    this.leaseEquipmentStore = leaseEquipmentStore
    this.leaseStore = leaseStore
    this.uiStore = uiStore
  }

  @action.bound
  removeById(id = '') {
    const { id: leaseId } = this.leaseStore
    const { id: leaseEquipmentId } = this.leaseEquipmentStore
    const extensionIndex = this.extensions.findIndex(extension => extension.id === id)

    if (extensionIndex < 0) return

    return removeExtension({ leaseId, leaseEquipmentId, extensionId: id })
      .then(() => {
        this.extensions = [
          ...this.extensions.slice(0, extensionIndex),
          ...this.extensions.slice(extensionIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar extension de contrato para equipo'))
  }

  @action.bound
  printNote(id = '') {
    const { id: leaseId } = this.leaseStore
    const { id: leaseEquipmentId } = this.leaseEquipmentStore
    const extensionIndex = this.extensions.findIndex(extension => extension.id === id)

    if (extensionIndex < 0) return
    const error = 'Error al generar contrato, asegurese que los siguientes datos esten correctos:'
    return printNote({ leaseId, leaseEquipmentId, extensionId: id })
      .then((response) => {
        saveAs(response.data, 'extension_nota_remision_' + leaseEquipmentId + '.pdf')
      })
      .catch(this.uiStore.handleError(error, [
        'Informacion del cliente',
        'Informacion del proyecto',
        'Sucursal',
        'Equipos de renta en el contrato'
      ]))
  }

  @action.bound
  getExtensions({
    leaseId,
    leaseEquipmentId
  }: {
    leaseId: string;
    leaseEquipmentId: string;
  }) {

    return fetchExtensions({ leaseId, leaseEquipmentId })
      .then(({ data }) => {
        console.log("fetchExtensions", data)
        const extensions = data
        this.extensions = extensions
      })
      .catch(this.uiStore.handleError('Error al obtener extensiones de contrato para equipo'))
  }

  @computed get allExtensions() {
    return this.extensions
  }
}

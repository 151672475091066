import React from 'react'

import { Link, match, useHistory } from 'react-router-dom'
import { Client } from '../../../stores/types/client.types'
import moment from 'moment'
import { DocumentType } from '../../../stores/Documents'
import dayjs from 'dayjs'
const ClientRow = ({
  match,
  onRemove,
  onClientClick,

  client }: {
    match: match<{}>
    onClientClick: (data: any) => void;
    onRemove: () => void;
    client: Client
  }) => {
  const {
    id,
    name,
    email,
    phone,
    address,
    neighborhood,
    city,
    state,
    created_date,
    projects,
    leases,
    documents,
  } = client;

  const history = useHistory();

  const proofOfAddress = documents.find((document) => {
    return document.document_type === DocumentType.ADDRESS_PROOF;
  })

  const proofOfAddressExpired = dayjs().isAfter(dayjs(proofOfAddress?.expire_date).add(6, 'month'))
  return (
    <div className='card mb-3'>
      <div className='card-body'>
        <div className='row align-items-center'>
          <div className='col-auto'>
            <h4 className='card-title mb-1'>
              {name}
            </h4>
          </div>
          <div className='col-auto'>
            <p className='card-text small text-muted mb-1'>
              {email}
            </p>
          </div>
          <div className='col-auto'>
            <p className='card-text small text-muted mb-1'>
              {phone}
            </p>
          </div>
          <div className='col-auto'>
            <p className='card-text small text-muted mb-1'>
              {moment(created_date).format('DD/MMM/YY')}
            </p>
          </div>

        </div>
        <div className='row align-items-center'>
          <div className='col-auto'>
            <button
              onClick={() => {
                history.push(`${match.url}/${id}/documents`)
                onClientClick({
                  id,
                  name,
                  email,
                  phone,
                  address,
                  neighborhood,
                  city,
                  state
                })
              }}
              //to={`${match.url}/${id}/documents`}
              className='btn text-white btn-sm btn-info d-inline-block'
            >
              Ver documentos
            </button>
          </div>
          <div className='col-auto'>
            <Link
              onClick={() => onClientClick({
                id,
                name,
                email,
                phone,
                address,
                neighborhood,
                city,
                state
              })}
              to={`${match.url}/client-form`}
              className='btn btn-sm btn-info d-inline-block'
            >
              Editar
            </Link>
          </div>
          <div className='col-auto'>
            <button
              onClick={() => {
                if (leases.length > 0 || projects.length > 0) {
                  let errorMsg = ''
                  if (leases.length) {
                    errorMsg += ` ${leases.length} contrato(s)`
                  }

                  if (errorMsg) errorMsg += ','

                  if (projects.length) {
                    errorMsg += ` ${projects.length} proyecto(s)`
                  }

                  return alert('No se puede borrar el cliente por que fue usado en: ' + errorMsg)
                }
                onRemove()
              }}
              className='btn btn-sm btn-danger d-inline-block'
            >
              Borrar
            </button>


          </div>

        </div>
        <div className='' style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start'
        }}>
          <div>
            <strong>Proyectos:</strong>{projects.length}
          </div>
          <div>
            <strong>Contratos:</strong>{leases.length}
          </div>
          <strong>Documentos:</strong>{documents.length}
        </div>
        <div>
          {proofOfAddressExpired ? <span className='badge badge-danger' style={{
          }}>Comprobante de domicilio Expirado</span> : null}
        </div>
      </div>

    </div>
  )

}

export default ClientRow

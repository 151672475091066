import { observable, action, computed, makeObservable } from 'mobx'
import {
  fetchDocuments,
  uploadFile
} from '../sources/document'
import { ClientStore } from './ClientsStore';
import UiStore from './UiStore';

export enum DocumentType {
  IFE_BACK = 'IFE_BACK',
  IFE_FRONT = 'IFE_FRONT',
  ADDRESS_PROOF = 'ADDRESS_PROOF',
  CONSTITUTIVE_ACT = 'CONSTITUTIVE_ACT',
  IFE_LEGAL_REPRESENTATIVE = 'IFE_LEGAL_REPRESENTATIVE',
}

export default class DocumentsStore {
  @observable documents: { document_type: DocumentType; document_url: string; expire_date?: Date; }[] = []
  private clientStore: ClientStore;
  private uiStore: UiStore;
  constructor({ uiStore, clientStore }: { uiStore: UiStore; clientStore: ClientStore; }) {
    this.clientStore = clientStore
    this.uiStore = uiStore;
    makeObservable(this);
  }

  @action.bound
  getDocuments(id: string) {
    return fetchDocuments(id)
      .then(({ data }) => {
        const documents = data
        this.documents = documents
      })
      .catch(id ? this.uiStore.handleError('Error al obtener documentos') : null)
  }

  uploadFile(id: string, files: File[], type: DocumentType, expireDate: string | undefined = undefined, progress = (p: number) => console.log("progress", p)) {

    return uploadFile({
      clientId: id,
      file: files[0],
      type: type,
      expireDate,
      progress
    })
      .then(() => {

        this.getDocuments(id).then(() => {
          progress && progress(0)//Reset Progress  
        })

      })
  }

  uploadIfeBack(id: string, files: File[], progress: () => void) {
    return this.uploadFile(id, files, DocumentType.IFE_BACK, undefined, progress)
      .catch(this.uiStore.handleError('Error al guardar IFE parte posterior'))
  }

  uploadIfeFront(id: string, files: File[], progress: () => void) {
    return this.uploadFile(id, files, DocumentType.IFE_FRONT, undefined, progress)
      .catch(this.uiStore.handleError('Error al guardar IFE parte frontal'))
  }

  uploadAddressProof(id: string, files: File[], progress: () => void, expireDate?: string) {
    return this.uploadFile(id, files, DocumentType.ADDRESS_PROOF, expireDate, progress)
      .catch(this.uiStore.handleError('Error al guardar comprobante de domicilio'))
  }
  //CONSTITUTIVE_ACT (ConstitutiveAct)
  uploadConstitutiveAct(id: string, files: File[], progress: () => void) {
    return this.uploadFile(id, files, DocumentType.CONSTITUTIVE_ACT, undefined, progress)
      .catch(this.uiStore.handleError('Error al guardar acta constitutiva'))
  }
  //IFE_LEGAL_REPRESENTATIVE
  uploadIFELegalRepresentative(id: string, files: File[], progress: () => void) {
    return this.uploadFile(id, files, DocumentType.IFE_LEGAL_REPRESENTATIVE, undefined, progress)
      .catch(this.uiStore.handleError('Error al guardar IFE de representante legal'))
  }
  @computed get documentsMap() {
    return this.documents.reduce((map, doc) => {
      return Object.assign(map, {
        [doc.document_type]: {
          url: doc.document_url,
          expire_date: doc.expire_date,
        }
      })
    }, {} as { [key in DocumentType]: {
      url: string;
      expire_date?: Date;
    } | undefined; })
  }
}

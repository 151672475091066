import React, { useCallback } from 'react'
import { inject, useObserver } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import { useHistory } from 'react-router-dom'
import { UserStore } from '../stores/Users'

const NewUser = inject('userStore')(({ userStore }: {
  userStore: UserStore
}) => {

  const history = useHistory();
  const onSubmit = useCallback((event: any) => {
    event.preventDefault()
    // TODO: Validate form is okay
    if (userStore.isPasswordOkay) {
      userStore
        .create()
        .then(() => {
          // CHECK: redirect to users so you can see new user you created
          history.push('/home/users')
        })
    }
  }, [history, userStore])

  const onCancel = useCallback(() => {
    userStore.restore()
  }, [userStore])

  const bindPassword = useCallback((fieldName: string) => {
    let error = ''
    if (!userStore.isPasswordOkay) {
      error = 'Las contrasenas deben coincidir'
    }
    return {
      error: userStore.form.getFieldError(fieldName) || error,
      value: userStore.form.getField(fieldName),
      onChange({ target }: { target: HTMLInputElement }) {
        userStore.form.setField(fieldName, target.value)
      },
      onBlur() {
        userStore.form.validateField(fieldName)
      }
    }
  }, [userStore])

  return useObserver(() => (
    <Form
      preTitle='Usuarios'
      title='Crear nuevo usuario'
      primaryButtonLabel='Crear usuario'
      disableSubmit={!userStore.formValid || userStore.saving}
      isSubmitLoading={userStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label='Nombre(s)'
          {...bindInput(userStore.form, 'firstName')}
        />
        <Input
          label='Apellidos'
          {...bindInput(userStore.form, 'lastName')}
        />
        <Input
          type='email'
          label='Correo electronico'
          {...bindInput(userStore.form, 'email')}
        />
        <Input
          type='password'
          label='Contraseña'
          {...bindPassword('firstPassword')}
        />
        <Input
          type='password'
          label='Repetir contraseña'
          {...bindPassword('secondPassword')}
        />
      </>
    </Form>
  ))
})

export default NewUser


import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'mobx-react'
import createAppStore from './js/stores/createAppStore'
import Login from './js/pages/Login'
import Home from './js/pages/Home'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { ErrorModal } from './js/components/Modal'
//@ts-ignore
String.prototype.capitalize = function (notrim) {
  let s = notrim ? this : this.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '').replace(/\s+/g, ' ');
  return s.length > 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s;
}



function App() {
  return (
    <Provider {...createAppStore()}>
      <React.Fragment>
        <ErrorModal />
        <Router >
          <Switch>
            <Route path='/login' exact component={Login} />
            <Route path='/home' component={Home} />
            <Route path='/' exact component={() => <Redirect to='/home/leases' />} />
          </Switch>
        </Router>
      </React.Fragment>
    </Provider>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);



export default function Checkbox({
  checked = false,
  onChange = (e: any) => { },
  label = ''
}) {
  return (
    <div className='form-group'>
      <label>
        {label}
      </label>
      <div className='custom-control custom-checkbox-toggle'>
        <input
          type='checkbox'
          className='custom-control-input'
          id='exampleToggle'
          checked={checked}
          onChange={onChange}
        />
        <label className='custom-control-label' htmlFor='exampleToggle' />
      </div>
    </div>
  )
}

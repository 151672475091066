import request, { ancoAPI } from '../utils/http-client'

export function fetchDetails({ userId = '', maintenanceId = '' }) {
  return ancoAPI
    .get(`/api/user/${userId}/maintenance/${maintenanceId}/details`)
}

export function createDetail({
  userId = '',
  maintenanceId = '',
  qty = 0,
  employeeId = '',
  inventoryId = ''
}) {
  return ancoAPI
    .post(`/api/user/${userId}/maintenance/${maintenanceId}/details`, {
      qty,
      employee_id: employeeId,
      supply_inventory_id: inventoryId
    })
}

export function removeDetail({ userId = '', maintenanceId = '', detailId = '' }) {
  return ancoAPI
    .delete(`/api/user/${userId}/maintenance/${maintenanceId}/details/${detailId}`)
}

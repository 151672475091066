import React, { useCallback } from 'react'
import { inject, useObserver } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import { SupplyStore } from '../stores/SuppliesStore'
import { useHistory } from 'react-router-dom'

const Supply = inject('supplyStore')(({ supplyStore }: {
  supplyStore: SupplyStore
}) => {
  const history = useHistory();
  const onSubmit = useCallback((event) => {
    event.preventDefault()
    // TODO: Validate form is okay
    supplyStore
      .save()
      .then(() => {
        // CHECK: redirect to employees so you can see new user you created
        history.push('/home/supplies')
      })
  }, [history, supplyStore])

  const onCancel = useCallback(() => {
    supplyStore.restore()
  }, [supplyStore])

  const { isNew } = supplyStore
  const buttonLabel = isNew ? 'Crear material' : 'Editar material'
  const title = isNew ? 'Crear nuevo material' : 'Actualizar material'

  return useObserver(() => (
    <Form
      preTitle='Materiales'
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!supplyStore.form.isValid || supplyStore.saving}
      isSubmitLoading={supplyStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label='Nombre'
          {...bindInput(supplyStore.form, 'name')}
        />
        <Input
          label='Unidad'
          {...bindInput(supplyStore.form, 'unit')}
        />
        <Input
          label='Descripcion'
          {...bindInput(supplyStore.form, 'description')}
        />
      </>
    </Form>
  ))
})

export default Supply

import React from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput } from '../../../utils/bindField'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import InputMask from '../../../components/InputMask'
import { useHistory } from 'react-router-dom'
import { ClientStore } from '../../../stores/ClientsStore'


const Client = inject('clientStore')(observer(({ clientStore }: { clientStore: ClientStore }) => {
  const history = useHistory();
  const onSubmit = (event: any) => {

    event.preventDefault()
    clientStore
      .save()
      .then(() => {
        // CHECK: redirect to employees so you can see new user you created
        history.push('/home/clients')
      })
  }
  const onCancel = () => {
    clientStore.restore()
  }

  const { isNew } = clientStore
  const buttonLabel = isNew ? 'Crear cliente' : 'Editar cliente'
  const title = isNew ? 'Crear nuevo cliente' : 'Actualizar cliente'

  return (
    <Form
      preTitle='Clientes'
      title={title}
      primaryButtonLabel={buttonLabel}
      disableSubmit={!clientStore.form.isValid || clientStore.saving}
      isSubmitLoading={clientStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label='Nombre'
          {...bindInput(clientStore.form, 'name')}
        />
        <Input
          label='Correo'
          type='email'
          {...bindInput(clientStore.form, 'email')}
        />
        <InputMask
          label='Telefono'
          mask='(000) 000-0000'
          {...bindInput(clientStore.form, 'phone')}
        />
        <Input
          label='Direccion'
          {...bindInput(clientStore.form, 'address')}
        />
        <Input
          label='Colonia'
          {...bindInput(clientStore.form, 'neighborhood')}
        />
        <Input
          label='Ciudad'
          {...bindInput(clientStore.form, 'city')}
        />
        <Input
          label='Estado'
          {...bindInput(clientStore.form, 'state')}
        />
      </>
    </Form>
  )
}));

export default Client

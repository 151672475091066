import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import HeaderImage from '../components/HeaderImage'
import { Table, TableBody, TableHead } from '../components/Table'
import { Link } from 'react-router-dom'
import { EmployeeStore, EmployeesStore } from '../stores/EmployeesStore'

const Employee = inject('employeeStore')(observer(({ employeeStore, id, firstName, lastName, name, salary, onDeleteClick }:
  {
    employeeStore?: EmployeeStore; id: string; firstName: string;
    lastName: string; salary: number; name: string; onDeleteClick: () => void;
  }) => {

  const onEditClick = (employeeData: any) => {
    employeeStore?.loadData(employeeData)
  }
  return (
    <tr>
      <td className='employee-name'>
        {name}
      </td>
      <td className='employee-salary'>
        {salary}
      </td>
      <td>
        <Link
          className='btn btn-sm btn-info d-none d-md-inline-block'
          to={`/home/employee?id=${id}`}
          onClick={() => onEditClick({ id, firstName, lastName, salary })}
        >
          Editar
        </Link>
      </td>
      <td>
        <Link
          className='btn btn-sm btn-danger d-none d-md-inline-block'
          to={`/home/employees`}
          onClick={onDeleteClick}
        >
          Borrar
        </Link>
      </td>
    </tr>
  )
}));

const Employees = inject('employeesStore')(observer(({
  employeesStore
}: {
  employeesStore: EmployeesStore;
}) => {

  useEffect(() => {

    fetchEmployees()
  }, []);

  const fetchEmployees = () => {
    employeesStore?.getEmployees()
  }
  const onRemove = (employeeId: string) => {
    employeesStore.deleteEmployeeById(employeeId)
  }


  return (
    <div className='main-content'>
      <HeaderImage to='/home/employee' title='Empleados' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Table>
              <TableHead>
                <th>
                  <a href='#' className='text-muted sort' data-sort='employee-name'>
                    Nombre
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='employee-salary'>
                    Salario
                  </a>
                </th>
                <th />
              </TableHead>
              <TableBody>
                {employeesStore.allEmployees.map(employee => <Employee key={employee.id} {...employee} onDeleteClick={() => onRemove(employee.id)} />)}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}));

export default Employees

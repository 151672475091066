import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput, bindSelect } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import Select from '../components/Select'
import SuppliersStore from '../stores/SuppliersStore'
import InventoryStore from '../stores/InventoryStore'
import { useHistory } from 'react-router-dom'
import { SupplyStore } from '../stores/SuppliesStore'


const SupplyInfoForm = inject('inventoryStore', 'supplyStore', 'suppliersStore')(observer(({
  suppliersStore,
  inventoryStore,
  supplyStore,
}: {
  suppliersStore: SuppliersStore;
  inventoryStore: InventoryStore;
  supplyStore: SupplyStore;
}) => {

  const history = useHistory();

  useEffect(() => {

    fetchSuppliers();
  }, []);
  const fetchSuppliers = () => {
    return suppliersStore.getSuppliers()
  }
  const onSubmit = (event) => {

    event.preventDefault()
    // TODO: Validate form is okay
    // inventoryStore
    //   .create()
    //   .then(() => {
    //     // CHECK: redirect to employees so you can see new user you created
    //     history.push(`/home/supply/${supplyStore.id}/inventory`)
    //   })
  }
  const onCancel = () => {
    inventoryStore.restore()
  }


  const suppliersOptions = suppliersStore.suppliers.map(supplier => ({
    value: supplier.id,
    label: supplier.name
  }))
  return (
    <Form
      preTitle={`${supplyStore.form.getField('name')} inventario`}
      title='Agregar inventario'
      primaryButtonLabel={'Agregar inventario'}
      disableSubmit={!inventoryStore.form.isValid || inventoryStore.saving}
      isSubmitLoading={inventoryStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Select
          label='Provedor'
          options={suppliersOptions}
          {...bindSelect(inventoryStore.form, 'supplierId')}
        />
        <Input
          label='Costo'
          {...bindInput(inventoryStore.form, 'cost')}
        />
        <Input
          label={'Cantidad(' + supplyStore.form.getField('unit') + ')'}
          {...bindInput(inventoryStore.form, 'qty')}
        />
      </>
    </Form>
  )

}));

export default SupplyInfoForm

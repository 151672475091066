import { AxiosResponse } from 'axios'
import { ancoAPI } from '../utils/http-client'
import { LeaseEquipment } from '../stores/types/leaseEquipment.types'

export function createLeaseEquipment({ leaseId, equipmentId, cost, unitQty, timeUnit, qty, initialHours }: {
  leaseId: string;
  equipmentId: string;
  cost: number;
  unitQty: number;
  timeUnit: string;
  qty: number;
  initialHours: number;
}) {
  return ancoAPI
    .post(`/api/lease-equipment/${leaseId}`, {
      equipment_id: equipmentId,
      cost,
      qty,
      unit_qty: unitQty,
      time_unit: timeUnit,
      initial_hours: initialHours
    })
}

export function fetchLeaseEquipments(leaseId: string): Promise<AxiosResponse<LeaseEquipment[]>> {
  return ancoAPI
    .get(`/api/lease-equipment/${leaseId}`)
}

export function fetchLeaseEquipmentById(leaseId: string, leaseEquipmentId: string): Promise<AxiosResponse<LeaseEquipment>> {
  return ancoAPI
    .get(`/api/lease-equipment/${leaseId}/equipment/${leaseEquipmentId}`)
}

export function deleteLeaseEquipment({ leaseId, leaseEquipmentId }: { leaseId: string; leaseEquipmentId: string }) {
  return ancoAPI
    .delete(`/api/lease-equipment/${leaseId}/delete/${leaseEquipmentId}`)
}

export function returnEquipment({ leaseId, leaseEquipmentId, finalHours, isScaffold }: { leaseId: string; leaseEquipmentId: string; finalHours: number; isScaffold: boolean; }) {
  const url = `/api/lease-equipment/${leaseId}/return/${leaseEquipmentId}` + (isScaffold ? '/scaffold' : '')
  return ancoAPI
    .post(url, {
      final_hours: finalHours
    })
}

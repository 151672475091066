import React, { useEffect, useState } from 'react'
import { Table } from '../../../../components/Table'
import HeaderImage from '../../../../components/HeaderImage'
import { match, useHistory } from 'react-router-dom'
import { ancoAPI } from '../../../../utils/http-client'
import dayjs from 'dayjs'
import { ClientMachine } from './ClientMachine.types'

export const ClientMachines = ({ match }: { match: match }) => {
  const history = useHistory();
  const [clientMachines, setClientMachines] = useState<ClientMachine[]>([]);

  const getInventory = async () => {
    const { data } = await ancoAPI.get('/api/client-machine');
    console.log('data', data);
    setClientMachines(data);

  }
  useEffect(() => {

    getInventory();
  }, []);

  const onDelete = async (clientMachine: ClientMachine) => {
    await ancoAPI.delete(`/api/client-machine/${clientMachine.id}`);
    getInventory();
  }
  const onEdit = async (clientMachine: ClientMachine) => {
    history.push(`${match.url}/update-client-machine/${clientMachine.id}`)
  }
  return (
    <div>
      <HeaderImage title='Maquinaria de cliente' to={`${match.url}/create-client-machine`} />
      <Table>
        <thead>
          <tr>

            <th>Name</th>
            <th>Descripción</th>
            <th>Identificador</th>

            <th>QR Code</th>
            <th>Fecha de creacion</th>
          </tr>
        </thead>
        <tbody>
          {clientMachines.map((clientMachine, index) => {
            return <tr key={clientMachine.id}>
              <td>{clientMachine.name}</td>
              <td>{clientMachine.description}</td>
              <td>{clientMachine.identifier}</td>
              <td><img alt={'Codigo QR de la maquina'} height={25} width={25} src={clientMachine.qrCode} /></td>
              <td>{dayjs(clientMachine.created_date).format('DD/MM/YYYY')}</td>
              <td>
                <button className='btn btn-sm btn-primary' onClick={() => onEdit(clientMachine)}>Editar</button>
                <button className='btn btn-sm btn-danger' onClick={() => onDelete(clientMachine)}>Borrar</button>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import HeaderImage from '../components/HeaderImage'
import { Table, TableBody, TableHead } from '../components/Table'
import ClientCard from '../components/ClientCard'
import ProjectCard from '../components/ProjectCard'
import { mapTimeUnit } from './LeaseEquipmentForm'
import numeral from 'numeral'
import { fetchLeaseById } from '../sources/lease'
import { LeaseEquipmentStore, LeaseEquipmentStoreEquipment, LeaseEquipmentsStore } from '../stores/LeaseEquipmentsStore'
import LeasesStore, { LeaseStore } from '../stores/Leases'
import { EquipmentStore } from '../stores/EquipmentsStore'
import { ClientStore } from '../stores/ClientsStore'
import { ProjectStore } from '../stores/ProjectsStore'
import { Lease } from '../stores/types/lease.types'

const EquipmentRow = inject('leaseEquipmentStore')(({
  id,
  equipmentId,
  equipmentName,
  cost,
  timeUnit,
  unitQty,
  qty,
  initialHours,
  finalHours,
  onRemoveClick,
  leaseId,
  scaffold,
  returned,
  leaseEquipmentStore }: {
    id: string;
    equipmentId: string;
    equipmentName: string;
    cost: number;
    timeUnit: string;
    unitQty: number;
    qty: number;
    scaffold: boolean;
    returned: boolean;
    initialHours?: number;
    finalHours?: number;
    onRemoveClick: () => void;
    leaseId: string;
    leaseEquipmentStore: LeaseEquipmentStore
  }) => {


  return (
    <tr style={{
      backgroundColor: returned ? '#1BEC6D' : 'transparent',
    }}>
      <td className='lease-details-machine'>
        {equipmentName}
      </td>
      <td className='lease-details-cost'>
        {cost}
      </td>
      <td className='lease-details-timeunit'>
        {unitQty}
      </td>
      <td className='lease-details-qty'>
        {qty}
      </td>
      <td className='lease-details-hours-initial'>
        {scaffold ? 'N/A' : initialHours}
      </td>
      <td className='lease-details-hours-return'>
        {scaffold ? 'N/A' : finalHours == null ? "Pendiente" : finalHours}
      </td>
      <td>
        <div className='dropdown'>
          <a href='#!' className='btn btn-info dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' data-expanded='false'>
            VER
          </a>
          <div className='dropdown-menu dropdown-menu-right'>
            {finalHours == null && <Link
              to={`/home/lease/${leaseId}/equipment/${id}/return-form?scaffold=${scaffold}`}
              className='dropdown-item'
              onClick={() => leaseEquipmentStore.loadData({
                id,
                equipmentId
              })}
            >
              Entregar equipo
            </Link>}
            <Link
              to={`/home/lease/${leaseId}/equipment/${id}/details`}
              className='dropdown-item'
              onClick={() => leaseEquipmentStore.loadData({
                id,
                equipmentName,
                equipmentId,
                cost,
                timeUnit,
                unitQty,
                qty
              })}
            >
              Ver extensiones
            </Link>
            <div className="dropdown-divider"></div>
            <a href='#!' className='dropdown-item text-danger' onClick={onRemoveClick}>
              Borrar
            </a>
          </div>
        </div>
      </td>
    </tr>
  )
})


const LeaseDetails = inject(
  'leaseStore',
  'leaseEquipmentsStore',
  'leaseEquipmentStore',
  'equipmentStore',
  'clientStore',
  'projectStore',
  'leasesStore'
)(observer(({
  leaseStore,
  leaseEquipmentsStore,
  leaseEquipmentStore,
  equipmentStore,
  clientStore,
  projectStore,
  leasesStore
}: {
  leaseStore: LeaseStore;
  leaseEquipmentsStore: LeaseEquipmentsStore;
  leaseEquipmentStore: LeaseEquipmentStore;
  equipmentStore: EquipmentStore;
  clientStore: ClientStore;
  projectStore: ProjectStore;
  leasesStore: LeasesStore;
}) => {
  const [lease, setLease] = useState<Lease>();
  const params = useParams<{ leaseId: string; }>();
  let totalLease = 0;


  useEffect(() => {

    leaseEquipmentStore.restore()
    equipmentStore.restore()
    let { leaseId } = params
    leaseEquipmentsStore.getEquipments(leaseId)
    fetchLeaseById({ leaseId }).then(({ data }) => {
      onLoadData(data)
    })
  }, []);

  const onLoadData = (lease: Lease) => {
    clientStore.setId(lease.client.id)
    projectStore.setId(lease.project.id)
    setLease(lease);
  }

  useEffect(() => {

    if (lease) {
      leaseStore.loadData({
        id: lease.id,
        clientId: lease.client.id,
        projectId: lease.project.id,
        branchId: lease.branch.id,
        requestor: lease.requestor,
        noTax: lease.no_tax,
        humanId: lease.human_id,
        deliveryDate: lease.delivery_date,
        receivedDate: lease.received_date
      })
    }
  }, [lease, leaseStore]);


  const onRemove = ({ id }: { id: string }) => {

    leaseEquipmentsStore.removeById(id)
  }
  const onDownloadLease = () => {
    leasesStore.downloadById(lease!.id, lease!.human_id)
  }

  const onDownloadLeaseNote = () => {
    leasesStore.downloadNoteById(lease!.id, lease!.human_id)
  }

  const renderEquipment = (equipment: LeaseEquipmentStoreEquipment) => {
    totalLease += ((equipment.cost) * (equipment.qty) * (equipment.unitQty))
    return <EquipmentRow key={equipment.id} leaseEquipmentStore={leaseEquipmentStore} {...equipment} onRemoveClick={() => onRemove(equipment)} />
  }


  let timeUnit: string | undefined = 'Dia'
  totalLease = 0;
  if (leaseEquipmentsStore.allEquipments.length > 0) {
    timeUnit = mapTimeUnit(leaseEquipmentsStore.allEquipments[0].timeUnit)
  }
  const equipmentRows = leaseEquipmentsStore.allEquipments.map(renderEquipment)

  let noTax = leaseStore.form.getField("noTax")
  let taxRate = noTax ? 0 : 0.16

  return (
    <div className='main-content'>
      <HeaderImage
        title='Detalles de contrato'
        buttonLabel='Agregar'
        to={`/home/lease/${leaseStore.id}/equipment-form`}
      />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-xl-8'>
            <div>
              <ClientCard clientStore={clientStore} client={lease?.client} />
              <ProjectCard projectStore={projectStore} project={lease?.project} />
              <div style={{
                float: 'right',
                fontSize: 28
              }} >

                <span style={{
                  fontWeight: "800"
                }}>Subtotal </span>
                <span >{totalLease}</span>

                <span style={{
                  fontWeight: "800"
                }}> IVA </span>
                <span>{totalLease * (0 + taxRate)}</span>

                <span style={{
                  fontWeight: "800"
                }}> = Total </span>
                <span>{numeral(totalLease * (1 + taxRate)).format("$0,0.00")}</span>
              </div>
            </div>
          </div>
          <div className='col-12 col-xl-4'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title text-center'>
                  # Contrato
                </h4>
              </div>
              <div className='card-body'>
                <p className='card-text text-center'>
                  {leaseStore.humanId}
                </p>
                <button
                  className='btn btn-success btn-lg btn-block'
                  onClick={onDownloadLease}
                >
                  Generar contrato
                </button>

                <button
                  className='btn btn-warning btn-lg btn-block'
                  onClick={onDownloadLeaseNote}
                >
                  Generar Nota de Primer Pago
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='row' style={{ marginBottom: '7rem' }}>
          <div className='col-12'>
            <Table>
              <TableHead>
                <th>
                  <a href='#' className='text-muted sort' data-sort='lease-details-machine'>
                    Maquinaria
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='lease-details-cost'>
                    Costo
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='lease-details-timeunit'>
                    {timeUnit}
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='lease-details-qty'>
                    Cantidad
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='lease-details-hours-initial'>
                    Horometro inicial
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='lease-details-hours-return'>
                    Horometro entrega
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted' >
                    Opciones
                  </a>
                </th>
              </TableHead>
              <TableBody>
                {equipmentRows}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}))

export default LeaseDetails

import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import Form from "../../../components/Form";
import { File } from "../../../components/File";
import { buildUrl } from "../../../utils/media";
import { ClientStore } from "../../../stores/ClientsStore";
import DocumentsStore from "../../../stores/Documents";
import { useRouteMatch } from "react-router-dom";
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import dayjs from "dayjs";
require('dayjs/locale/es')
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.locale('es')


const ClientDocuments = inject("clientStore", "documentsStore")(observer(({
  clientStore,
  documentsStore,
}: {
  clientStore: ClientStore;
  documentsStore: DocumentsStore;
}) => {
  const match = useRouteMatch<{ clientId: string; }>()
  const { documentsMap } = documentsStore;

  const [selectedDay, setSelectedDay] = useState<DayValue>(null);
  const formattedDate = `${selectedDay?.day}-${selectedDay?.month}-${selectedDay?.year}`
  const selectedDate = dayjs(formattedDate, 'D-M-YYYY').add(dayjs().get('hour'), 'hour')
  const expireDate = selectedDate.add(6, 'month')
  useEffect(() => {
    let { clientId } = match.params;
    documentsStore.getDocuments(clientId);
  }, []);

  useEffect(() => {
    if (!documentsMap.ADDRESS_PROOF?.expire_date) {
      return;
    }
    const proofAddressExpireDate = dayjs(documentsMap.ADDRESS_PROOF?.expire_date);
    console.log(`proofAddressExpireDate ====`, proofAddressExpireDate)
    console.log(`proofAddressExpireDate.get('month')`, proofAddressExpireDate.get('month'))
    setSelectedDay({
      day: proofAddressExpireDate.get('date'),
      month: proofAddressExpireDate.get('month') + 1,
      year: proofAddressExpireDate.get('year'),
    });
  }, [documentsMap.ADDRESS_PROOF?.expire_date]);

  console.log(`documentsMap.ADDRESS_PROOF?.expire_date`, documentsMap.ADDRESS_PROOF?.expire_date)

  const onFileDrop = (files: File[], upload: any, progress: any) => {
    let { clientId } = match.params;
    upload(clientId, files, progress, selectedDate.format('YYYY-M-DD HH:mm:ss'));
  }




  return (
    <Form
      preTitle={clientStore.form.getField("name")}
      title="Documentos"
      onSubmit={(e) => e.preventDefault()}

    >
      <>
        <File
          label="Arrastra la imagen de la parte posterior del IFE o click para seleccionar archivo"
          alt="IFE parte posterior"
          src={buildUrl(documentsMap.IFE_BACK?.url)}
          onDrop={(files) =>
            onFileDrop(
              files,
              documentsStore.uploadIfeBack.bind(documentsStore),
              (progress: number) => {
                console.log(documentsMap.IFE_BACK?.url, progress)
              }
            )
          }
        />
        <File
          label="Arrastra la imagen de la parte frontal del IFE o click para seleccionar archivo"
          alt="IFE parte frontal"
          src={buildUrl(documentsMap.IFE_FRONT?.url)}
          onDrop={(files) =>
            onFileDrop(
              files,
              documentsStore.uploadIfeFront.bind(documentsStore),
              (progress: number) => {
                console.log(documentsMap.IFE_FRONT, progress)
              }
            )
          }
        />
        <div>
          <File
            label="Arrastra la imagen del comprobante de domicilio o click para seleccionar archivo"
            alt="Comprobante de domicilio"
            src={buildUrl(documentsMap.ADDRESS_PROOF?.url)}
            onCancel={() => {
              console.log('onCancel')
            }}
            onSave={(files) => {
              onFileDrop(
                files,
                documentsStore.uploadAddressProof.bind(documentsStore),
                (progress: number) => {
                  console.log(documentsMap.ADDRESS_PROOF?.url, progress)
                }
              )
            }}
          />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: dayjs().isBefore(expireDate) ? 'transparent' : 'red',
            padding: 10,
            gap: 10
          }}>
            <DatePicker
              value={selectedDay}
              onChange={setSelectedDay}
              inputPlaceholder="Fecha de comprobante de domicilio"
              shouldHighlightWeekends
            />

            {selectedDay ? <span style={{
              fontSize: 20,
              fontWeight: 'bold',
            }}>Fecha de expiracion: {expireDate.format('DD MMM YYYY')}</span> : null}
          </div>
        </div>
        <File
          label="Arrastra la imagen del IFE del representante legal"
          alt="IFE de represtentante legal"
          src={buildUrl(documentsMap.IFE_LEGAL_REPRESENTATIVE?.url)}

          onDelete={() => {

          }}
          onDrop={(files) =>
            onFileDrop(
              files,
              documentsStore.uploadIFELegalRepresentative.bind(documentsStore),
              (progress: number) => {
                console.log(documentsMap.IFE_LEGAL_REPRESENTATIVE, progress)
              }
            )
          }
        />
        <File
          isPDF
          title={"Acta Constitutiva"}
          label="Arrastra el PDF de la acta constitutiva o click para seleccionar archivo"
          alt="Acta constitutiva"
          src={buildUrl(documentsMap.CONSTITUTIVE_ACT?.url)}

          onDelete={() => {

          }}
          onDrop={(files) =>
            onFileDrop(
              files,
              documentsStore.uploadConstitutiveAct.bind(documentsStore),
              (progress: number) => {
                console.log(documentsMap.CONSTITUTIVE_ACT, progress)
              }
            )
          }
        />
      </>
    </Form>
  );

}))

export default ClientDocuments;

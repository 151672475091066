import { useEffect } from 'react'
import Card from './Card'
import { ProjectStore } from '../stores/ProjectsStore'
import { Project } from '../stores/types/project.types';

const ProjectCard = ({
  projectStore,
  project
}: {
  projectStore: ProjectStore;
  project?: Project;
}) => {

  useEffect(() => {
    if (projectStore.id)
      projectStore.fetchData(projectStore.id)
  }, [projectStore, projectStore.id]);

  if (!project) return null

  const { name, address, city, zip, state } = project



  return (
    <Card >
      <>
        <div className='col-auto'>
          <h4 className='card-title mb-1'>
            {name}
          </h4>
        </div>
        <div className='col-auto'>
          <p className='card-text small text-muted mb-1'>
            {address}
          </p>
        </div>
        <div className='col-auto'>
          <p className='card-text small text-muted mb-1'>
            {city}
          </p>
        </div>
        <div className='col-auto'>
          <p className='card-text small text-muted mb-1'>
            {zip}
          </p>
        </div>
        <div className='col-auto'>
          <p className='card-text small text-muted mb-1'>
            {state}
          </p>
        </div>
      </>
    </Card>
  )
}

export default ProjectCard

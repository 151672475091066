import React, { useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import HeaderImage from '../components/HeaderImage'
import Input from '../components/Input'
import { Table, TableHead, TableBody } from '../components/Table'
import { useHistory, useParams } from 'react-router-dom'
import { EquipmentStore, EquipmentsStore } from '../stores/EquipmentsStore'
import Branches from '../stores/BranchesStore'
import { debounce } from 'lodash'
import './Equipments.css'
import BranchesStore from '../stores/BranchesStore'
import { Equipment } from '../stores/types/equipment.types'

type onClickEvent<T> = React.MouseEvent<T, MouseEvent>

const EquipmentRow = (observer(({
  equipmentStore,
  equipment,
  scaffold,
  onDeleteClick
}: {
  equipmentStore: EquipmentStore;
  branchesStore: BranchesStore;
  scaffold: boolean;
  equipment: Equipment;
  onDeleteClick: () => void;
}) => {

  const {
    id,
    name,
    description,
    cost,
    hours,
    branch,
    lease,
    inventory,
  } = equipment
  const history = useHistory();

  const humanId = lease?.human_id;
  const branchName = branch?.name;


  useEffect(() => {

    equipmentStore.restore()
  }, []);

  const leaseId = lease?.id;
  const onEditClick = (e: onClickEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`/home/equipment/${scaffold ? "scaffold/" : ""}?id=${id}`)
  }

  let equipmentName: any = name
  const equipmentDefaultPhoto = equipment.photos[0]?.url;
  return (
    <tr className='table-row' onClick={onEditClick}>
      <td className='equipment-name'>
        {equipmentDefaultPhoto ? <img style={{
          width: 40,
          height: 40,
          objectFit: 'cover',
          margin: 4
        }} src={equipmentDefaultPhoto} alt={`Foto de ${equipment.name}`} /> : null}
        {equipmentName}
      </td>
      <td className='equipment-description'>
        {description}
      </td>
      <td className='equipment-contract' >
        {!scaffold && humanId && <button className='btn btn-primary' onClick={(e) => {
          e.stopPropagation();
          history.push(`/home/lease/${leaseId}/details`)
        }}>#{branch.serialprefix}-{humanId}</button>}
        {!scaffold && !humanId && "Disponible para renta"}
      </td>
      <td className='equipment-hours'>
        {scaffold ? inventory : hours}
      </td>
      <td className='equipment-cost'>
        {cost}
      </td>
      <td className='equipment-branch-name'>
        {branchName}
      </td>

      {/* <td>
        <Link
          className='btn btn-sm btn-danger d-none d-md-inline-block'
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick();
          }}
        >
          Borrar
        </Link>
      </td> */}
    </tr>
  )

}))



const Equipments = inject('equipmentsStore', 'equipmentStore', 'branchesStore')(observer(({
  equipmentsStore,
  equipmentStore,
  branchesStore
}: {
  equipmentsStore: EquipmentsStore,
  equipmentStore: EquipmentStore,
  branchesStore: Branches
}) => {

  const params = useParams<{ scaffold: string; }>()


  const getEquipments = useRef(debounce(() => {
    return equipmentsStore.getEquipments({
      search: equipmentsStore.searchValue,
      scaffold: !!params.scaffold
    })
  }, 300)).current;


  useEffect(() => {
    getEquipments();
  }, [equipmentsStore.searchValue]);

  useEffect(() => {
    branchesStore.getBranches()
    getEquipments();
  }, []);

  const onRemove = (equipmentId: string) => {
    equipmentsStore.deleteEquipmentById(equipmentId)
  }
  const onSearchChange = ({ currentTarget }: any) => {
    equipmentsStore.setSearchValue(currentTarget.value);


  }
  const renderEquipment = (equipment: Equipment) => {
    if ((!params.scaffold && !equipment.scaffold) || (params.scaffold && equipment.scaffold))
      return <EquipmentRow key={equipment.id} equipment={equipment} scaffold={!!params.scaffold} onDeleteClick={() => onRemove(equipment.id)} equipmentStore={equipmentStore} branchesStore={branchesStore} />
  }


  const rows = equipmentsStore.filteredEquipments.map(equipment => renderEquipment(equipment))

  return (
    <div className='main-content'>
      <HeaderImage to={'/home/equipment/' + ((params.scaffold) || "")} title={params.scaffold ? 'Andamios' : 'Equipo de renta'} />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Input
              label={params.scaffold ? 'Buscar andamios' : 'Buscar equipo de renta'}
              placeholder='Buscar...'
              defaultValue={equipmentsStore.searchValue}
              onChange={onSearchChange}
            />
          </div>
          <div className='col-12'>
            <Table>
              <TableHead>
                <th>
                  <a href='#' className='text-muted sort' data-sort='equipment-name'>
                    Nombre
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='equipment-salary'>
                    Descripcion
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='equipment-contract'>
                    En Proyecto
                  </a>
                </th>

                <th>
                  <a href='#' className='text-muted sort' data-sort='equipment-hours'>
                    {params.scaffold ? "Inventario" : "Horometro"}
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='equipment-name'>
                    Costo
                  </a>
                </th>
                <th>
                  <a href='#' className='text-muted sort' data-sort='equipment-name'>
                    Sucursal
                  </a>
                </th>
                <th />
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )

}))

export default Equipments

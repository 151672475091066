import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  fetchMaintenances,
  createMaintenance,
  updateMaintenance,
  removeMaintenance
} from '../sources/maintenance'
import Form from './Form'
import { isRequired, isInt } from '../utils/validator'
import SessionStore from './SessionStore'
import UiStore from './UiStore'

export class MaintenanceStore {
  @observable id = ''
  @observable saving = false
  private sessionStore: SessionStore;
  private uiStore: UiStore;
  public form: Form<{
    name: '',
    description: '',
    cost: '',
    equipmentId: ''
  }>;

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore; uiStore: UiStore }) {
    this.form = new Form({
      name: '',
      description: '',
      cost: '',
      equipmentId: ''
    }, {
      name: isRequired(),
      description: isRequired(),
      cost: [
        isRequired(),
        isInt()
      ],
      equipmentId: isRequired()
    })
    this.sessionStore = sessionStore
    this.uiStore = uiStore;
    makeObservable(this);
  }

  create() {
    const { name, description, cost, equipmentId } = toJS(this.form.fields)
    const { id } = this.sessionStore

    return createMaintenance({
      name,
      description,
      cost: parseInt(cost, 10),
      userId: id,
      equipmentId
    })
      .catch(this.uiStore.handleError('Error al crear mantenimiento'))
  }

  update() {
    const { name, description, cost, equipmentId } = toJS(this.form.fields)
    const { id } = this.sessionStore

    return updateMaintenance({
      name,
      description,
      cost: parseInt(cost, 10),
      userId: id,
      maintenanceId: this.id,
      equipmentId
    })
      .catch(this.uiStore.handleError('Error al editar mantenimiento'))
  }

  @action.bound
  save() {
    let promise = null
    this.saving = false
    if (this.isNew) {
      promise = this.create()
    } else {
      promise = this.update()
    }
    return promise
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  loadData({ id, name, description, cost, equipmentId }: { id: string; name: string; description: string; cost: number; equipmentId: string }) {
    this.id = id
    this.form.initialize({
      description,
      name,
      cost,
      equipmentId
    })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }

  @computed get isNew() {
    return this.id.length === 0
  }
}

export class MaintenancesStore {
  @observable maintenances: any[] = []
  private sessionStore: SessionStore;
  private uiStore: UiStore;

  constructor({ sessionStore, uiStore }: { sessionStore: SessionStore; uiStore: UiStore }) {
    this.sessionStore = sessionStore
    this.uiStore = uiStore
  }

  @action.bound
  deleteMaintenanceById(id = '') {
    const { id: userId } = this.sessionStore
    const maintenanceIndex = this.maintenances.findIndex(maintenance => maintenance.id === id)

    if (maintenanceIndex < 0) return
    return removeMaintenance({ userId, maintenanceId: id })
      .then(() => {
        this.maintenances = [
          ...this.maintenances.slice(0, maintenanceIndex),
          ...this.maintenances.slice(maintenanceIndex + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar mantenimiento'))
  }

  @action.bound
  getMaintenances() {
    const { id } = this.sessionStore

    return fetchMaintenances(id)
      .then(({ data }) => {
        const maintenances = data
        this.maintenances = maintenances
      })
      .catch(this.uiStore.handleError('Error al obtener mantenimientos'))
  }

  @computed get allMaintenances() {
    return this.maintenances.map((maintenance: any) => ({
      id: maintenance.id,
      name: maintenance.name,
      description: maintenance.description,
      cost: maintenance.cost,
      equipmentId: maintenance.equipment?.id,
      equipmentName: maintenance.equipment?.name
    }))
  }
}

import React from 'react'
import { inject, observer } from 'mobx-react'
import { bindInput } from '../utils/bindField'
import Form from '../components/Form'
import Input from '../components/Input'
import InputMask from '../components/InputMask'
import BranchStore from '../stores/Branch'
import { useHistory } from 'react-router-dom'

const NewBranch = inject('branchStore')(observer(({ branchStore }: {
  branchStore: BranchStore;
}) => {
  const history = useHistory();
  const onSubmit = (event: any) => {
    event.preventDefault()
    // TODO: Validate form is okay
    branchStore
      .create()
      .then(() => {
        // CHECK: redirect to branches so you can see new user you created
        history.push('/home/branches')
      })
  }

  const onCancel = () => {
    branchStore.restoreForm()
  }

  return (
    <Form
      preTitle='Sucursales'
      title='Crear nueva sucursal'
      primaryButtonLabel='Crear sucursal'
      disableSubmit={!branchStore.form.isValid || branchStore.saving}
      isSubmitLoading={branchStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <Input
          label='Nombre de la sucursal'
          {...bindInput(branchStore.form, 'name')}
        />
        <Input
          label='Direccion'
          {...bindInput(branchStore.form, 'address')}
        />
        <Input
          label='Colonia'
          {...bindInput(branchStore.form, 'neighborhood')}
        />
        <Input
          label='Ciudad'
          {...bindInput(branchStore.form, 'city')}
        />
        <Input
          label='Estado'
          {...bindInput(branchStore.form, 'state')}
        />
        <Input
          label='Codigo postal'
          {...bindInput(branchStore.form, 'zip')}
        />
        <InputMask
          label='Telefono'
          mask='(000) 000-0000'
          {...bindInput(branchStore.form, 'phone')}
        />
      </>
    </Form>
  )
}))

export default NewBranch

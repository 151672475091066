import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  createLeaseEquipment,
  fetchLeaseEquipments,
  deleteLeaseEquipment,
  returnEquipment
} from '../sources/lease-equipment'
import Form from './Form'
import { isRequired, isInt, isNumber } from '../utils/validator'
import { LeaseStore } from './Leases'
import UiStore from './UiStore'
import { LeaseEquipment } from './types/leaseEquipment.types'

export class LeaseEquipmentStore {
  @observable id = ''
  @observable equipmentName = ''
  @observable saving = false
  public form: Form<{
    equipmentId: '',
    cost: '',
    timeUnit: '',
    unitQty: '',
    qty: '',
    initialHours: ''
  }>;

  public returnForm: Form<{
    finalHours: ''
  }>;
  private uiStore: UiStore;
  private leaseStore: LeaseStore;

  constructor({ leaseStore, uiStore }: { leaseStore: LeaseStore; uiStore: UiStore }) {
    this.returnForm = new Form({
      finalHours: ''
    }, {
      finalHours: [
        isRequired(),
        isInt()
      ]
    })
    this.form = new Form({
      equipmentId: '',
      cost: '',
      timeUnit: '',
      unitQty: '',
      qty: '',
      initialHours: ''
    }, {
      equipmentId: isRequired(),
      cost: [
        isRequired(),
        isNumber()
      ],
      timeUnit: isRequired(),
      unitQty: [
        isRequired(),
        isInt()
      ],
      qty: [
        isRequired(),
        isInt()
      ],
      initialHours: [
        isRequired(),
        isInt()
      ]
    })
    this.uiStore = uiStore
    this.leaseStore = leaseStore
    makeObservable(this)
  }

  create() {
    const { equipmentId, cost, timeUnit, unitQty, qty, initialHours } = toJS(this.form.fields)
    const { id: leaseId } = this.leaseStore

    return fetchLeaseEquipments(leaseId!)
      .then(({ data }) => {
        const equipments = data
        // validate we only got equipments with same timeUnit
        const eqs = equipments.filter(equipment => equipment.time_unit === timeUnit)
        if (eqs.length !== equipments.length) {
          this.uiStore.setError('Un contrato solamente puede tener un tipo de costo')
          const error: Error & { code?: string } = new Error('Equipment and lease cost not matching')
          error.code = 'COST_NO_MATCH'
          throw error
        }
        return createLeaseEquipment({
          leaseId: leaseId!,
          equipmentId,
          timeUnit,
          cost: parseFloat(cost),
          unitQty: parseInt(unitQty, 10),
          qty: parseInt(qty, 10),
          initialHours: parseInt(initialHours, 10)
        })
      })
      .catch((error) => {
        if (!(error && error.code === 'COST_NO_MATCH')) {
          this.uiStore.setError('Error al crear equipo de renta para el contrato')
        }
        throw error
      })
  }

  @action.bound
  returnEquipment(isScaffold: boolean) {
    const { id: leaseId } = this.leaseStore
    const { finalHours } = toJS(this.returnForm.fields)

    this.saving = true
    return returnEquipment({
      leaseId: leaseId!,
      leaseEquipmentId: this.id,
      finalHours: parseInt(finalHours, 10),
      isScaffold
    })
      .then(() => {
        this.saving = false
        this.returnForm.resetAllFields()
      })
      .catch((error) => {
        this.saving = false
        this.uiStore.setError('Error al actualizar entrega de equipo')
        throw error
      })
  }

  @action.bound
  save() {
    this.saving = true
    return this.create()
      .then(() => {
        this.saving = false
        this.restore()
      })
      .catch((error) => {
        this.saving = false
        throw error
      })
  }

  @action.bound
  loadData({ id, equipmentName, equipmentId, cost, timeUnit, unitQty, qty }: {
    id: string;
    equipmentName?: string;
    equipmentId: string;
    cost?: number;
    timeUnit?: string;
    unitQty?: number;
    qty?: number;
  }) {
    this.id = id
    this.equipmentName = equipmentName!
    this.form.initialize({ equipmentId, cost, timeUnit, unitQty, qty })
  }

  @action.bound
  restore() {
    this.id = ''
    this.saving = false
    this.form.resetAllFields()
  }
}

export class LeaseEquipmentsStore {
  @observable leaseEquipments: LeaseEquipment[] = []

  private leaseStore: LeaseStore;
  private uiStore: UiStore;

  constructor({ leaseStore, uiStore }: { leaseStore: LeaseStore; uiStore: UiStore; }) {
    this.leaseStore = leaseStore
    this.uiStore = uiStore
    makeObservable(this);
  }

  @action.bound
  getEquipments(leaseId: string) {
    return fetchLeaseEquipments(leaseId)
      .then(({ data }) => {
        const equipments = data
        this.leaseEquipments = equipments
      })
      .catch(this.uiStore.handleError('Error al obtener equipos de renta para contrato'))
  }

  @action.bound
  removeById(leaseEquipmentId: string) {
    const { id: leaseId } = this.leaseStore
    const index = this.leaseEquipments.findIndex(equipment => equipment.id === leaseEquipmentId)
    if (index < 0) return
    return deleteLeaseEquipment({ leaseId: leaseId!, leaseEquipmentId })
      .then(() => {
        this.leaseEquipments = [
          ...this.leaseEquipments.slice(0, index),
          ...this.leaseEquipments.slice(index + 1)
        ]
      })
      .catch(this.uiStore.handleError('Error al borrar equipo de renta para contrato'))
  }

  @computed get allEquipments(): LeaseEquipmentStoreEquipment[] {
    return this.leaseEquipments.map((leaseEquipment) => ({
      id: leaseEquipment.id,
      cost: leaseEquipment.cost,
      unitQty: leaseEquipment.unit_qty,
      timeUnit: leaseEquipment.time_unit,
      qty: leaseEquipment.qty,
      equipmentName: leaseEquipment.equipment?.name,
      equipmentId: leaseEquipment.equipment?.id,
      leaseId: leaseEquipment.lease.id,
      initialHours: leaseEquipment.initial_hours,
      finalHours: leaseEquipment.final_hours,
      scaffold: leaseEquipment.equipment?.scaffold,
      returned: leaseEquipment.returned,
    }))
  }
}

export type LeaseEquipmentStoreEquipment = {
  id: string;
  cost: number;
  unitQty: number;
  timeUnit: string;
  qty: number;
  equipmentName: string;
  equipmentId: string;
  leaseId: string;
  returned: boolean;
  initialHours?: number;
  finalHours?: number;
  scaffold: boolean;
}

export default LeaseEquipmentsStore;
import request, { ancoAPI } from '../utils/http-client'

export function fetchSuppliers(userId = '') {
  return ancoAPI
    .get(`/api/supplier`)
}

export function createSupplier({ name, phone, email, rfc, street, number, neighborhood, city, state, zip, userId }: { name: string, phone: string, email: string, rfc: string, street: string, number: string, neighborhood: string, city: string, state: string, zip: string, userId: string }) {
  return ancoAPI
    .post(`/api/supplier`, {
      name: name,
      street: street,
      number: number,
      rfc: rfc,
      phone,
      email,
      neighborhood: neighborhood,
      city: city,
      state: state,
      zip: zip,
      userId: userId
    })
}

export function updateSupplier({ name, rfc, street, phone, email, number, neighborhood, city, state, zip, userId, supplierId }: { name: string, rfc: string, street: string, phone: string, email: string, number: string, neighborhood: string, city: string, state: string, zip: string, userId: string, supplierId: string }) {
  return ancoAPI
    .put(`/api/supplier/${supplierId}`, {
      name: name,
      rfc: rfc,
      street: street,
      phone,
      email,
      number: number,
      neighborhood: neighborhood,
      city: city,
      state: state,
      zip: zip,
      userId: userId,
      supplierId: supplierId
    })
}

export function removeSupplier({ userId, supplierId }: { userId: string, supplierId: string }) {
  return ancoAPI
    .delete(`/api/supplier/${supplierId}`)
}

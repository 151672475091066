
import { InventoryItem } from "../pages/Maintenances/components/Inventory/Inventory.types";
import { ancoAPI } from "../utils/http-client"

const fetchInventoryItems = ({ searchText = '', limit = 10 }) => {
  return ancoAPI.get(`/api/inventory-item?search=${searchText}&limit=${limit}`);
}

const fetchInventoryItemById = (inventoryItemId: string) => {
  return ancoAPI.get<InventoryItem>(`/api/inventory-item/${inventoryItemId}`);
}
const createInventoryItem = ({ name, supplierId, cost, unit, description, profitPercentage }: { name: string; supplierId: string, cost: number; unit: string; description: string; profitPercentage: number; }) => {
  return ancoAPI.post('/api/inventory-item', {
    supplierId,
    cost,
    unit,
    name,
    description,
    profitPercentage,
  })
}
const updateInventoryItem = ({ name, supplierId, cost, unit, quantity, description, profitPercentage, inventoryItemId }: { name: string; supplierId: string, cost: number; unit: string; quantity: number; description: string; profitPercentage: number; inventoryItemId: string }) => {
  return ancoAPI.put(`/api/inventory-item/${inventoryItemId}`, {
    supplierId,
    cost,
    unit,
    name,
    quantity,
    description,
    profitPercentage,
  })
}
const removeInventoryItem = ({ inventoryItemId }: { inventoryItemId: string }) => {
  return ancoAPI.delete(`/api/inventory-item/${inventoryItemId}`)
}

const inventoryItemSource = {

  fetchInventoryItems,
  fetchInventoryItemById,
  createInventoryItem,
  updateInventoryItem,
  removeInventoryItem
};

export default inventoryItemSource
import Form from './Form'
import { observable, action, computed, makeObservable } from 'mobx'
import { setAuthorizationHeader, restoreClient } from '../utils/http-client'
import { authorizationStorage } from '../utils/localstorage'
import { login, me } from '../sources/user'
import { isRequired, isEmail } from '../utils/validator'
import UiStore from './UiStore'

function removeToken() {
  authorizationStorage.remove()
  restoreClient()
  localStorage.setItem('userId', '')
  localStorage.setItem('token', '')
}

export default class SessionStore {
  @observable id = localStorage.getItem('userId') || ''
  @observable isAdmin = false
  @observable firstName = ''
  @observable private token?: string = localStorage.getItem('token') || ''
  @observable lastName = ''
  @observable loading = false;
  private uiStore: UiStore;
  public form: Form<{
    email: '',
    password: ''
  }>

  constructor({ uiStore }: { uiStore: UiStore; }) {
    this.form = new Form({
      email: '',
      password: ''
    }, {
      email: [
        isRequired(),
        isEmail()
      ],
      password: isRequired()
    })
    this.uiStore = uiStore
    makeObservable(this)
  }

  @action.bound
  loginSuccess({ admin, first_name: firstName, last_name: lastName, id }: { admin: boolean; first_name: string; last_name: string; id: string }) {
    this.firstName = firstName
    this.lastName = lastName
    this.isAdmin = admin
    this.id = id
    localStorage.setItem('userId', id)
    this.loading = false
    this.form.resetAllFields()
  }

  @action.bound
  logoutSuccess() {
    this.id = ''
    this.isAdmin = false
    this.firstName = ''
    this.lastName = ''
    this.form.resetAllFields()
  }

  @action.bound
  me() {
    this.loading = true
    return me()
      .then(({ data }) => {
        this.loginSuccess(data)
      })
      .catch((error) => {
        console.log("error", error)
        this.loading = false
        this.uiStore.setError('Sesion Invalida, vuelve a Iniciar Sesion')
        this.token = undefined
        removeToken()
        throw error
      })
  }

  login() {
    const { email, password } = this.form.fields
    return login({
      email,
      password
    })
      .then(({ data }) => {
        console.log('login sucess', data)
        const { token } = data


        this.loginSuccess(data)
        // save token in http-client
        setAuthorizationHeader(token.id)
        // save token in localStorage
        this.token = token.id
        if (!this.token) {
          throw new Error('Could not load token = undefined');
        }
        localStorage.setItem('token', this.token)
        authorizationStorage.setValue(token.id)
      })
      .catch(() => {
        this.uiStore.setError('Correo o Contraseña invalida')
        removeToken()
      })
  }

  logout() {
    removeToken()
    this.logoutSuccess()
  }

  @computed get isLoggedIn() {
    return !!(this.token && this.token.length > 0)
  }

  @computed get isLoading() {
    return (this.loading)
  }

  @computed get name() {
    if (!this.isLoggedIn) return ''
    return `${this.firstName} ${this.lastName}`
  }
}

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import classnames from 'classnames'
import { bindInput } from '../utils/bindField'
import Input from '../components/Input'
import SessionStore from '../stores/SessionStore'
import { useHistory, useLocation } from 'react-router-dom'

const Login = inject('sessionStore')(observer(({
  sessionStore
}: {
  sessionStore: SessionStore
}) => {
  const history = useHistory()
  const location = useLocation<{
    from: {
      pathname: string;
    }
  }>()
  const onSubmit = (event: any) => {
    event.preventDefault()
    const { from } = location.state || { from: { pathname: '/home' } }

    sessionStore.login()
      .then(() => history.push(from.pathname))
  }

  const { form, loading } = sessionStore

  const buttonClassName = classnames('btn btn-lg btn-block btn-primary mb-3', {
    'is-loading': loading
  })

  return (
    <div className='d-flex align-items-center bg-auth border-top border-top-2 border-primary'>
      <div className='container-fluid'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5'>

            <h1 className='display-4 text-center mb-3'>
              Anco
            </h1>

            <p className='text-muted text-center mb-5'>
              Sistema Anco.
            </p>

            <form onSubmit={onSubmit}>

              <Input
                label='Correo electronico'
                placeholder='name@address.com'
                {...bindInput(form, 'email')}
              />

              <Input
                label='Contraseña'
                type='password'
                placeholder='Ingresa tu contraseña'
                {...bindInput(form, 'password')}
              />

              <button
                className={buttonClassName}
                disabled={!form.isValid || loading}
              >
                Sign in
              </button>
            </form>

          </div>
          <div className='col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block'>

            <div className='bg-cover vh-100 mt--1 mr--3' style={{ 'backgroundImage': 'url(./assets/img/covers/auth-side-cover.jpg)' }} />

          </div>
        </div>
      </div>

    </div>

  )
}));

export default Login

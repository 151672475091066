import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ancoAPI } from '../../../../../utils/http-client';
import { AddQuoteItems } from './AddQuoteItems';
import numeral from 'numeral';
import { quoteSource } from '../../../../../sources/quoteSource';
import { QuoteItem } from './QuoteItems.types';
import { Quote } from '../Quotes.types';
import { inject, observer } from 'mobx-react';
import { InventoryItemStore } from '../../../../../stores/InventoryItemStore';
import { AddQuoteLabor } from './AddQuoteLabor';
import { QuoteLabor } from './QuoteLabor.types';
import PrintIcon from './assets/print.png'
import AcceptIcon from './assets/accept.png'
import RejectIcon from './assets/reject.png'
import Card from '../../../../../components/Card';

const QuoteItemRow = ({ quoteItem, onSave }: { quoteItem: QuoteItem; onSave: () => void }) => {

  const [quantity, setQuantity] = useState<number>(quoteItem.quantity);

  const _onSave = async () => {
    await quoteSource.updateQuoteItem({
      quoteId: quoteItem.quote.id,
      quoteItemId: quoteItem.id,
      quantity,
      description: quoteItem.description,
      name: quoteItem.name,

    })
    onSave()
  }

  return <tr key={quoteItem.id}>
    <td>
      <strong>
        {quoteItem.name}
      </strong>
      <br />
      <span style={{
        fontSize: 12,
      }}>{quoteItem.description}</span>
    </td>

    <td>{numeral(quoteItem.sale_price).format('$0.0')}</td>
    <td>
      <input type="number" className='form-control' defaultValue={quoteItem.quantity} onChange={(event) => {
        setQuantity(parseInt(event.target.value));
      }} />
    </td>
    <td>{numeral(quoteItem.quantity * quoteItem.sale_price).format('$0.0')}</td>
    <td>
      <button onClick={_onSave} className='btn btn-primary'>Actualizar</button>
    </td>
  </tr>
}
const QuoteLaborRow = ({ quoteLabor, onRemove }: { quoteLabor: QuoteLabor; onRemove: () => void }) => {

  const onDelete = async () => {
    await ancoAPI.delete(`/api/quote-labor/${quoteLabor.id}`)
    onRemove()
  }

  return <tr key={quoteLabor.id}>
    <td>
      <strong>
        {quoteLabor.employee.first_name}
      </strong>
      <br />

    </td>
    <td>
      <span style={{
        fontSize: 12,
      }}>{quoteLabor.description}</span>
    </td>
    <td>{numeral(quoteLabor.hours).format('0.0')}</td>
    <td>{numeral(quoteLabor.cost).format('$0.0')}</td>
    <td>
      <button onClick={onDelete} className='btn btn-danger'>Borrar</button>
    </td>
  </tr>
}

export const QuoteItems = inject('inventoryItemStore')(observer(({
  inventoryItemStore
}: {
  inventoryItemStore?: InventoryItemStore
}) => {

  const history = useHistory();

  const [quote, setQuote] = useState<Quote>();
  const params = useParams<{
    quoteId: string;
  }>()


  const fetchQuoteDetails = async () => {
    const { data } = await ancoAPI.get<Quote>(`/api/quote/${params.quoteId}`);
    setQuote({
      ...data,
    });
  }

  const onAddItem = () => {
    fetchQuoteDetails();
  }

  useEffect(() => {

    fetchQuoteDetails();
  }, []);

  const totalItems = quote?.quoteItems?.reduce((total, quoteItem) => {
    return total + quoteItem.quantity * quoteItem.sale_price;
  }, 0) ?? 0

  const totalLabor = quote?.quoteLabors?.reduce((total, quoteLabor) => {
    return total + parseFloat(quoteLabor.cost);
  }, 0) ?? 0

  const totalExtras = quote?.accepted ? 0 : 500;

  const grandTotal = totalItems + totalLabor + totalExtras;

  const onSave = () => {
    fetchQuoteDetails();
    inventoryItemStore?.fetchInventoryItems();
  }

  const onRemove = () => {
    fetchQuoteDetails();
    inventoryItemStore?.fetchInventoryItems();
  }

  const acceptOrRejectQuote = async () => {
    await ancoAPI.put(`/api/quote/${params.quoteId}/accept-reject`)
    fetchQuoteDetails();
  }


  return (
    <div style={{
      padding: 40,
    }}>
      <div className='row'>
        <div className='col-8'>
          <h1>Cotizacion {quote?.name}</h1>
          <h4>Diagnostico</h4>
          <Card>

            <h4>{quote?.initialDiagnostic}</h4>
          </Card>
          <h4>Descripcion</h4>
          <p>{quote?.description}</p>
        </div>
        <div className='col-4' style={{
          flexDirection: 'row',
          display: 'flex'
        }}>

          <button className={`btn btn-${quote?.accepted ? 'success' : 'danger'}`} onClick={acceptOrRejectQuote} style={{
            height: 40
          }}>
            <img src={quote?.accepted ? AcceptIcon : RejectIcon} alt="Print logo" height={20} />
          </button>

          <button className='btn btn-warning' onClick={() => {
            history.push(`/home/maintenances/quotes/${params.quoteId}/print`)
          }} style={{
            height: 40
          }}>
            <img src={PrintIcon} alt="Print logo" height={20} />
          </button>
        </div>
      </div>
      <div className='row'>
        <table className='table'>
          <thead>
            <tr>
              <th>Concepto</th>
              <th>Precio</th>
              <th>Cantidad</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {quote?.quoteItems?.map((quoteItem) => <QuoteItemRow onSave={onSave} key={quoteItem.id} quoteItem={quoteItem} />)}
          </tbody>
        </table>
        <table className='table'>
          <thead>
            <tr>
              <th>Encargado</th>
              <th>Descripcion</th>
              <th>Horas</th>
              <th>Costo estimado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {quote?.quoteLabors?.map((quoteLabor) => <QuoteLaborRow onRemove={onRemove} key={quoteLabor.id} quoteLabor={quoteLabor} />)}
          </tbody>
        </table>
        {!quote?.accepted && <>
          <h3>
            <strong>
              Extras
            </strong>
          </h3>
          <table className="quote-table">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Costo</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>

              {!quote?.accepted && <tr>
                <td>Costo de diagnostico</td>
                <td>1</td>
                <td>$500</td>
                <td>$500</td>
              </tr>}
            </tbody>
          </table>
        </>}
        <div style={{
          display: 'flex',
          flex: 1,
          paddingBottom: 20,
          paddingRight: 100,
        }}>
          <AddQuoteLabor onRefresh={() => {
            fetchQuoteDetails();
          }} quote={quote} />

          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}>

            <h1 style={{

            }}>
              Total: {numeral(grandTotal).format('$0,0.00')}
            </h1>

          </div>
        </div>

      </div>
      <AddQuoteItems onAddItem={onAddItem} quote={quote} />
    </div>
  )
}));

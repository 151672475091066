import React, { useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash';
import { quoteSource } from '../../../../../sources/quoteSource';
import { InventoryItem } from '../../Inventory/Inventory.types';
import { Quote } from '../Quotes.types';
import { InventoryItemStore } from '../../../../../stores/InventoryItemStore';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';



const QuoteItemRow = ({ inventoryItem, onAddItem }: { inventoryItem: InventoryItem; onAddItem: (inventoryItem: InventoryItem, quantity: number) => void; }) => {

  const outOfStock = inventoryItem.quantity <= 0;
  const [quantity, setQuantity] = useState<number>(1);
  return <tr key={inventoryItem.id} style={{
    backgroundColor: outOfStock ? '#FEC3B6' : 'white'
  }}>
    <td>
      <strong>{inventoryItem.name}</strong>
      <br />
      <span style={{
        fontSize: 12,
      }}>{inventoryItem.description}</span>
    </td>

    <td>{inventoryItem.cost * (1 + inventoryItem.profitPercentage / 100)}/{inventoryItem.unit}</td>
    <td>{inventoryItem.supplier.name}</td>
    <td>{inventoryItem.quantity}</td>
    <td>
      <input
        type="number"
        disabled={outOfStock}
        className='form-control'
        value={quantity}
        onChange={(event) => setQuantity(parseInt(event.target.value))} />
    </td>
    <td>
      <button disabled={outOfStock} onClick={() => {
        onAddItem(inventoryItem, quantity)
      }} className='btn btn-primary'>Agregar</button>
    </td>
  </tr>
}

export const AddQuoteItems = inject('inventoryItemStore')(observer(({ quote, onAddItem, inventoryItemStore }: { quote: Quote | undefined; onAddItem: (inventoryItem: InventoryItem) => void; inventoryItemStore?: InventoryItemStore }) => {


  const [searchText, setSearchText] = useState<string>();

  const fetchInventoryItems = useRef(debounce(async ({ searchText }) => {
    inventoryItemStore?.fetchInventoryItems(searchText, 10);
  }, 300)).current;

  useEffect(() => {
    fetchInventoryItems({ searchText });
  }, [searchText, fetchInventoryItems]);

  const _onAddItem = (inventoryItem: InventoryItem, quantity: number) => {
    console.log('inventoryItem', inventoryItem);
    quoteSource.addQuoteItem({
      quoteId: quote!.id,
      inventoryItemId: inventoryItem.id,
      quantity
    })
    onAddItem(inventoryItem)
    fetchInventoryItems({ searchText });

  }

  if (!quote) return null;

  return (
    <div className="row">
      <div className="col-12">
        <div style={{
          width: "100%",
          height: 1,
          backgroundColor: 'lightgray',
          marginBottom: 20,
        }} />
      </div>
      <div className="mog" style={{
      }} role="document">
        <div className="content">
          <div className="body">
            <div className='row'>
              <div className='col-12'>
                <input type="text" onChange={(event) => setSearchText(event.target.value)} className='form-control' placeholder='Buscar' />
              </div>
            </div>

            <table className='table'>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Precio</th>
                  <th>Proveedor</th>
                  <th>Inventario</th>
                  <th>Cantidad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {inventoryItemStore?.inventoryItems.map((inventoryItem, index) => {
                  return <QuoteItemRow onAddItem={_onAddItem} key={inventoryItem.id} inventoryItem={inventoryItem} />
                })}

              </tbody>
            </table>


          </div>
        </div>
      </div>
    </div>
  )
}));

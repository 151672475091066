import React, { useEffect, useRef, useState } from 'react'
import Form from '../../../../components/Form'
import Input from '../../../../components/Input'
import { inject, observer } from 'mobx-react'
import Inventory from '../../../../stores/InventoryStore'

import { useHistory, useParams } from 'react-router-dom'
import { ancoAPI } from '../../../../utils/http-client'
import ClientsStore from '../../../../stores/ClientsStore'
import SelectSearch, { SelectSearchOption } from '../../../../components/SelectSearch'
import { Client } from '../../../../stores/types/client.types'
import { debounce, set } from 'lodash'
import { clientMachineSource } from '../../../../sources/clientMachineSource'
import { ClientMachine } from './ClientMachine.types'

export const UpdateClientMachine = inject('inventoryStore', 'clientsStore', 'suppliersStore')(observer(({ inventoryStore, clientsStore }: { inventoryStore: Inventory; clientsStore: ClientsStore }) => {
  const history = useHistory();
  const params = useParams<{
    clientMachineId: string;
  }>();

  const [description, setDescription] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [qrCode, setQrCode] = useState<string>();
  const [client, setClient] = useState<Client>();
  const [year, setYear] = useState<number>(2000);
  const [model, setModel] = useState<string>('');
  const [brand, setBrand] = useState<string>('');
  const [motorNumber, setMotorNumber] = useState<string>('');
  const [hourometer, setHourometer] = useState<number>(0);


  const loadData = async () => {

    const { data } = await clientMachineSource.fetchClientMachineById({
      clientMachineId: params.clientMachineId
    })
    onSelected({
      meta: data.client,
    } as SelectSearchOption)
    setDescription(data.description);
    setIdentifier(data.identifier);
    setName(data.name);
    setQrCode(data.qrCode);
    setClient(data.client);
    setYear(data.year);
    setModel(data.model);
    setBrand(data.brand);
    setMotorNumber(data.motorNumber);
    setHourometer(data.hourometer);


  }

  useEffect(() => {

    loadData();
    fetchClients()
  }, []);

  const fetchClients = useRef(debounce((searchText = '') => {
    clientsStore.getClients({
      search: searchText
    })
  }, 300)
  ).current;


  const onSubmit = async (event: any) => {

    event.preventDefault()
    console.log('submitting');

    try {
      const { data } = await ancoAPI.put(`/api/client-machine/${params.clientMachineId}`, {
        clientId: client?.id,
        name,
        description,
        year,
        model,
        brand,
        motorNumber,
        hourometer,
        identifier,
      })
      console.log('data', data);

      history.goBack();
    } catch (e) {
      alert('Error al crear el maquinaria de cliente')
    }


  }
  const onCancel = () => {
    inventoryStore.restore()
  }



  const clientsOptions = clientsStore.clients.map(client => ({
    value: client.id,
    label: client.name,
    meta: client
  }))

  const onSelected = (option: SelectSearchOption) => {
    const { id, name } = option.meta as ClientMachine

    setClient(option.meta);
    return name;
  }

  const onSearchClient = (seachText: string) => {
    fetchClients(seachText)
  }

  const renderClientItem = (option: SelectSearchOption) => {


    const { id, name, email, phone } = option.meta as Client

    if (!id) return null


    return <div key={id} className='row select-row' style={{

      borderTop: '1px solid lightgray',
      padding: 10
    }}>
      <div className='col-12' style={{

      }}>
        {name} - {phone}
      </div>
    </div>


  }

  const isValid = () => {
    return client && name && description && identifier;
  }


  return (
    <Form
      preTitle={`Nueva maquinaria de cliente`}
      title='Actualizar maquinaria'
      primaryButtonLabel={'Actualizar maquinaria'}
      disableSubmit={!isValid()}
      isSubmitLoading={inventoryStore.saving}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <>
        <SelectSearch label='Cliente'
          onSelected={onSelected}
          options={clientsOptions}
          onSearch={onSearchClient}
          value={client?.name}
          renderItem={renderClientItem} />

        <Input
          label='Nombre'
          value={name}
          onChange={(event) => {
            console.log('event', event);
            setName(event.target.value)
          }}
        />

        <Input
          label='Descripcion'
          value={description}
          onChange={(event) => {
            setDescription(event.target.value)
          }}
        />


        <Input
          label={'Identificador'}
          value={identifier}
          onChange={(event) => {
            console.log('event', event);
            setIdentifier(event.target.value)
          }}
        />

        <Input
          label={'Año'}
          type='number'
          value={`${year}`}
          onChange={(event) => {
            console.log('event', event);
            setYear(parseInt(event.target.value))
          }}
        />

        <Input
          label={'Modelo'}
          value={model}
          onChange={(event) => {
            console.log('event', event);
            setModel(event.target.value)
          }}
        />

        <Input
          label={'Brand'}
          value={brand}
          onChange={(event) => {
            console.log('event', event);
            setBrand(event.target.value)
          }}
        />

        <Input
          label={'No. Motor'}
          value={motorNumber}
          onChange={(event) => {
            console.log('event', event);
            setMotorNumber(event.target.value)
          }}
        />

        <Input
          label={'Horometro'}
          type='number'
          value={`${hourometer}`}
          onChange={(event) => {
            console.log('event', event);
            setHourometer(parseInt(event.target.value))
          }}
        />

        {qrCode && <img src={qrCode} alt="Codigo QR Maquinaria" />}
      </>
    </Form>
  )
}))
